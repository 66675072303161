import { Box } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "src/components/app/Logo";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";

const GeneralLayout: React.FC = () => {
    return (
        <Box minWidth="100%" p="10" minHeight="100vh" bg="gray.50">
            <Box mb="5" display="flex" justifyContent="center">
                <Link to={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
                    <Logo />
                </Link>
            </Box>
            <Box bg="white" boxShadow="sm" width="lg" maxWidth="100%" height="auto" m="0 auto" p="5" border="1px solid" borderColor="gray.100"
                borderRadius="lg">
                <Box>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

export default GeneralLayout;