interface IUseConfirmModalProps<T> {
    confirmLabel?: string;
    cancelLabel?: string;
    onConfirm?: (data?: T) => void;
    onCancel?: (data?: T) => void;
}

const styles = {
    root:`position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.5);z-index:10000;`,
    modal:`position:absolute;top:30%;left:50%;transform:translateX(-50%);width:400px;height:auto;background-color:#fff;border-radius:4px;max-width:90%;`,
    title:`font-size:1.4rem;font-weight:normal;padding:16px;`,
    message:`font-size:1rem;padding:16px;margin-bottom:8px;`,
    actions:`padding:16px;display:flex;flex-direction:row-reverse;`,
    btn:`border:1px solid transparent;min-width:100px;padding:8px 16px;border-radius:4px;`,
    confirmBtn:`background-color:red;color:#fff;`,
    cancelBtn:`color:#555;margin-right:8px;border-color:#777;`
};

export function useConfirmModal<T>(props: IUseConfirmModalProps<T>) {
    const {
        confirmLabel = "Confirm",
        cancelLabel = "Cancel",
        onConfirm,
        onCancel
    } = props;

    let modalMetaData:T | undefined = undefined;
    let title = "";
    let message = "";
    const modalId = "nConfirmModal7020";

    const getModalTitle = () => {
        const titleElement = document.createElement("h1");
            titleElement.innerHTML = title || "";
            titleElement.setAttribute("style",styles.title);
            return titleElement;
    }
    const getModalMessage = () => {
        const messageElement = document.createElement("p");
        messageElement.setAttribute("style",styles.message);
        messageElement.innerHTML = message;
        return messageElement;
    }

    

    const onConfirmClicked = () => {
        //Show modal
        closeModal();
        if(onConfirm){
            onConfirm(modalMetaData);
        }
    }

    const onCancelClicked = () => {
        //close modal
        closeModal();
        if(onCancel){
            onCancel(modalMetaData);
        }
    }

    const onBackdropClicked = () => {
        //close modal
        closeModal()
        if(onCancel){
            onCancel(modalMetaData);
        }
    }

    // const onCloseClicked = () => {
    //     //close modal
    //     closeModal()
    //     if(onCancel){
    //         onCancel(modalMetaData);
    //     }
    // }

    const getModalActions = ()=>{
        const confirmBtnElement = document.createElement("button");
        confirmBtnElement.setAttribute("style",styles.btn+styles.confirmBtn);
        confirmBtnElement.setAttribute("type","button");
        confirmBtnElement.innerText = confirmLabel;
        const cancelBtnElement = document.createElement("button");
        cancelBtnElement.setAttribute("style",styles.btn+styles.cancelBtn);
        cancelBtnElement.setAttribute("type","button");
        cancelBtnElement.innerText = cancelLabel;
        confirmBtnElement.addEventListener("click",()=>{
            onConfirmClicked()
        });
        cancelBtnElement.addEventListener("click",()=>{
            onCancelClicked();
        });
        const actionsElement = document.createElement("div");
        actionsElement.setAttribute("style",styles.actions);
        actionsElement.appendChild(confirmBtnElement);
        actionsElement.appendChild(cancelBtnElement);
        return actionsElement;
    }

    const getModalDom = ()=>{
        const mainElement = document.createElement("div");
        mainElement.setAttribute("id",modalId);
        mainElement.setAttribute("style",styles.root);
        mainElement.addEventListener("click",()=>{
            onBackdropClicked()
        })
        const modalElement = document.createElement("div");
        modalElement.setAttribute("style",styles.modal);
        if(title){
            modalElement.appendChild(getModalTitle());
        }
        modalElement.appendChild(getModalMessage());
        modalElement.appendChild(getModalActions());
        modalElement.addEventListener("click",(e)=>{
            e.stopPropagation();
        })
        mainElement.appendChild(modalElement);
        return mainElement;
    }

    //------------------------------------------------------------------------------------

    const openModal = (data?: T,titleP:string = "",messageP:string="Are you sure?") => {
        modalMetaData = data;
        title = titleP;
        message = messageP;
        document.body.appendChild(getModalDom())
    }

    const closeModal = () => {
        const modalElement = document.getElementById(modalId);
        if(modalElement){
            document.body.removeChild(modalElement);
        }
    }

    return {
        openModal,
        closeModal
    }
}