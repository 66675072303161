import { useCallback, useState } from "react";
import { API_ROUTES } from "src/constants/api-routes";
import { IAttachment, IAttachmentResponse } from "src/types/attachment";
import { getAuthorization } from "src/utils/get-authorization";
//TODO read upload API URL from ENV or API route constants
const API_BASE_URL = window?.REACT_APP_API_URL || "https://dev-api.tmp.nizek.dev/";
interface IUploaderHookArgs {
    onComplete: (data: IAttachment) => void;
    onError: (err: unknown) => void;
    setUploading?:(value:boolean)=>void;
}

function useUploadToCDN(data: IUploaderHookArgs) {
    const {onComplete,onError,setUploading:setParentUploading} = data;
    const [progress, setProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);

    const upload = useCallback((file: File) => {
        try{
            const request = new XMLHttpRequest();
            const uploadUrl = `${API_BASE_URL}${API_ROUTES.ATTACHMENT.UPLOAD}`;
            request.open("POST",uploadUrl);
            request.setRequestHeader('Authorization', getAuthorization() || "");
            request.upload.addEventListener("progress",function(e){
                setUploading(true);
                setParentUploading && setParentUploading(true);
                let percentage = (e.loaded / e.total) * 100;
                setProgress(()=>{
                    return Math.floor(percentage)
                });
            });
            request.addEventListener("load",function(e){
                setProgress(100);
                setUploading(false);
                setParentUploading && setParentUploading(false);
                onComplete((JSON.parse(request.response) as IAttachmentResponse).payload);
            });
            const data = new FormData();
            data.append("file",file);
            request.send(data);
        }catch(err){
            onError(err);
        }
    },[onComplete,onError,setParentUploading])

    return {
        progress,
        upload,
        uploading
    }
}

export default useUploadToCDN;