import React from "react";
import {Box} from "@chakra-ui/react";
import "./styles.css";



const HorizontalLoading:React.FC = ()=>{
    return <Box className="horizontal-animate-item"></Box>
}

export default HorizontalLoading;