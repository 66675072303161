import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";
import { IPlanDayMeal } from "src/api/types/plan-days";

interface IMealProps {
    meal: IPlanDayMeal;
    isLatest:boolean;
}

const Meal: React.FC<IMealProps> = (props) => {
    const { meal ,isLatest} = props;
    return (<AccordionItem borderBottom={isLatest?"none":"1px solid gray"} borderTop="none">
        <h2 className="day-title">
            <AccordionButton>
                <Box flex="1" textAlign="left">
                    {
                        meal.title
                    }
                </Box>
                <AccordionIcon />
            </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
            <UnorderedList>
                {
                    meal.foods.map((f, i) => (
                        <ListItem key={i} whiteSpace="break-spaces">
                            <span>
                            {
                                `${f.name_en}: ${f.value} ${f.unit}`
                            }
                            </span>
                            {
                                f.ate && <Badge variant='outline' colorScheme='green' ml="2">Ate</Badge>
                            }
                        </ListItem>
                    ))
                }
            </UnorderedList>
        </AccordionPanel>
    </AccordionItem>);
}

export default Meal;