import { Link, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import CheckoutContainer from "../CheckoutContainer";
import CheckoutContent from "../CheckoutContent";
interface ICheckoutSuccessProps {
    planId: string;
    children: ReactNode;
}

const CheckoutSuccess: React.FC<ICheckoutSuccessProps> = ({ children, planId }) => {
    return <CheckoutContainer
        topChild={
            <CheckoutContent>
                <img src={process.env.PUBLIC_URL + '/congratulation-icon.png'} alt="success payment" style={{ maxWidth: "160px", margin: "0 auto 30px" }} />
                <Text fontSize="1em" fontWeight="500" marginBottom="13px">Congratulation!</Text>
                <Text fontSize="0.47em" color="#525252" marginBottom="13px">Payment was successful</Text>
                {children}
            </CheckoutContent>
        }
        bottomChild={
            <Link href={`elevateApp://payment/success?planId=${planId}`}
                color="#fff"
                bgColor="#0AA9B1"
                padding="12px"
                borderRadius="12px"
                textAlign="center"
                textDecor="none"
                display="block"
                _hover={{ textDecoration: "none" }}>View Plan</Link>
        }
    />;
}

export default CheckoutSuccess;