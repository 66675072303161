import { Center, Text,Box, Button } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import Icon from "@mdi/react";
import { mdiCoffeeOff } from '@mdi/js';
import {Link} from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";

const NotFound:React.FC = ()=>{
    const {t} = useTranslation();
    useTitle(t("pageTitles.notFound"));
    return (
        <Center display="flex" flexDirection="column" p="6">
            <Box color="orange.500">
            <Icon path={mdiCoffeeOff} size="48px" />
            </Box>
            <Text as="h3" fontSize="2xl" mt="4" fontWeight="bold">
                {
                    t("messages.notFound")
                }
            </Text>
            <Box as="p" mt="4">
                <Button as={Link} to={ROUTE_CONSTANTS.DASHBOARD.ROOT.ABSOLUTE}>{t("actions.backToHome")}</Button>
            </Box>
        </Center>
    );
}

export default NotFound;