import { Box, List, ListItem } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { INavLink } from "../../types";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";
interface IMenuProps{
    title:string;
    icon?:string;
    showTitle?:boolean;
    navLinks:INavLink[];
}

const Menu:React.FC<IMenuProps> = ({title,icon,navLinks,showTitle = true})=>{
    const {t} = useTranslation();
    return (<Box className="d-category">
        <Box className="d-c-title ex-item-target">
            {
                icon && <Icon path={icon} size="24px" className="ex-target-icon"/>
            }
            <span className="item-text">{t(title)}</span>
        </Box>
        <List className="d-c-menu-items">
            {
                navLinks.map(nl=>(
                    <ListItem key={nl.href}>
                        <NavLink to={nl.href} 
                            className={({isActive})=>isActive?"nav-link ex-item-target active":"nav-link ex-item-target"}>
                            {nl.icon && <Icon path={nl.icon} size="24px" className="ex-target-icon"/>}
                            <span className="item-text">{t(nl.text)}</span>
                            </NavLink>
                    </ListItem>
                ))
            }
        </List>
    </Box>);
}

export default Menu;