import { string, object, number, boolean } from "yup";

import { transform } from "../utils/validation-number-transform";

export const newPlanValidationSchema = (
  t: any,
  scope: "admin" | "nutritionist"
) => {
  let validationObject = {
    thumbnail_en: string().required(
      t("validationMessages.fieldIsRequired", {
        field: t("fields.photo"),
      })
    ),
    thumbnail_ar: string().required(
      t("validationMessages.fieldIsRequired_ar", {
        field: t("fields.photo_ar"),
      })
    ),
    type_en: object()
      .nullable()
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.type"),
        })
      ),
    chatEnabled: boolean(),
    // target: string()
    //   .required(
    //     t("validationMessages.fieldIsRequired", {
    //       field: t("fields.target"),
    //     })
    //   )
    //   .nullable(),
    title_en: string().required(
      t("validationMessages.fieldIsRequired", {
        field: t("fields.title"),
      })
    ),
    title_ar: string().required(
      t("validationMessages.fieldIsRequired_ar", {
        field: t("fields.title_ar"),
      })
    ),
    description_en: string()
      .max(400, t("validationMessages.maximumCharacter", { number: 400 }))
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.description"),
        })
      ),
    description_ar: string()
      .max(400, t("validationMessages.maximumCharacter_ar", { number: 400 }))
      .required(
        t("validationMessages.fieldIsRequired_ar", {
          field: t("fields.description_ar"),
        })
      ),
    briefs_en: object({
      first: string()
        .max(30, t("validationMessages.maximumCharacter", { number: 30 }))
        .required(
          t("validationMessages.fieldIsRequired", {
            field: t("fields.brief"),
          })
        ),
      second: string()
        .max(30, t("validationMessages.maximumCharacter", { number: 30 })),
      third: string()
        .max(30, t("validationMessages.maximumCharacter", { number: 30 })),
    }),
    briefs_ar: object({
      first: string()
        .max(30, t("validationMessages.maximumCharacter_ar", { number: 30 }))
        .required(
          t("validationMessages.fieldIsRequired_ar", {
            field: t("fields.brief_ar"),
          })
        ),
      second: string()
        .max(30, t("validationMessages.maximumCharacter_ar", { number: 30 })),
      third: string()
        .max(30, t("validationMessages.maximumCharacter_ar", { number: 30 })),
    }),
    contains_en: string()
      .max(150, t("validationMessages.maximumCharacter", { number: 150 }))
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.contains"),
        })
      ),
    contains_ar: string()
      .max(150, t("validationMessages.maximumCharacter_ar", { number: 150 }))
      .required(
        t("validationMessages.fieldIsRequired_ar", {
          field: t("fields.contains_ar"),
        })
      ),
    duration: number()
      .typeError(
        t("validationMessages.amountMostBeNumber", {
          field: t("fields.duration"),
        })
      )
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.duration"),
        })
      )
      .nullable()
      .transform((_, v) => transform(v)),
    carbohydrate: number()
      .typeError(
        t("validationMessages.amountMostBeNumber", {
          field: t("fields.carbohydrate"),
        })
      )
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.carbohydrate"),
        })
      )
      .nullable()
      .transform((_, v) => transform(v)),
    fat: number()
      .typeError(
        t("validationMessages.amountMostBeNumber", {
          field: t("fields.fat"),
        })
      )
      .nullable()
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.fat"),
        })
      )
      .transform((_, v) => transform(v)),
    protein: number()
      .typeError(
        t("validationMessages.amountMostBeNumber", {
          field: t("fields.protein"),
        })
      )
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.protein"),
        })
      )
      .nullable()
      .transform((_, v) => transform(v)),
    calories: number()
      .typeError(
        t("validationMessages.amountMostBeNumber", {
          field: t("fields.calories"),
        })
      )
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.calories"),
        })
      )
      .nullable()
      .transform((_, v) => transform(v)),
    originalPrice: number()
      .typeError(
        t("validationMessages.amountMostBeNumber", {
          field: t("fields.price"),
        })
      )
      .min(0, t("validationMessages.priceValidate"))
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.price"),
        })
      )
      .nullable()
      .transform((_, v) => transform(v)),
    discountedPrice: number()
      .typeError(
        t("validationMessages.amountMostBeNumber", {
          field: t("fields.discountedPrice"),
        })
      )
      .test({
        name: "discountedPrice",
        message: t("validationMessages.priceAfterDiscountValidate"),
        test: function (value) {
          return value ? value < parseFloat(this.parent.originalPrice) : true;
        },
      })
      .nullable()
      .transform((_, v) => transform(v, true)),
  };
  if (scope === "admin") {
    const another = {
      nutritionist: object()
        .required(
          t("validationMessages.fieldIsRequired", {
            field: t("fields.nutritionist"),
          })
        )
        .nullable(),
    };
    validationObject = {
      ...validationObject,
      ...another,
    };
  }
  return object().shape(validationObject);
};
