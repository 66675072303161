import {  Box, IconButton } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp, mdiPlus } from "@mdi/js";
import { INewDayMeal, TMealTitle } from "src/api/types/plan-days";

interface IDayMealProps {
    mealData: INewDayMeal;
    setActive: (value: INewDayMeal) => void;
}

const mealTitles:Record<TMealTitle,string> = {
    breakfast:"Breakfast",
    dinner:"Dinner",
    lunch:"Lunch",
    snack1:"Snack 1",
    snack2:"Snack 2",
    extra:"Extra Foods"
}

export const DayMeal: React.FC<IDayMealProps> = (props) => {
    const { mealData, setActive } = props;
    const [expanded, setExpanded] = useState<boolean>(false);
    const divRef = useRef<HTMLDivElement>(null);

    const onAddFoodClicked = () => {
        setActive(mealData);
    }

    const toggleFoodsExpanded = () => {
        setExpanded(!expanded);
    }

    return <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt="2" borderBottom="1px solid" borderColor="gray.200"
            mb="2">
            <Box>{`${mealTitles[mealData.title]} (${mealData.foods.length} foods)`}</Box>
            <Box display="flex" pr="2" pb="2">
                <IconButton icon={<Icon size="24px" path={mdiPlus} />} aria-label="Add Food" onClick={onAddFoodClicked}
                    colorScheme="whiteAlpha"
                    color="gray" />
                <IconButton icon={<Icon size="24px" path={expanded ? mdiChevronUp : mdiChevronDown} />} aria-label="Toggle Foods"
                    colorScheme="whiteAlpha"
                    color="gray"
                    ml="2"
                    onClick={toggleFoodsExpanded} />
            </Box>
        </Box>
        {
            <Box overflow="hidden" transition="all ease 0.2s" height={expanded ? `${divRef.current?.clientHeight}px` : "0"}>
                <Box ref={divRef} maxHeight="200px" overflowY="auto">
                    <div >
                        {
                            mealData.foods.map(f => (
                                <Box key={f.foodId} p="2" display="flex">
                                    <Box color="gray.800" pr="2">
                                        <span>{f.name}</span>
                                        <span>({`${f.value} ${f.unit}`})</span>
                                    </Box>
                                </Box>
                            ))
                        }
                    </div>
                </Box>
            </Box>
        }
    </Box>
}