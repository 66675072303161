import { Box } from "@chakra-ui/react";
import Icon from "@mdi/react";
import React from "react";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";

interface IUnitSelectProps {
    units: string[];
    selectedUnit: string;
    setSelectedUnit: (data: string) => void;
}

export const UnitSelect: React.FC<IUnitSelectProps> = (props) => {
    const { units, selectedUnit, setSelectedUnit } = props;

    const changeUnit = (target:"up" | "down")=>{
        const current = units.indexOf(selectedUnit);
        if(target === "up"){
            if(current > 0){
                setSelectedUnit(units[current-1])
            }
        }else{
            if(current < units.length-1){
                setSelectedUnit(units[current+1])
            }
        }
    }
    
    return (<Box ml="2">
        <Box display="flex" justifyContent="center" alignItems="center" cursor="pointer" onClick={()=>{changeUnit("up")}}>
            <Icon path={mdiChevronUp} size="24px" />
        </Box>
        <Box height="36px" overflow="hidden" >
            <Box mt={`${(units.indexOf(selectedUnit) * -36)}px`} transition="all linear 0.2s">
                {
                    units.map(u => (<Box key={u}  height="36px" lineHeight="36px" title={selectedUnit}>{selectedUnit}</Box>))
                }
            </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" cursor="pointer" onClick={()=>{changeUnit("down")}}>
            <Icon path={mdiChevronDown} size="24px" />
        </Box>
    </Box>);
}