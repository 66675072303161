export const planTypeFilters = [{
    label: "All",
    value: "all"
}, {
    label: "Normal",
    value: "normal"
}, {
    label: "Customized",
    value: "customized"
}];

export const statusFilters = [{
    label: "All",
    value: "all"
}, {
    label: "Not Purchased",
    value: "notPurchased"
}, {
    label: "Ordered",
    value: "ordered"
}, {
    label: "Available",
    value: "available"
}, {
    label: "Activated",
    value: "activated"
}, {
    label: "Canceled",
    value: "canceled"
}, {
    label: "Finished",
    value: "finished"
}];