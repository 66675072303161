import { Box } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import { ROLES } from "src/constants/roles";
import { useGlobal } from "src/global-context/GlobalContext";
import PermissionDenied from "src/pages/PermissionDenied";

const AdminLayout: React.FC = () => {
    const {user} = useGlobal();
    return (
        <Box>
            <div>
                {
                    user?.role === ROLES.ADMIN ? (
                        <Outlet />
                    ) : (
                        <PermissionDenied />
                    )
                }
            </div>
        </Box>
    );
}

export default AdminLayout;