import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { adminUsersService } from "src/api/services/admin-users";
import { IEndUserDetail } from "src/api/types/admin-user";
import ContentView from "src/components/app/ContentView";
import EndUserSubscribedPlans from "src/components/app/EndUserSubscribedPlans";
import UserInformation from "src/components/app/UserInformation";
import { useTitle } from "src/hooks/useTitle";
import ROUTE_CONSTANTS from "src/Routes/route-constants";

const UserDetails: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.users"));
  const params = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [getController] = useState<AbortController>(new AbortController());
  const [details, setDetails] = useState<IEndUserDetail | undefined>();

  const getDetail = useCallback(async () => {
    try {
      setLoading(true);
      if (params.id) {
        const result = await adminUsersService.getDetail(
          params.id,
          getController.signal
        );
        setDetails(result.data.payload);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [params, getController.signal]);

  useEffect(() => {
    getDetail();
    return () => {
      getController.abort();
    };
  }, [getDetail, getController]);

  return (
    <ContentView>
      {loading && (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      )}
      {details && (
        <>
          <Box textAlign="right" mb="4">
            <Button as={Link} colorScheme="brand" size="md" type="submit" mr="2"
              to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.USERS.ABSOLUTE}>{t("actions.back")}</Button>
          </Box>
          <UserInformation info={details} />
          <Divider pt="5" />
          <Text fontSize="3xl" fontWeight={"bold"} mt="5">
            Subscribed Plans
          </Text>
          <Grid templateColumns="repeat(3, 1fr)" gap={10} mt="3">
            {details.subscribePlans?.map((s) => (
              <GridItem w="100%" key={s._id}>
                <EndUserSubscribedPlans info={s} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
    </ContentView>
  );
};

export default UserDetails;
