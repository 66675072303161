import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  IAdminCreatePlanForm,
  IAdminDetailPlanPayload,
  IAdminListPlanPayload,
} from "../types/admin-plans";
import { IAxiosResponse } from "../types/axios-response";
import { INewPlanDay, IPlanDay } from "../types/plan-days";
import {
  IPlanChangeStatusPayload,
  IPlanRejectionPayload,
  PlanStatus,
} from "../types/plan-status";

export const adminPlansService = {
  create(body: IAdminCreatePlanForm) {
    return AxiosInstance.post<IAxiosResponse<IAdminDetailPlanPayload>>(
      API_ROUTES.PANEL.PLANS.ROOT,
      body
    );
  },
  getAll(abortSignal: AbortSignal, searchQuery?: string) {
    return AxiosInstance.get<IAxiosResponse<IAdminListPlanPayload[]>>(
      `${API_ROUTES.PANEL.PLANS.ROOT}${searchQuery && `?${searchQuery}`}`,
      {
        signal: abortSignal,
      }
    );
  },
  getDetail(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IAdminDetailPlanPayload>>(
      API_ROUTES.PANEL.PLANS.GET_ITEM(id),
      {
        signal: abortSignal,
      }
    );
  },
  update(data: IAdminCreatePlanForm, id: string) {
    const finalBody: any = { ...data };
    return AxiosInstance.put<IAxiosResponse<IAdminDetailPlanPayload>>(
      API_ROUTES.PANEL.PLANS.GET_ITEM(id),
      finalBody
    );
  },
  addDay(planId: string, dayDetails: INewPlanDay) {
    const body = {
      dayNo: dayDetails.dayNo,
      meals: dayDetails.meals.map((m) => {
        return {
          title: m.title,
          foods: m.foods.map((f) => {
            return {
              foodId: f.foodId,
              value: f.value,
              unit: f.unit,
            };
          }),
        };
      }),
    };
    return AxiosInstance.post<IAxiosResponse<IPlanDay>>(
      API_ROUTES.PANEL.PLANS.ADD_DAY(planId),
      body
    );
  },
  editDay(planId: string, dayDetails: INewPlanDay) {
    const body = {
      meals: dayDetails.meals.map((m) => {
        return {
          title: m.title,
          foods: m.foods.map((f) => {
            return {
              foodId: f.foodId,
              value: f.value,
              unit: f.unit,
            };
          }),
        };
      }),
    };
    return AxiosInstance.put<IAxiosResponse<IPlanDay>>(
      API_ROUTES.PANEL.PLANS.EDIT_DAY(planId, dayDetails.dayNo),
      body
    );
  },
  deleteDay(planId: string, dayNumber: number) {
    return AxiosInstance.delete<IAxiosResponse<IPlanDay>>(
      API_ROUTES.PANEL.PLANS.EDIT_DAY(planId, dayNumber)
    );
  },
  changeStatus(planId: string, status: PlanStatus) {
    return AxiosInstance.put<IAxiosResponse<IPlanChangeStatusPayload>>(
      API_ROUTES.PANEL.PLANS.CHANGE_STATUS(planId, status)
    );
  },
  rejection(planId: string, rejectionMessage: string) {
    const body = {
      rejectionMessage,
    };

    return AxiosInstance.put<IAxiosResponse<IPlanRejectionPayload>>(
      API_ROUTES.PANEL.PLANS.CHANGE_STATUS(planId, PlanStatus.REJECTED),
      body
    );
  },
  changeActivation(planId:string,activation:boolean){
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.PANEL.PLANS.CHANGE_ACTIVATION(planId),
      {
        activation
      }
    );
  }
};
