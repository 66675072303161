import { Box } from "@chakra-ui/react";
import React from "react";

const ContentView: React.FC = ({ children }) => {
    return (<Box bg="white" borderRadius="12" p={[2,4,6,8]} boxShadow="sm" position="relative">
        {
            children
        }
    </Box>);
}

export default ContentView;