export const cacheService = {
    handleCached(axiosConfig: any) {
      if (axiosConfig.method.toLowerCase() === 'get') {
        if (axiosConfig.customCache) {
          let cachedKey = axiosConfig.url;
          if (axiosConfig.baseURL) {
            cachedKey = axiosConfig.baseURL + cachedKey;
          }
          axiosConfig.cacheKey = cachedKey;
          const previousData = sessionStorage.getItem(cachedKey);
          if (previousData) {
            return Promise.reject({
              response: {
                status: 700,
                data: JSON.parse(previousData),
              },
            });
          }
        }
      }
      return axiosConfig;
    },
    setItem(key: string, data: any) {
      sessionStorage.setItem(key, JSON.stringify(data));
    },
  };