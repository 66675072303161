import { Box, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { paymentService } from "src/api/services/payment";
import CheckoutFailure from "./components/CheckoutFailure";
import CheckoutSuccess from "./components/CheckoutSuccess";

const CheckoutStatus: React.FC = () => {
    let [searchParams] = useSearchParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [orderInfoController] = useState<AbortController>(new AbortController());
    const [paymentOrderInfo, setPaymentOrderInfo] = useState<{
        planId: string;
        planType: string;
        isSuccess: boolean;
        tryAgainUrl?: string;
    }>();

    const orderId = searchParams.get("orderId") || "";
    const senderIsMobile = !!searchParams.get("isMobile") || true;

    useEffect(() => {
        const getPaymentOrderInfo = async () => {
            setLoading(true);
            try {
                const result = await paymentService.getPaymentInfo(senderIsMobile, orderId, orderInfoController.signal);
                const info = result.data.payload;
                setPaymentOrderInfo({
                    planId: info.plan.id,
                    planType: info.plan.type,
                    isSuccess: info.payment.success,
                    tryAgainUrl: info.payment.url
                });
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
        getPaymentOrderInfo();

        return () => {
            orderInfoController.abort();
        }
    }, []);

    const renderCheckoutStateContent = () => {
        if (paymentOrderInfo?.isSuccess === undefined) {
            return <Text>An Error Occured!</Text>;
        }

        if (paymentOrderInfo?.isSuccess) {
            return paymentOrderInfo.planType === "normal"
                ? <CheckoutSuccess planId={paymentOrderInfo.planId}>
                    <Text fontSize="0.53em" color="#525252">You can start your plan <Text as="span" color="#0AA9B1">tomorrow</Text></Text>
                </CheckoutSuccess>
                : <CheckoutSuccess planId={paymentOrderInfo.planId}>
                    <Text fontSize="0.53em" color="#525252">You will receive your <br /> customized plan in <Text as="span" color="#0AA9B1">48 hours.</Text></Text>
                </CheckoutSuccess>
        } else {
            return <CheckoutFailure tryAgainUrl={paymentOrderInfo?.tryAgainUrl || ""} />
        }
    }

    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        {loading
            ? <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg" />
            : (!orderId || !senderIsMobile) ? <Text>Invalid Data</Text> : renderCheckoutStateContent()
        }
    </Box>;
}

export default CheckoutStatus;