import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import FormRow from "../FormRow";
import { IEntityDetailsField } from "./types";



interface IEntityDetailsProps {
    items: (IEntityDetailsField | null)[];
}


const EntityDetails: React.FC<IEntityDetailsProps> = (props) => {
    const { items } = props;
    const [filteredItems,setFilteredItems] = useState<IEntityDetailsField[]>([]);
    useEffect(()=>{
        const tempData = items.filter(item=>!!item);
        setFilteredItems(tempData as IEntityDetailsField[]);
    },[items]);
    return <>
        {
            filteredItems.map(item => (
                <div key={item.data.label} style={{borderBottom:item.divide ?"1px solid #ccc":"",marginBottom:item.divide?"24px":"0"}}>
                    <FormRow
                        bidirectional={item.bidirectional}
                        leftContent={
                            <Box display="flex">
                                <Text textTransform={"capitalize"} as="span" fontWeight="bold">
                                    {item.data.label}:
                                </Text>
                                <Text as="span" ml={"2"}>
                                    {item.data.value}
                                </Text>
                            </Box>
                        }
                        rightContent={
                            item.arabicData ? (
                                <Box display="flex">
                                    <Text textTransform={"capitalize"} as="span" fontWeight="bold">
                                        {item.arabicData.label}:
                                    </Text>
                                    <Text as="span" ml={"2"}>
                                        {item.arabicData.value}
                                    </Text>
                                </Box>
                            ) : <span></span>
                        }
                    />
                </div>
            ))
        }
    </>
}

export default EntityDetails;