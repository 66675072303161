import { Box, Button, IconButton, Select } from "@chakra-ui/react";
import {  mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { usePagination } from "./usePagination";


interface IPaginationProps {
  defaultActivePage: number;
  onPageChanged: (page: number) => void;
  pageSize?: number;
  onPageSizeChanged:(value:number)=>void;
  totalRecordsCount: number;
  entity:string;
}

const Pagination: React.FC<IPaginationProps> = (props) => {
  const { defaultActivePage, onPageChanged, pageSize = DEFAULT_PAGE_SIZE, totalRecordsCount,entity,onPageSizeChanged } = props;
  const {
    pages,
    pageCount,
    setTotalRecordsCount,
    activePage,
    canGoNext,
    canGoPrevious,
    setPageSize,
    goToPage
  } = usePagination(defaultActivePage);

  const [pageRecordsSize,setPageRecordsSize] = useState<string>(pageSize+"");
  const [pageSizeList] = useState<number[]>([20,30,40,50]);
  


  useEffect(() => {
    setTotalRecordsCount(totalRecordsCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRecordsCount]);

  useEffect(() => {
    console.log(pageSize);
    setPageRecordsSize(pageSize+"");
    setPageSize(pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);



  const onNextClicked = () => {
    goToPage(activePage - 1);
    onPageChanged(activePage - 1)
  }
  const onPreviousClicked = () => {
    goToPage(activePage + 1);
    onPageChanged(activePage + 1)
  }
  const onPageClicked = (sender: number) => {
    goToPage(sender);
    onPageChanged(sender);
  }

  const onGoToLastPageClicked =()=>{
    goToPage(pages.length);
    onPageChanged(pages.length);
  }

  const onGoToFirstPageClicked =()=>{
    goToPage(1);
    onPageChanged(1);
  }

  const onPSizeChanged = (e:React.ChangeEvent<HTMLSelectElement>)=>{
    onPageSizeChanged(parseInt(e.target.value));
    setPageRecordsSize(e.target.value);
  }

  return <Box display="flex" mt="6" justifyContent="space-between" alignItems="center">
    <Box ml="3" mr="3" display="flex" alignItems="center">
      <Box mr="2">View</Box>
      <Box>
        <Select value={pageRecordsSize} onChange={onPSizeChanged}>
          {
            pageSizeList.map(p=>(
              <option key={p} value={p}>{p}</option>
            ))
          }
        </Select>
      </Box>
      <Box ml="2">{entity} per page</Box>
      {/* <Box mr="2">Page: {activePage}/{pageCount}</Box>
      <Box mr="2">Page Size: {pageSize}</Box>
      <Box>Total Count: {totalRecordsCount}</Box> */}
    </Box>
    <Box display="flex" alignItems="center">
      <Box fontSize="0.875rem" color="gray.600">
        Showing {((activePage-1)*pageSize)+1} to {activePage === pageCount ? totalRecordsCount:activePage*pageSize} of {totalRecordsCount} records
      </Box>
      <IconButton icon={<Icon path={mdiChevronDoubleLeft} size="24px"/>} aria-label="First" bg="transparent" color="gray.600" mr="2"
        disabled={activePage === 1} onClick={onGoToFirstPageClicked}/>
      <IconButton icon={<Icon path={mdiChevronLeft} size="24px"/>} aria-label="Previous" bg="transparent" color="gray.600" mr="2"
        disabled={!canGoPrevious} onClick={onNextClicked}/>
      <Box ml="2" mr="2">
        {
          pages.map(p => (
            <Button type="button" key={`pagination_btn_${p.text}`} onClick={() => { onPageClicked(p.index) }}
            bg="transparent" color={p.index === activePage ?"gray.900":"gray.600"} mr="1" ml="1">{p.text}</Button>
          ))
        }
      </Box>
      <IconButton icon={<Icon path={mdiChevronRight} size="24px"/>} aria-label="Next" bg="transparent" color="gray.600" mr="2"
        disabled={!canGoNext} onClick={onPreviousClicked}/>
      <IconButton icon={<Icon path={mdiChevronDoubleRight} size="24px"/>} aria-label="Latest" bg="transparent" color="gray.600" mr="2"
        disabled={activePage === pages.length} onClick={onGoToLastPageClicked}/>
    </Box>

  </Box>
}

export default Pagination;