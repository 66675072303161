import React, { useEffect, useState } from "react";
import { adminPlansService } from "src/api/services/admin-plan";
import { nutritionistPlansService } from "src/api/services/nutritionist-plans";
import { adminSubscriptionService } from "src/api/services/admin-subscriptions";
import { nutritionistSubscriptionService } from "src/api/services/nutritionist.subscription";
import { INewPlanDay, IPlanDay } from "src/api/types/plan-days";
import { HTTP_STATUS_CODES } from "src/constants/http-status-codes";
import { contextFactory } from "src/utils/context-factory";
import { IPlanDaysContext, IPlanDaysProps, TServiceKey } from "./types.d";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useGlobal } from "src/global-context/GlobalContext";

const services = {
    "adminPlan": adminPlansService,
    "adminSubscription": adminSubscriptionService,
    "nutritionistPlan": nutritionistPlansService,
    "nutritionistSubscription": nutritionistSubscriptionService,
}

export const [PlanDaysContext, usePlandays] = contextFactory<IPlanDaysContext>("PlanDaysContext", "usePlandays");

export const PlanDaysProvider: React.FC<IPlanDaysProps> = (props) => {
    const { children, days: defaultDays, isEditable = true, planOrSubscriptionId, duration, onUpdate, isCustomPlanSubscription = false } = props;
    const { t } = useTranslation();
    const { user } = useGlobal();
    const [days, setDays] = useState<IPlanDay[]>([]);
    const [openAddDayModal, setOpenAddDayModal] = useState<boolean>(false);
    const [addingDay, setAddingDay] = useState<boolean>(false);
    const [editingDayTarget, setEditingDayTarget] = useState<INewPlanDay | null>(null);
    const [deletingDayNumber, setDeletingDayNumber] = useState<number>(-1);
    const [serviceKey,setServiceKey] = useState<TServiceKey>("adminPlan")
    const toast = useToast();


    useEffect(() => {
        //TODO: Set Days based on default days changes.
        setDays(defaultDays);
        const tempDays: IPlanDay[] = [];
        for (let i = 1; i < duration + 1; i++) {
            const existingDay = defaultDays.find(dd => dd.dayNo === i);
            if (existingDay) {
                tempDays.push({ ...existingDay });
            } else {
                tempDays.push({
                    dayNo: i,
                    meals: []
                })
            }
        }
        setDays(tempDays);
    }, [defaultDays, duration]);

    useEffect(() => {
        let sKey = "";
        if (user) {
            sKey += user.role === "admin" ? "admin" : "nutritionist";
            sKey += isCustomPlanSubscription ? "Subscription" :"Plan";
            setServiceKey(sKey as TServiceKey);
        }
    }, [user, isCustomPlanSubscription]);

    const addDay = async (data: INewPlanDay) => {
        try {
            setAddingDay(true);
            let apiResult: IPlanDay;
            const tempData: INewPlanDay = {
                dayNo: data.dayNo,
                meals: data.meals.filter(dm => dm.foods.length > 0)
            };

            if (editingDayTarget) {
                const result = await services[serviceKey].editDay(planOrSubscriptionId, tempData);
                apiResult = result.data.payload;
            } else {
                const result = await services[serviceKey].addDay(planOrSubscriptionId, tempData);
                apiResult = result.data.payload;
            }

            const tempDays = [...days];
            const targetIndex = tempDays.findIndex(td => td.dayNo === data.dayNo);
            tempDays[targetIndex] = {
                ...apiResult
            };
            setDays(tempDays);
            onUpdate && onUpdate(tempDays);
            setOpenAddDayModal(false);
            setEditingDayTarget(null);
            if (editingDayTarget) {
                toast({
                    status: "success",
                    description: t("messages.daySuccessfullyEdited")
                });
            } else {
                toast({
                    status: "success",
                    description: t("messages.daySuccessfullyAdded")
                });
            }
        } catch (err) {

        } finally {
            setAddingDay(false);
        }

    };
    const removeDay = async (dayNumber: number) => {
        try {
            setDeletingDayNumber(dayNumber);
            const result = await services[serviceKey].deleteDay(planOrSubscriptionId, dayNumber);
            if (result.status === HTTP_STATUS_CODES.SUCCESS_NO_CONTENT) {
                const ind = days.findIndex(d => d.dayNo === dayNumber);
                const tempDays = [...days];
                // tempDays[ind] = {dayNo:tempDays[ind].dayNo,meals:[]};
                tempDays.splice(ind,1);
                setDays(tempDays);

                onUpdate && onUpdate(tempDays);
                toast({
                    status: "success",
                    description: t("messages.daySuccessfullyDeleted")
                });
            }


        } catch (err) {
            toast({
                status: "error",
                description: t("messages.deleteDayProblem")
            });
        } finally {
            setDeletingDayNumber(-1);
        }

    };
    
    useEffect(()=>{
        if(!openAddDayModal){
            setEditingDayTarget(null);
        }
    },[openAddDayModal]);



    return <PlanDaysContext.Provider value={{
        days,
        addDay,
        removeDay,
        openAddDayModal,
        setOpenAddDayModal,
        isEditable,
        addingDay,
        setAddingDay,
        setEditingDayTarget,
        editingDayTarget,
        deletingDayNumber,
        setDeletingDayNumber,
        duration
    }}>
        {
            children
        }
    </PlanDaysContext.Provider>
}