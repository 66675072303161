import React, { useState } from "react";
import { Badge, Box, Button, Text } from "@chakra-ui/react";
import { useMobilePageContext } from "../../MobileHomePageContext";
import { IMobileSection } from "src/api/types/admin-mobile-home-page-sections";
import PlanCard from "../PlanCard";
import NutritionistCard from "../NutritionistCard";
import { useConfirmModal } from "src/hooks/useConfirmModal";

interface ISectionProps {
    data: IMobileSection
}

const Section: React.FC<ISectionProps> = (props) => {
    const { data } = props;
    const { setOpenAddChildModal, setActiveSetion, setOpenAddSectionModal, deleteSection,changeSectionActivation } = useMobilePageContext();
    const [changingActivation,setChangingActivation] = useState<boolean>(false);

    const { openModal } = useConfirmModal<boolean>({
        confirmLabel: "Yes",
        onConfirm: (isDelete?:boolean) => {
            if (isDelete) {
                deleteSection(data._id);
            }else{
                setChangingActivation(true);
                changeSectionActivation(data._id,()=>{
                    setChangingActivation(false);
                })
            }
        },
    });


    const onAddChildClicked = () => {
        setActiveSetion(data);
        setOpenAddChildModal(true);
    }

    const getChildren = () => {
        if (data.type === "nutritionist") {
            return data.items.map(d => (<NutritionistCard data={d} key={d._id} />))
        } else {
            return data.items.map(d => (<PlanCard data={d} key={d._id} />))
        }
    }

    const onEditClicked = () => {
        setActiveSetion(data);
        setOpenAddSectionModal(true);
    }

    const onDeleteClicked = () => {
        openModal(true);
    }

    const onActivationClicked = ()=>{
        openModal(false);
    }

    return <Box mb="4" borderWidth='2px' borderRadius='lg'>
        <Box display="flex" alignItems="center" textTransform={"capitalize"} justifyContent="space-between" p="3"
            borderBottom="1px solid" borderColor="gray.300">
            <Text as="h3" fontSize="1.4rem" mr="3">
                {data.title_en}
                <Badge colorScheme="orange" marginLeft="3">{data.type}</Badge>
            </Text>
            <Box>
                <Button onClick={onAddChildClicked} variant="outline" size="sm">Manage {data.type === "nutritionist"?"Nutritionists":"Plans"}</Button>
                <Button onClick={onActivationClicked} variant="outline" size="sm" colorScheme="brand" ml="2"
                    isLoading={changingActivation}>{data.active?"Deactivate":"Activate"}</Button>
                {
                    data.type !== "banner" && (<>
                        <Button onClick={onDeleteClicked} variant="outline" size="sm" colorScheme="red" ml="2">Delete</Button>
                        <Button onClick={onEditClicked} variant="outline" size="sm" colorScheme="green" ml="2">Edit</Button>
                    </>)
                }
            </Box>
        </Box>
        <Box p="4">
            {
                getChildren()
            }
        </Box>
    </Box>
}


export default Section;
