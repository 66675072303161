import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  IconButton,
  Switch,
} from "@chakra-ui/react";
import Icon from "@mdi/react";
import { mdiMagnify, mdiPlus, mdiTune, mdiPencil } from "@mdi/js";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { adminNutritionistsService } from "src/api/services/admin-nutritionists";
import { IAdminNutritionistListPayload } from "src/api/types/admin-nutritionist";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import dateHelper from "src/utils/date-helper";
import { NUTRITIONIST_SPECIALITIES } from "src/constants/notritionist-specialties";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import EmptyResult from "src/components/app/EmptyResult";
import { useSearch } from "src/hooks/useSearch";
import { useConfirmModal } from "src/hooks/useConfirmModal";
import Pagination from "src/components/app/Pagination";

const Nutritionists: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.nutritionists"));
  const {
    searchQuery,
    setSearchQuery,
    callApiController,
    searchResult: nutritionists,
    resultMeta:nutritionistsMeta,
    loading,
    setSearchResult,
    filters,
    setFilters
  } = useSearch<IAdminNutritionistListPayload[]>({
    searchApi: adminNutritionistsService.getAll,
    filterItems:["pageNumber","pageSize"]
  });

  const [searchValue, setSearchValue] = useState<string>(searchQuery || "");

  const { openModal } = useConfirmModal<IAdminNutritionistListPayload>({
    confirmLabel:"Yes",
    onConfirm: (data?: IAdminNutritionistListPayload) => {
      if (data) {
        adminNutritionistsService.changeActivation(data._id,!data.active);
        const tempItems = [...nutritionists];
        const itemIndex = tempItems.findIndex((ti) => ti._id === data._id);
        tempItems[itemIndex].active = !tempItems[itemIndex].active;
        setSearchResult(tempItems);
      }
    },
  });

  useEffect(() => {
    setSearchValue(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    return () => {
      callApiController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    setSearchQuery(searchValue);
  };

  const changePage = (page:number)=>{
    setFilters({ ...filters, "pageNumber": `${page}` });
  }

  const onSearchQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  const toggleActivation = (
    sender: IAdminNutritionistListPayload,
    index: number
  ) => {
    const title = `${sender.active ? "Deactivate" : "Activate"} Nutritionist`;
    const message = `${sender.active ? "Deactivating" : "Activating"} <b>${
      sender.firstname_en
    } ${sender.lastname_en}</b>, are you sure?`;
    openModal(sender, title, message);
  };

  const onPageSizeChanged = (value:number)=>{
    setFilters({ ...filters, "pageSize": `${value}`,"pageNumber": `1` });
  }

  return (
    <Box>
      <Text as="h3" fontSize="3xl">
         Nutritionists
      </Text>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray"
              children={<Icon path={mdiMagnify} size="24px" />}
            />
            <Input
              type="search"
              placeholder={t("actions.search")}
              value={searchValue}
              onChange={onSearchQueryChanged}
              backgroundColor="white"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearch();
                }
              }}
            />
            <Button ml="3" onClick={() => onSearch()} isLoading={loading}>
              {t("actions.search")}
            </Button>
          </InputGroup>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton
              aria-label="Test"
              icon={<Icon path={mdiTune} size="28px" />}
              color="gray.700"
              bg="transparent"
            />
          </Box>
          <Box ml="4">
            <Button
              leftIcon={<Icon path={mdiPlus} size="24px" />}
              as={Link}
              to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.CREATE.ABSOLUTE}
            >
              {t("actions.add")}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box height="5px" mb="1">
        {loading && <HorizontalLoading />}
      </Box>
      <Table
        variant="simple"
        colorScheme="blackAlpha"
        size="md"
        boxShadow="md"
        backgroundColor="white"
        borderRadius="md"
      >
        <Thead>
          <Tr>
            <Th></Th>
            <Th>{t("fields.fullName")}</Th>
            <Th>{t("fields.email")}</Th>
            <Th>{t("fields.status")}</Th>
            <Th>{t("fields.specialty")}</Th>
            <Th>{t("fields.createdAt")}</Th>
            <Th>{t("fields.updatedAt")}</Th>
            <Th>{t("fields.isActive")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {nutritionists.map((n, ind) => (
            <Tr key={n._id}>
              <Td>
              <Text as="span" color="gray.400">{((nutritionistsMeta.pageNumber-1)*nutritionistsMeta.pageSize)+(ind+1)}</Text>
              </Td>
              <Td color="blue.500">
                <Link
                  to={
                    ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.DETAILS.BY_DATA(
                      n._id
                    ).ABSOLUTE
                  }
                >{`${n.firstname_en} ${n.lastname_en}`}</Link>
              </Td>
              <Td>{n.email}</Td>
              <Td>{n.status || "---"}</Td>
              <Td>{NUTRITIONIST_SPECIALITIES.english[n.specialty_en]}</Td>
              <Td>{dateHelper.formatDate(n.createdAt)}</Td>
              <Td>{dateHelper.formatDate(n.updatedAt)}</Td>
              <Td>
                {
                  <Switch
                    isChecked={n.active}
                    onChange={() => {
                      toggleActivation(n, ind);
                    }}
                  />
                }
              </Td>
              <Td>
                <IconButton
                  aria-label="Test"
                  icon={<Icon path={mdiPencil} size="28px" />}
                  color="gray.100"
                  bg="blue.300"
                  as={Link}
                  to={
                    ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.EDIT.BY_DATA(n._id)
                      .ABSOLUTE
                  }
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {!nutritionists.length && !loading && <EmptyResult />}
      <Pagination pageSize={nutritionistsMeta.pageSize} defaultActivePage={nutritionistsMeta.pageNumber} 
        totalRecordsCount={nutritionistsMeta.totalCount} onPageChanged={changePage}
        onPageSizeChanged={onPageSizeChanged}
        entity="nutritionist"/>
    </Box>
  );
};

export default Nutritionists;
