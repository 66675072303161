import React from "react";
import { useTitle } from "src/hooks/useTitle";
import { useTranslation } from "react-i18next";
import SubscriptionDetails from "src/components/app/SubscriptionDetails";


const SubscriptionDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.planSubscriptions"));
  

  return <SubscriptionDetails />
};

export default SubscriptionDetailsPage;
