import { Avatar, Box, Button, Center, Divider, IconButton, Spinner, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentView from "src/components/app/ContentView";
import FormRow from "src/components/app/FormRow";
import { useTitle } from "src/hooks/useTitle";
import { Link, useParams } from "react-router-dom";
import { adminNutritionistsService } from "src/api/services/admin-nutritionists";
import { HTTP_STATUS_CODES } from "src/constants/http-status-codes";
import { IAdminNutritionistProfile } from "src/api/types/admin-nutritionist";
import { NUTRITIONIST_SPECIALITIES } from "src/constants/notritionist-specialties";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import dateHelper from "src/utils/date-helper";
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";
import { useConfirmModal } from "src/hooks/useConfirmModal";

const NutritionistDetails: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.nutritionistProfile"));
    const params = useParams<{ id: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [getProfileController] = useState<AbortController>(new AbortController());
    const [userProfile, setUserProfile] = useState<IAdminNutritionistProfile | undefined>();

    const { openModal } = useConfirmModal<IAdminNutritionistProfile>({
        confirmLabel: "Yes",
        onConfirm: (data?: IAdminNutritionistProfile) => {
            if (data) {
                if (userProfile) {
                    adminNutritionistsService.changeActivation(data._id, !data.active);
                    setUserProfile({ ...userProfile, active: !userProfile?.active })
                }
            }
        },
    });

    const getProfile = useCallback(async () => {
        try {
            setLoading(true);
            if (params.id) {
                const result = await adminNutritionistsService.getNutritionistProfile(params.id, getProfileController.signal);
                if (result.status === HTTP_STATUS_CODES.SUCCESS) {
                    setUserProfile(result.data.payload);
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [params, getProfileController.signal])

    useEffect(() => {
        getProfile();
        return () => {
            getProfileController.abort();
        }
    }, [getProfile, getProfileController]);

    const toggleActivation = () => {
        if (userProfile) {
            const title = `${userProfile.active ? "Deactivate" : "Activate"} Nutritionist`;
            const message = `${userProfile.active ? "Deactivating" : "Activating"} <b>${userProfile.email}</b>, are you sure?`;
            openModal(userProfile, title, message);
        }
    }

    return (
        <ContentView>
            {
                loading && (<Center>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    /></Center>)
            }
            {
                userProfile && (<>
                    <Box position="absolute" top="6" right="6">
                        <Button as={Link} colorScheme="brand" size="md" type="submit" mr="2"
                            to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.ROOT.ABSOLUTE}>{t("actions.back")}</Button>
                        <Button
                            colorScheme={userProfile.active ? "red" : "green"}
                            size="md"
                            mr="3"
                            onClick={toggleActivation}>{userProfile.active ? "Deactivate" : "Activate"}</Button>
                        <IconButton aria-label="Test" icon={<Icon path={mdiPencil} size="28px" />}
                            color="gray.100"
                            bg="blue.300" as={Link} to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.EDIT.BY_DATA(userProfile._id).ABSOLUTE} />
                    </Box>
                    <Box mb="6">
                        <Avatar name={`${userProfile.firstname_en} ${userProfile.lastname_en}`} size="xl" src={userProfile.avatar}
                        />
                    </Box>
                    <FormRow
                        leftContent={<Box display="flex" >
                            <Text as="span">{userProfile.email}</Text>
                        </Box>}
                    />
                    <Divider mb="6" />
                    <FormRow
                        bidirectional
                        leftContent={<Box display="flex" >
                            <Text as="span" mr="2" fontWeight="bold">{t("fields.fullName")}:</Text>
                            <Text as="span">{`${userProfile.firstname_en} ${userProfile.lastname_en}`}</Text>
                        </Box>}
                        rightContent={<Box display="flex">
                            <Text as="span" ml="2" fontWeight="bold">{t("fields.firstName_ar")}:</Text>
                            <Text as="span">{`${userProfile.firstname_ar} ${userProfile.lastname_ar}`}</Text>
                        </Box>}
                    />
                    <FormRow
                        bidirectional
                        leftContent={<Box display="flex" >
                            <Text as="span" mr="2" fontWeight="bold">{t("fields.specialty")}:</Text>
                            <Text as="span">{NUTRITIONIST_SPECIALITIES.english[userProfile.specialty_en]}</Text>
                        </Box>}
                        rightContent={<Box display="flex">
                            <Text as="span" ml="2" fontWeight="bold">{t("fields.specialty_ar")}:</Text>
                            <Text as="span">{NUTRITIONIST_SPECIALITIES.arabic[userProfile.specialty_en]}</Text>
                        </Box>}
                    />
                    <FormRow
                        bidirectional
                        leftContent={<Box display="flex" >
                            <Text as="span" mr="2" fontWeight="bold">{t("fields.about")}:</Text>
                            <Text as="span">{userProfile.aboutMe_en}</Text>
                        </Box>}
                        rightContent={<Box display="flex">
                            <Text as="span" ml="2" fontWeight="bold">{t("fields.about_ar")}:</Text>
                            <Text as="span">{userProfile.aboutMe_ar}</Text>
                        </Box>}
                    />
                    <FormRow
                        leftContent={<Box display="flex" >
                            <Text as="span" mr="2" fontWeight="bold">{t("fields.createdAt")}:</Text>
                            <Text as="span">{dateHelper.formatDate(userProfile.createdAt)}</Text>
                        </Box>}
                    />
                    <FormRow
                        leftContent={<Box display="flex" >
                            <Text as="span" mr="2" fontWeight="bold">{t("fields.updatedAt")}:</Text>
                            <Text as="span">{dateHelper.formatDate(userProfile.updatedAt)}</Text>
                        </Box>}
                    />
                </>)
            }
        </ContentView>
    );
}

export default NutritionistDetails;