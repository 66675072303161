import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAdminUserListPayload, IEndUserDetail } from "../types/admin-user";
import { IAxiosResponse } from "../types/axios-response";

export const adminUsersService = {
  create() {
    // return AxiosInstance.post<IAxiosResponse<ICreateNutritionistResponsePayload>>(API_ROUTES.ADMIN.NUTRITIONISTS.ROOT,data);
  },
  getAll(abortSignal: AbortSignal, searchQuery?: string) {
    return AxiosInstance.get<IAxiosResponse<IAdminUserListPayload[]>>(
      `${API_ROUTES.PANEL.USERS.ROOT}${
        searchQuery && `?${searchQuery}`
      }`,
      {
        signal: abortSignal,
      }
    );
  },
  changeActivation(userId: string, activation: boolean) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.PANEL.USERS.CHANGE_ACTIVATION(userId),
      {
        activation,
      }
    );
  },

  getDetail(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IEndUserDetail>>(
      API_ROUTES.PANEL.USERS.GET_ITEM(id),
      {
        signal: abortSignal,
      }
    );
  },
};
