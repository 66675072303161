import { Box, Text, Image } from "@chakra-ui/react";
import React from "react";
import { ISectionChildItem } from "src/api/types/admin-mobile-home-page-sections";

interface IPlanCardProps {
    data: ISectionChildItem
}

const PlanCard: React.FC<IPlanCardProps> = (props) => {
    const { data } = props;
    return <Box boxShadow="md" display="inline-block" width="200px" mr="4" borderRadius={"md"} textTransform={"capitalize"}
        opacity={data.active?"1":"0.5"}>
        <Box mb="3">
            <Image objectFit='cover' src={data.thumbnail_en} alt={data.title_en}
                fallbackSrc="https://www.qiteplanguage.org/assets/img/noimage2.png"
            />
        </Box>
        <Box fontSize='1rem' p="3">
            {data.title_en}
        </Box>
        <Box display="flex" alignItems="center" p="3">
            <Image
                borderRadius='full'
                boxSize='40px'
                src={data.nutritionist?.avatar}
                alt={data.nutritionist?.firstname_en}
                objectFit="cover"
                mr="4"
            />
            <Text fontSize="0.875rem">{`${data.nutritionist?.firstname_en} ${data.nutritionist?.lastname_en}`}</Text>

        </Box>
    </Box>
}

export default PlanCard;