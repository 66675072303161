import { IDashboardLayoutContext } from "./types.d";
import React, { useEffect, useState } from "react";
import { contextFactory } from "src/utils/context-factory";
import { useMediaQuery } from '@chakra-ui/react'
import { useLocation } from "react-router-dom";


export const [DashboardLayoutContext, useDashboardLayout] =
    contextFactory<IDashboardLayoutContext>("DashboardLayoutContext", "useDashboardLayout");


export const DashboardLayoutProvider: React.FC = ({ children }) => {
    const [isLowerThan600] = useMediaQuery('(max-width: 600px)');
    const location = useLocation();
    const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(!isLowerThan600);


    useEffect(() => {
        if (isLowerThan600) {
            setDrawerIsOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <DashboardLayoutContext.Provider value={{ drawerIsOpen, setDrawerIsOpen }}>
            {
                children
            }
        </DashboardLayoutContext.Provider>
    );
}