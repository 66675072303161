import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { URLSearchParamsInit, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { IAxiosResponse } from "src/api/types/axios-response";
import { IPaylodListMeta } from "src/api/types/base";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";

interface IUseSearchProps<T> {
  searchApi: (
    abortSignal: AbortSignal,
    q: string
  ) => Promise<AxiosResponse<IAxiosResponse<T>, any>>;
  filterItems: string[]
}

export function useSearch<T>(props: IUseSearchProps<T>) {
  const { searchApi, filterItems } = props;
  let location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [callApiController] = useState<AbortController>(new AbortController());
  const q = searchParams.get("search");

  const filterItemQueries = useMemo(() => {
    const result: Record<string, string> = {};
    filterItems.forEach(fi => {
      const qi = searchParams.get(fi);
      result[fi] = qi || "all";
    })
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])
  
  const [searchQuery, setSearchQuery] = useState<string>(q || "");
  const [filters, setFilters] = useState<Record<string, string>>(filterItemQueries)
  const [searchResult, setSearchResult] = useState<T | []>([]);
  const [resultMeta, setResultMeta] = useState<IPaylodListMeta>({pageNumber:1,pageSize:DEFAULT_PAGE_SIZE,totalCount:0});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSearchQuery(q || "");
    setFilters(()=>filterItemQueries);
    callApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q,filterItemQueries]);

  const callApi = useCallback(async () => {
    let queryString =
      q !== searchQuery ? "" : `search=${searchQuery.trim().toLowerCase()}`;
    for (let p in filterItemQueries) {
      if (filterItemQueries[p] !== "all") {
        queryString += `&${p}=${filterItemQueries[p]}`
      }
    }
    try {
      setLoading(true);
      const result = await searchApi(callApiController.signal, queryString);
      setSearchResult(result.data.payload);
      if(result.data.meta){
        setResultMeta(result.data.meta);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q,filterItemQueries]);

  useEffect(() => {
    const searchQueryItems: Record<string, string> = {};
    
    if (searchQuery) {
      searchQueryItems["search"] = searchQuery;
    }
    for (let it in filters) {
      if (filters[it] !== "all") {
        searchQueryItems[it] = filters[it];
      }
    }
    if(filterItems.includes("pageNumber")){
      searchQueryItems["pageNumber"] = "1";
    }
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(searchQueryItems as URLSearchParamsInit)}`,
    },{replace:true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    const searchQueryItems: Record<string, string> = {};
    
    if (searchQuery) {
      searchQueryItems["search"] = searchQuery;
    }
    for (let it in filters) {
      if (filters[it] !== "all") {
        searchQueryItems[it] = filters[it];
      }
    }
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(searchQueryItems as URLSearchParamsInit)}`,
    },{replace:true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return {
    searchQuery,
    setSearchQuery,
    loading,
    searchResult,
    setSearchResult,
    callApiController,
    filters,
    setFilters,
    resultMeta
  };
}
