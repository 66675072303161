import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
    Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface IConfirmModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onConfirm:()=>void;
    message?:string;
}

const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
    const { isOpen, setIsOpen, onConfirm, message } = props;
    const {t} = useTranslation();
    const onModalClosed = () => {
        setIsOpen(false);
    }

    const onNoClicked = ()=>{
        setIsOpen(false);
    }

    const onYesClicked = ()=>{
        onConfirm()
    }

    return (<Modal isOpen={isOpen} onClose={onModalClosed} >
        <ModalOverlay />
        <ModalContent>
            <ModalBody>
                <Box pt={4}>
                    {message || t("messages.areYouSure")}
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme="teal" mr="2" onClick={onNoClicked}>{t("actions.no")}</Button>
                <Button colorScheme="orange" onClick={onYesClicked}>{t("actions.yes")}</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>);
}

export default ConfirmModal;