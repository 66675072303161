import React from "react";
import InfoField from "src/components/app/InfoField";
import InfoSection from "src/components/app/InfoSection";
import dateHelper from "src/utils/date-helper";
import { useSubscriptionDetails } from "../../SubscriptionDetailsContext";
import { Text } from "@chakra-ui/react";

const SubscriptionInfo: React.FC = () => {
  const { subscribeDetails, remainingTime } = useSubscriptionDetails();


  return (
    subscribeDetails ? (
      <InfoSection title="Subscription Info">
        <InfoField label="Code">{subscribeDetails.code}</InfoField>
        <InfoField label="Latest Status">{subscribeDetails.state ?? "---"}</InfoField>
        {subscribeDetails.subscribedAt && (
          <InfoField label="Subscription Date">
            {dateHelper.formatDate(subscribeDetails.subscribedAt)}
          </InfoField>
        )}
        {subscribeDetails.availableAt && (
          <InfoField label="Available Date">
            {dateHelper.formatDate(subscribeDetails.availableAt)}
          </InfoField>
        )}
        {subscribeDetails.activatedAt && (
          <InfoField label="Activation Date">
            {dateHelper.formatDate(subscribeDetails.activatedAt)}
          </InfoField>
        )}
        {subscribeDetails.finishedAt && (
          <InfoField label="Finalization Date">
            {dateHelper.formatDate(subscribeDetails.finishedAt)}
          </InfoField>
        )}
        {subscribeDetails.canceledAt && (
          <InfoField label="Cancellation Date">
            {dateHelper.formatDate(subscribeDetails.canceledAt)}
          </InfoField>
        )}
        {
          subscribeDetails.state === "ordered" && (<InfoField label="Remaining Time">
            <Text color={(remainingTime !== undefined && remainingTime <= 0) ? "red" : "green"}>
              {
                (remainingTime !== undefined && remainingTime <= 0) ? `0 Hours` : `${remainingTime} Hours`
              }
            </Text>
          </InfoField>)
        }
      </InfoSection>
    ) : null
  );
};

export default SubscriptionInfo;
