import { Avatar, Box, Center, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import ContentView from "src/components/app/ContentView";
import { useTranslation } from "react-i18next";
import FormRow from "src/components/app/FormRow";
import { useGlobal } from "src/global-context/GlobalContext";
import { NUTRITIONIST_SPECIALITIES } from "src/constants/notritionist-specialties";
import dateHelper from "src/utils/date-helper";

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useGlobal();

  return (
    <ContentView>
      <Box
        mb="6"
        pb="2"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Box>
          <Text textTransform={"capitalize"} as="h3" fontSize="3xl">
            {t("titles.userProfile")}
          </Text>
        </Box>
      </Box>
      <Flex>
        <Center>
          <Box mb="6">
            <Avatar name={`photo`} size="xl" src={user?.avatar} />
          </Box>
          <FormRow
            leftContent={
              <Box ml="3">
                <Text as="span">
                  {t("fields.email")}: {user?.email}
                </Text>
              </Box>
            }
          />
        </Center>
      </Flex>

      <FormRow
        bidirectional
        leftContent={
          <Text as="span">
            {t("fields.firstName")}: {user?.firstname_en}
          </Text>
        }
        rightContent={
          <Text as="span">
            {t("fields.firstName_ar")}: {user?.firstname_ar}
          </Text>
        }
      />
      <FormRow
        bidirectional
        leftContent={
          <Text as="span">
            {t("fields.lastName")}: {user?.lastname_en}
          </Text>
        }
        rightContent={
          <Text as="span">
            {t("fields.lastName_ar")}: {user?.lastname_ar}
          </Text>
        }
      />
      
      {
        user?.role === "nutritionist" && (
          <>
          <Divider mb="4" />
            <FormRow
              bidirectional
              leftContent={<Box display="flex" >
                <Text as="span" mr="2" fontWeight="bold">{t("fields.specialty")}:</Text>
                <Text as="span">{NUTRITIONIST_SPECIALITIES.english[user.specialty_en!]}</Text>
              </Box>}
              rightContent={<Box display="flex">
                <Text as="span" ml="2" fontWeight="bold">{t("fields.specialty_ar")}:</Text>
                <Text as="span">{NUTRITIONIST_SPECIALITIES.arabic[user.specialty_en!]}</Text>
              </Box>}
            />
            <FormRow
                        bidirectional
                        leftContent={<Box display="flex" >
                            <Text as="span" mr="2" fontWeight="bold">{t("fields.about")}:</Text>
                            <Text as="span">{user.aboutMe_en!}</Text>
                        </Box>}
                        rightContent={<Box display="flex">
                            <Text as="span" ml="2" fontWeight="bold">{t("fields.about_ar")}:</Text>
                            <Text as="span">{user.aboutMe_ar!}</Text>
                        </Box>}
                    />
                    
                    <FormRow
                        leftContent={<Box display="flex" >
                            <Text as="span" mr="2" fontWeight="bold">{t("fields.registrationData")}:</Text>
                            <Text as="span">{dateHelper.formatDate(user.createdAt!)}</Text>
                        </Box>}
                    />
          </>
        )
      }

    </ContentView>
  );
};

export default Profile;
