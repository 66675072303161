import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import PlanDays from "src/components/app/PlanDays";
import Answers from "src/components/app/SubscriptionDetails/components/Answers";
import PlanInfo from "./components/PlanInfo";
import SubscriptionInfo from "src/components/app/SubscriptionDetails/components/SubscriptionInfo";
import { useTitle } from "src/hooks/useTitle";
import { useTranslation } from "react-i18next";
import ContentView from "src/components/app/ContentView";
import { SubscriptionDetailsProvider, useSubscriptionDetails } from "./SubscriptionDetailsContext";
import Subscriber from "./components/Subscriber";

const SubscriptionDetailsContent = ()=>{
  const {loading,subscribeDetails,canEdit,updateSubscriptionDetails} = useSubscriptionDetails();



  return <ContentView>
  {loading && (
    <Center>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  )}
  {subscribeDetails && (
    <Box>
      <Box mb="6">
        <Subscriber />
        <SubscriptionInfo
        />
        <PlanInfo info={subscribeDetails.plan} />
        <Answers  />
      </Box>
      <Box>
        <Text fontSize="1.3rem" mb="4" as="h3">
          Days
        </Text>
        <PlanDays
          days={subscribeDetails.daysDetail}
          planOrSubscriptionId={subscribeDetails._id}
          isCustomPlanSubscription={subscribeDetails.plan.type_en === "customized"}
          isEditable={canEdit()}
          duration={subscribeDetails.plan.duration}
          onUpdate={updateSubscriptionDetails}
        />
      </Box>
    </Box>
  )}
</ContentView>
}

const SubscriptionDetails: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.planSubscriptions"));
  

  return <SubscriptionDetailsProvider>
    <SubscriptionDetailsContent />
  </SubscriptionDetailsProvider>
};

export default SubscriptionDetails;
