import dayjs from "dayjs";

const dateHelper = {
    formatDate(d: string) {
        if (!d) {
            return "---"
        }
        return dayjs(d).format("ddd YYYY-MM-DD HH:mm");
    },
    compareDateWithToday(start: string, unit: "hours" | "days") {
        const startDate = dayjs(start)
        const endDate = dayjs()
        return startDate.diff(endDate, unit);
    },
    getTwoDaysRemainingTime(start: string) {
        const today = dayjs()
        const endDate = dayjs(start).add(48,"hours");
        return endDate.diff(today, "hours");
    }
}

export default dateHelper;;