const ROUTE_CONSTANTS = {
  ROOT: {
    RELATIVE: "/",
    ABSOLUTE: "/",
  },
  ROOT_STAR: {
    RELATIVE: "/*",
    ABSOLUTE: "/*",
  },
  ABOUT_US: {
    RELATIVE: "about-us",
    ABSOLUTE: "/about-us",
  },
  DEEP_LINKS: {
    RELATIVE: "deep-links",
    ABSOLUTE: "/deep-links",
  },
  PRIVACY_POLICY: {
    RELATIVE: "privacy-policy",
    ABSOLUTE: "/privacy-policy",
  },
  TERMS_AND_CONDITIONS: {
    RELATIVE: "terms-and-conditions",
    ABSOLUTE: "/terms-and-conditions",
  },
  CHECKOUT_STATUS: {
    RELATIVE: "checkout-status",
    ABSOLUTE: "/checkout-status",
  },
  AUTH: {
    ROOT: {
      RELATIVE: "auth",
      ABSOLUTE: "/auth",
    },
    LOGIN: {
      RELATIVE: "login",
      ABSOLUTE: "/auth/login",
    },
    FORGOT_PASSWORD: {
      RELATIVE: "forgot-password",
      ABSOLUTE: "/auth/forgot-password",
    },
    RESET_PASSWORD: {
      RELATIVE: "reset-password",
      ABSOLUTE: "/auth/reset-password",
    },
  },
  DASHBOARD: {
    ROOT: {
      RELATIVE: "dashboard",
      ABSOLUTE: "/dashboard",
    },
    PROFILE: {
      RELATIVE: "profile",
      ABSOLUTE: "/dashboard/profile",
    },
    DOCTORS: {
      ROOT: {
        RELATIVE: "doctors",
        ABSOLUTE: "/dashboard/doctors",
      },
      MY_SUBSCRIPTIONS: {
        RELATIVE: "subscriptions",
        ABSOLUTE: "/dashboard/doctors/subscriptions",
        DETAILS: {
          RELATIVE: "subscriptions/:id",
          ABSOLUTE: "/dashboard/doctors/subscriptions/:id",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `subscriptions/${id}`,
              ABSOLUTE: `/dashboard/doctors/subscriptions/${id}`,
            };
          },
        },
      },
      PLANS: {
        ROOT: {
          RELATIVE: "plans",
          ABSOLUTE: "/dashboard/doctors/plans",
        },
        CREATE: {
          RELATIVE: "plans/new",
          ABSOLUTE: "/dashboard/doctors/plans/new",
        },
        EDIT: {
          RELATIVE: "plans/:id/edit",
          ABSOLUTE: "/dashboard/doctors/plans/:id/edit",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `plans/${id}/edit`,
              ABSOLUTE: `/dashboard/doctors/plans/${id}/edit`,
            };
          },
        },
        DETAILS: {
          RELATIVE: "plans/:id",
          ABSOLUTE: "/dashboard/doctors/plans/:id",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `plans/${id}`,
              ABSOLUTE: `/dashboard/doctors/plans/${id}`,
            };
          },
        },
      }
    },
    ADMIN: {
      ROOT: {
        RELATIVE: "admin",
        ABSOLUTE: "/dashboard/admin",
      },
      USERS: {
        RELATIVE: "users",
        ABSOLUTE: "/dashboard/admin/users",
        DETAILS: {
          RELATIVE: "users/:id",
          ABSOLUTE: "/dashboard/admin/users/:id",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `users/${id}`,
              ABSOLUTE: `/dashboard/admin/users/${id}`,
            };
          },
        },
      },
      DOCTORS: {
        ROOT: {
          RELATIVE: "nutritionists",
          ABSOLUTE: "/dashboard/admin/nutritionists",
        },
        CREATE: {
          RELATIVE: "nutritionists/new",
          ABSOLUTE: "/dashboard/admin/nutritionists/new",
        },
        EDIT: {
          RELATIVE: "nutritionists/:id/edit",
          ABSOLUTE: "/dashboard/admin/nutritionists/:id/edit",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `nutritionists/${id}/edit`,
              ABSOLUTE: `/dashboard/admin/nutritionists/${id}/edit`,
            };
          },
        },
        DETAILS: {
          RELATIVE: "nutritionists/:id",
          ABSOLUTE: "/dashboard/admin/nutritionists/:id",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `nutritionists/${id}`,
              ABSOLUTE: `/dashboard/admin/nutritionists/${id}`,
            };
          },
        },
      },
      ADMINISTRATORS: {
        ROOT: {
          RELATIVE: "administrators",
          ABSOLUTE: "/dashboard/admin/administrators",
        },
        CREATE: {
          RELATIVE: "administrators/new",
          ABSOLUTE: "/dashboard/admin/administrators/new",
        },
        EDIT: {
          RELATIVE: "administrators/:id/edit",
          ABSOLUTE: "/dashboard/admin/administrators/:id/edit",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `administrators/${id}/edit`,
              ABSOLUTE: `/dashboard/admin/administrators/${id}/edit`,
            };
          },
        },
      },
      PLANS: {
        ROOT: {
          RELATIVE: "plans",
          ABSOLUTE: "/dashboard/admin/plans",
        },
        CREATE: {
          RELATIVE: "plans/new",
          ABSOLUTE: "/dashboard/admin/plans/new",
        },
        EDIT: {
          RELATIVE: "plans/:id/edit",
          ABSOLUTE: "/dashboard/admin/plans/:id/edit",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `plans/${id}/edit`,
              ABSOLUTE: `/dashboard/admin/plans/${id}/edit`,
            };
          },
        },
        DETAILS: {
          RELATIVE: "plans/:id",
          ABSOLUTE: "/dashboard/admin/plans/:id",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `plans/${id}`,
              ABSOLUTE: `/dashboard/admin/plans/${id}`,
            };
          },
        },
      },
      SUBSCRIPTIONS: {
        ROOT: {
          RELATIVE: "subscriptions",
          ABSOLUTE: "/dashboard/admin/subscriptions",
        },
        DETAILS: {
          RELATIVE: "subscriptions/:id",
          ABSOLUTE: "/dashboard/admin/subscriptions/:id",
          BY_DATA: (id: string) => {
            return {
              RELATIVE: `subscriptions/${id}`,
              ABSOLUTE: `/dashboard/admin/subscriptions/${id}`,
            };
          },
        },
      },
      MOBILE_HOME_PAGE: {
        ROOT: {
          RELATIVE: "mobile-home-page",
          ABSOLUTE: "/dashboard/admin/mobile-home-page",
        },
      }
    },
  },
};

export default ROUTE_CONSTANTS;
