import { PlanStatus } from "src/api/types/plan-status";

export const API_ROUTES = {
  BASE_URL: "https://jsonplaceholder.typicode.com",
  AUTH: {
    LOGIN: "auth/panel/login",
    FORGOT_PASSWORD: "user/forget-password",
    RESET_PASSWORD: "user/reset-password",
  },
  USER: {
    PROFILE: "",
  },
  DOCTOR: {
    PLANS: {
      ROOT: "/panel/nutritionist/plans",
      GET_ITEM: (id: string) => `/panel/nutritionist/plans/${id}`,
      ADD_DAY: (planId: string) => `/panel/nutritionist/plans/${planId}/days`,
      EDIT_DAY: (planId: string, dayNo: number) =>
        `/panel/nutritionist/plans/${planId}/days/${dayNo}`,
      SUBMIT: (planId: string) =>
        `panel/nutritionist/plans/${planId}/status/submit`,
    },
    SUBSCRIPTIONS: {
      ROOT: "panel/nutritionist/subscribe-plan",
      GET_ITEM: (id: string) => `panel/nutritionist/subscribe-plan/${id}`,
      ADD_DAY: (subscriptionId: string) => `/panel/nutritionist/subscribe-plan/${subscriptionId}/days`,
      EDIT_DAY: (subscriptionId: string, dayNo: number) =>
        `/panel/nutritionist/subscribe-plan/${subscriptionId}/days/${dayNo}`,
      SET_AVAILABLE: (subscriptionId: string) => `panel/nutritionist/subscribe-plan/${subscriptionId}/status/approve`,
    },
  },
  ATTACHMENT: {
    UPLOAD: "attachment",
  },
  PAYMENT: {
    GET_INFO: "payments/info",
  },
  PANEL: {
    CURRENT_USER: {
      ROOT: "panel/user",
    },
    ADMINS: {
      ROOT: "panel/admins",
      GET_ITEM: (id: string) => `panel/admins/${id}`,
      CHANGE_ACTIVATION: (adminId: string) =>
        `panel/nutritionists/${adminId}/activation`,
    },
    USERS: {
      ROOT: "panel/users",
      CHANGE_ACTIVATION: (userId: string) => `panel/users/${userId}/activation`,
      GET_ITEM: (id: string) => `panel/users/${id}`,
    },
    NUTRITIONISTS: {
      ROOT: "panel/nutritionists",
      GET_ITEM: (id: string) => `panel/nutritionists/${id}`,
      SEARCH_PARAM: (param: string) => `panel/nutritionists/search/${param}`,
      CHANGE_ACTIVATION: (nutritionistId: string) =>
        `panel/nutritionists/${nutritionistId}/activation`,
    },
    PLANS: {
      ROOT: "panel/plans",
      ADD_DAY: (planId: string) => `panel/plans/${planId}/days`,
      EDIT_DAY: (planId: string, dayNo: number) =>
        `panel/plans/${planId}/days/${dayNo}`,
      GET_ITEM: (id: string) => `panel/plans/${id}`,
      CHANGE_STATUS: (planId: string, status: PlanStatus) =>
        `panel/plans/${planId}/status/${status}`,
      CHANGE_ACTIVATION: (planId: string) => `panel/plans/${planId}/activation`,
    },
    SUBSCRIPTIONS: {
      ROOT: "panel/subscribe-plan",
      GET_ITEM: (id: string) => `panel/subscribe-plan/${id}`,
      ADD_DAY: (subscriptionId: string) => `panel/subscribe-plan/${subscriptionId}/days`,
      EDIT_DAY: (subscriptionId: string, dayNo: number) =>
        `panel/subscribe-plan/${subscriptionId}/days/${dayNo}`,
      SET_AVAILABLE: (subscriptionId: string) => `panel/subscribe-plan/${subscriptionId}/status/approve`,
    },
    MOBILE_HOME_PAGE_SECTIONS: {
      ROOT: "panel/home-page-sections",
      GET_ITEM: (id: string) => `panel/home-page-sections/${id}`,
      ADD_ITEMS: (id: string) => `panel/home-page-sections/${id}/items`,
      CHANGE_ACTIVATION: (id: string) =>
        `panel/home-page-sections/${id}/activation`,
    },
    FOODS: {
      ROOT: "panel/foods",
    },
  },
};
