import { API_ROUTES } from "src/constants/api-routes";
import { INewAdministratorForm, IUpdateAdminData } from "src/pages/dashboard/admin/Administrators/types";
import { AxiosInstance } from "../index";
import {
  IAdminAdministratorListPayload,
  ICreateAdministratorPayload,
} from "../types/admin-admin";
import { IAxiosResponse } from "../types/axios-response";

export const adminAdministratorsService = {
  create(data: INewAdministratorForm, abortSignal: AbortSignal) {
    return AxiosInstance.post<IAxiosResponse<ICreateAdministratorPayload>>(
      API_ROUTES.PANEL.ADMINS.ROOT,
      data,
      {
        signal: abortSignal,
      }
    );
  },
  update(data: IUpdateAdminData, id: string) {
    return AxiosInstance.put<IAxiosResponse<ICreateAdministratorPayload>>(
      API_ROUTES.PANEL.ADMINS.GET_ITEM(id),
      data
    );
  },
  getAll(abortSignal: AbortSignal, searchQuery?: string) {
    return AxiosInstance.get<IAxiosResponse<IAdminAdministratorListPayload[]>>(
      `${API_ROUTES.PANEL.ADMINS.ROOT}${
        searchQuery && `?${searchQuery}`
      }`,
      {
        signal: abortSignal,
      }
    );
  },
  getById(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<ICreateAdministratorPayload>>(
      API_ROUTES.PANEL.ADMINS.GET_ITEM(id),
      {
        signal: abortSignal,
      }
    );
  },
  changeActivation(adminId:string,activation:boolean){
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.PANEL.ADMINS.CHANGE_ACTIVATION(adminId),
      {
        activation
      }
    );
  }
};
