import React from "react";
import { Avatar, Box, Text } from "@chakra-ui/react";
import WeightLogs from "./components/WeightLogs";
import { IEndUserDetail } from "src/api/types/admin-user";

interface IUserInformationProps {
  info: IEndUserDetail;
}

const UserInformation: React.FC<IUserInformationProps> = ({ info }) => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        border="1px"
        borderColor="gray.200"
        p="3"
        mb="4"
        boxShadow="md"
        borderRadius={"md"}
      >
        <Avatar size="xl" name={info.firstname_en} src={info.avatar} />
        <Box
          color="gray.500"
          letterSpacing="wide"
          fontSize="xs"
          textTransform="capitalize"
          ml="4"
        >
          <Text as="div" fontSize="1rem" mb="2">
            Name: {info.firstname_en ?? "----"} {info.lastname_en ?? "----"}
          </Text>
          <Text as="div" fontSize="1rem" mb="2">
            Email: {info.email}
          </Text>
          <Text as="div" fontSize="1rem" mb="2">
            Phone: {info.phone ?? "----"}
          </Text>
        </Box>
      </Box>
      <WeightLogs info={info.weightTrack!} />
    </Box>
  );
};

export default UserInformation;
