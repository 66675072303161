import axios from 'axios';
import { ACCESS_TOKEN } from 'src/constants/access-token';
import { HTTP_STATUS_CODES } from 'src/constants/http-status-codes';
import { getAuthorization } from 'src/utils/get-authorization';
import { toast } from 'src/utils/toast';

import { cacheService } from './cache-service';

export const responseInterceptors = {
  successInterceptor(response: any) {
    if (response.config.customCache) {
      cacheService.setItem(response.config.cacheKey, response);
    }
    return response;
  },
  errorInterceptor(error: any) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    const statusCode = error?.response?.status;
    if (statusCode) {
      switch (statusCode) {
        case HTTP_STATUS_CODES.CACHED_RESPONSE:
          return Promise.resolve(error.response.data);
        case HTTP_STATUS_CODES.VALIDATION_ERROR:
          toast.error(error.response?.data?.message);
          break;
        case HTTP_STATUS_CODES.SERVER_ERROR:
          toast.error(error.response?.data?.message);
          break;
        case HTTP_STATUS_CODES.UN_AUTHORIZED:
          toast.error(error.response?.data?.message);
          localStorage.removeItem(ACCESS_TOKEN);
          window.location.reload();
          break;
        default:
          return Promise.reject(error);
      }
      // return error.response;
    }
    return Promise.reject(error);
  },
};

export const requestInterceptors = {
  beforeSent(config: any) {
    if (config.url.includes('panel/')) {
      config.headers = {
        ...config.headers,
        Authorization: getAuthorization(),
      };
    }
    // else {
      //   const whiteListForBasicsUrls = [
      //     '',
      //     '',
      //   ];

      //   if (whiteListForBasicsUrls.includes(config.url)) {
      //     //set authorization token
      //     config.headers = {
      //       ...config.headers,
      //       Authorization: getAuthorization(),
      //     };
      //   }
    // }
    return cacheService.handleCached(config);
  },
  onError(error: any) {
    return Promise.reject(error);
  },
};