import { Box } from "@chakra-ui/react";
import React from "react";
import { useDashboardLayout } from "../../DashboardLayoutContext";
import clsx from "clsx";

const Content:React.FC = ({children})=>{
    const {drawerIsOpen} = useDashboardLayout();
    return (
        <Box className={clsx("content",!drawerIsOpen && "expand")}>
            {
                children
            }
        </Box>
    );
}

export default Content;