import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ICheckoutContainerProps {
    topChild: ReactNode;
    bottomChild: ReactNode;
}

const CheckoutContainer: React.FC<ICheckoutContainerProps> = ({ topChild, bottomChild }) => {
    return <Box height="100vh" padding="30px 20px" display="flex" flexDir="column" justifyContent="center" alignItems="center">
        <>{topChild}</>
        <Box alignSelf="stretch" marginTop="auto">{bottomChild}</Box>
    </Box>;
}

export default CheckoutContainer;