import React from "react";
import "./styles.css";

interface IStaticPageGeneralContent{
    content:string;
    rtl:boolean;
}

const StaticPageGeneralContent:React.FC<IStaticPageGeneralContent> = ({content,rtl=false})=>{
    return <div className="general-content" style={{direction:rtl?"rtl":"ltr"}}>
        <div dangerouslySetInnerHTML={{__html:content}} />
    </div>
}

export default StaticPageGeneralContent;