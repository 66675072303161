import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Text, Textarea, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ContentView from "src/components/app/ContentView";
import FormRow from "src/components/app/FormRow";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { INewNutritionistForm } from "../types.d";
import { newNutritionistValidationSchema } from "src/validation-schemas/nutritionist";
import { useTitle } from "src/hooks/useTitle";
import { useTranslation } from "react-i18next";
import { adminNutritionistsService } from "src/api/services/admin-nutritionists"
import { ICreateNutritionistBody } from "src/api/types/admin-nutritionist";
import { NUTRITIONIST_SPECIALITIES } from "src/constants/notritionist-specialties";
import ImageUpload from "src/components/app/ImageUpload";
import { HTTP_STATUS_CODES } from "src/constants/http-status-codes";

const NewNutritionist: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.newNutritionist"));
    const toast = useToast();
    const params = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [specialty, setspecialty] = useState<string>("");
    const [getProfileController] = useState<AbortController>(new AbortController());
    const [defaultAvatar, setDefautAvatar] = useState<string>("");
    const [editMode,setEditMode] = useState<boolean>(false);

    const { register, formState: { errors }, handleSubmit, watch, setValue, reset } = useForm<INewNutritionistForm>({
        resolver: yupResolver(newNutritionistValidationSchema(t)),
        mode: "all",
        defaultValues: {
            email: "",
            firstname_en: "",
            firstname_ar: "",
            lastname_en: "",
            lastname_ar: "",
            avatar: "",
            specialty_en: "",
            aboutMe_en: "",
            aboutMe_ar: "",
        }
    });

    const getNutritionistDetails = useCallback(async () => {
        if (params.id) {
            try {
                setLoading(true);
                const result = await adminNutritionistsService.getNutritionistProfile(params.id, getProfileController.signal);
                if (result.status === HTTP_STATUS_CODES.SUCCESS) {
                    const data = result.data.payload;
                    reset({
                        email: data.email,
                        firstname_en: data.firstname_en,
                        firstname_ar: data.firstname_ar,
                        lastname_en: data.lastname_en,
                        lastname_ar: data.lastname_ar,
                        avatar: data.avatar,
                        specialty_en: data.specialty_en,
                        aboutMe_en: data.aboutMe_en,
                        aboutMe_ar: data.aboutMe_ar
                    });
                    setDefautAvatar(data.avatar);
                    setEditMode(true);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, reset])

    useEffect(() => {
        if (params.id) {
            getNutritionistDetails()
        }
    }, [getNutritionistDetails, params])

    useEffect(() => {
        return () => {
            getProfileController.abort();
        }
    }, [getProfileController]);



    const onSubmit = async (data: INewNutritionistForm) => {
        try {
            setLoading(true);
            if (params.id) {
                const { avatar, ...fields } = data;
                const finalData: any = { ...fields };
                if (data.avatar && !data.avatar.includes("http")) {
                    finalData.avatar = data.avatar;
                }
                await adminNutritionistsService.update(finalData as ICreateNutritionistBody, params.id);
                toast({
                    title: t("messages.nutritionistUpdated"),
                    status: 'success',
                    duration: 5000
                })
            } else {
                await adminNutritionistsService.create(data as ICreateNutritionistBody);
                toast({
                    title: t("messages.nutritionistCreated"),
                    status: 'success',
                    duration: 5000
                })
            }
            navigate(ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.ROOT.ABSOLUTE);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    watch(({ specialty_en }) => {
        if (specialty_en) {
            setspecialty(specialty_en);
        }
    })

    const onspecialtyChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setspecialty(event.target.value);
        setValue("specialty_en", event.target.value);
    }

    const onAvatarChanged = (newData: string) => {
        setValue("avatar", newData);
    }


    return (<ContentView>
        <Box mb="6" pb="2" display="flex" 
        justifyContent="space-between" 
        alignItems="center" borderBottom="1px solid" borderColor="gray.200">
            <Box>
                <Text as="h3" fontSize="3xl">{t("titles.newNutritionist")}</Text>
            </Box>
            <Box display="flex" alignItems="center">
                <Button
                                as={Link}
                                colorScheme="brand"
                                size="md"
                                type="submit"
                                to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.ROOT.ABSOLUTE} 
                            >
                                {t("actions.backToList")}
                            </Button>
            </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box mb="2">
            <Text as="span">Photo </Text>
            <Text as="span" color="red">*</Text>
            </Box>
            <Box>
                <ImageUpload onImageChanged={onAvatarChanged} upload withCrop aspectRatio={16 / 9}
                    src={defaultAvatar}
                    width={600} height={200} />
                    <Box mb={3} fontSize="0.875rem" mt={-4}>
                        <p>Image Types: *.jpg, *.jpeg, *.png</p>
                        <p>Maximum Size: 5 MB</p>
                    </Box>
                {
                    !!errors.avatar && <Box mb="4" color="red.500" fontSize="0.875rem">
                        {errors.avatar.message}
                    </Box>
                }
            </Box>
            <FormRow
                bidirectional
                leftContent={<FormControl isInvalid={!!errors.email} isRequired>
                    <FormLabel htmlFor="email">{t("fields.emailAddress")}</FormLabel>
                    <Input id="email" type="email" size="lg" {...register("email")} disabled={editMode}/>
                    <FormErrorMessage>
                        {!!errors.email && errors.email.message}
                    </FormErrorMessage>
                </FormControl>} />
            <FormRow
                bidirectional
                leftContent={<FormControl isInvalid={!!errors.firstname_en} isRequired>
                    <FormLabel htmlFor="firstName">{t("fields.firstName")}</FormLabel>
                    <Input id="firstName" type="text" size="lg" {...register("firstname_en")} />
                </FormControl>}
                rightContent={<FormControl isInvalid={!!errors.firstname_ar} isRequired>
                    <FormLabel htmlFor="firstName_ar">{t("fields.firstName_ar")}</FormLabel>
                    <Input id="firstName_ar" type="text" size="lg" {...register("firstname_ar")} />
                </FormControl>} />
            <FormRow
                bidirectional
                leftContent={<FormControl isInvalid={!!errors.lastname_en} isRequired>
                    <FormLabel htmlFor="lastName">{t("fields.lastName")}</FormLabel>
                    <Input id="lastName" type="text" size="lg" {...register("lastname_en")} />
                </FormControl>}
                rightContent={<FormControl isInvalid={!!errors.lastname_ar} isRequired>
                    <FormLabel htmlFor="lastName_ar">{t("fields.lastName_ar")}</FormLabel>
                    <Input id="lastName_ar" type="text" size="lg" {...register("lastname_ar")} />
                </FormControl>} />
            <FormRow
                bidirectional
                leftContent={<FormControl isInvalid={!!errors.specialty_en} isRequired>
                    <FormLabel htmlFor="specialty">{t("fields.specialty")}</FormLabel>
                    <Select id="specialty" size="lg"  {...register("specialty_en")}>
                        <option value='medical'>Medical</option>
                        <option value='certified'>Certified</option>
                    </Select>
                </FormControl>}
                rightContent={<FormControl sx={{
                    '.chakra-select__icon-wrapper': {
                        left: "0.5rem",
                        right: "auto"
                    }
                }}>
                    <FormLabel htmlFor="specialty_ar">{t("fields.specialty_ar")}</FormLabel>
                    <Select id="specialty" size="lg" value={specialty} onChange={onspecialtyChanged}>
                        <option value="" disabled style={{ display: "none" }}></option>
                        <option value='medical'>{NUTRITIONIST_SPECIALITIES.arabic.medical}</option>
                        <option value='certified'>{NUTRITIONIST_SPECIALITIES.arabic.certified}</option>
                    </Select>
                </FormControl>} />
            <FormRow
                bidirectional
                leftContent={<FormControl isInvalid={!!errors.aboutMe_en} isRequired>
                    <FormLabel htmlFor="aboutMe">{t("fields.aboutMe")}</FormLabel>
                    <Textarea id="aboutMe" size="lg" resize="none" {...register("aboutMe_en")} />
                </FormControl>}
                rightContent={<FormControl isInvalid={!!errors.aboutMe_ar} isRequired>
                    <FormLabel htmlFor="aboutMe_ar">{t("fields.aboutMe_ar")}</FormLabel>
                    <Textarea id="aboutMe_ar" size="lg" resize="none" {...register("aboutMe_ar")} />
                </FormControl>} />
            <Box textAlign="right">
                <Button as={Link} to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.ROOT.ABSOLUTE}
                    size="lg" colorScheme="orange" mr="4">{t("actions.cancel")}</Button>
                <Button colorScheme="brand" size="lg" type="submit" isLoading={loading}>{t("actions.save")}</Button>
            </Box>
        </form>

    </ContentView>)
}

export default NewNutritionist;