import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, NumberInput, NumberInputField,  RadioGroup, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addSectionValidationSchema } from 'src/validation-schemas/sections'
import { useTranslation } from 'react-i18next'
import { IAddSectionData } from '../../types'
import { useMobilePageContext } from '../../MobileHomePageContext'
import { toast } from 'src/utils/toast'

interface INewSectionModalProps {
    open: boolean;
    setOpen: (val: boolean) => void;
}


const NewSectionModal: React.FC<INewSectionModalProps> = (props) => {
    const { t } = useTranslation();
    const { addingSection, addSection, activeSection, sections, editSection } = useMobilePageContext();
    const [abortController] = useState<AbortController>(new AbortController());
    const [editMode,setEditMode] = useState<boolean>(false);
    const [bannerAlreadyExist,setBannerAlreadyExist] = useState<boolean>(false);

    const closeModal = () => {
        props.setOpen(false)
    }

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors }
    } = useForm<IAddSectionData>({
        resolver: yupResolver(addSectionValidationSchema(t)),
        mode: "all",
        defaultValues: {
            type: 'plan',
            title_ar: '',
            title_en: '',
            order: 1
        }
    });

    useEffect(()=>{
        const existingBanner = sections.find(si=>si.type === "banner");
        if(existingBanner){
            setBannerAlreadyExist(true);
        }
    },[sections]);

    useEffect(() => {
        if (activeSection) {
            setEditMode(true);
            reset({
                title_ar: activeSection.title_ar,
                title_en: activeSection.title_en,
                type: activeSection.type,
                order: sections.findIndex(ss => activeSection._id === ss._id) + 1
            })
        }
    }, [activeSection, sections,reset])

    const onFormSubmitted = (data: IAddSectionData) => {
        if(bannerAlreadyExist && data.order === 1){
            toast.error("Already Banner Collection Has order 1");
            return;
        }
        if (editMode) {
            editSection(activeSection?._id!, data);
        } else {
            addSection({...data,order:data.type === "banner"?1:data.order}, abortController.signal, () => {
                closeModal();
            });
        }

    }

    useEffect(() => {
        return () => {
            abortController.abort();
        }
    }, [abortController]);

    const onCancelClicked = ()=>{
        closeModal();
    }


    return <><Modal motionPreset="slideInBottom" onClose={closeModal} isOpen={props.open}>
        <ModalOverlay />
        <ModalContent pb={5}>
            <ModalHeader>{(editMode) ? "Edit" : "New"} Collection</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <form noValidate onSubmit={handleSubmit(onFormSubmitted)}>

                    <FormControl isInvalid={!!errors.type} isRequired mb="4">
                        <FormLabel textTransform={"capitalize"} htmlFor="type">
                            {t("fields.type")}
                        </FormLabel>
                        <RadioGroup textTransform={"capitalize"} id="type" >
                            <Stack direction="row">
                                <label>
                                    <input
                                        type="radio"
                                        value="plan"
                                        {...register("type")}
                                        disabled={!!activeSection}
                                        name="type"
                                        id="plan" style={{ marginRight: 8 }} />
                                    {t("titles.plan")}
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="nutritionist"
                                        {...register("type")}
                                        name="type"
                                        disabled={!!activeSection}
                                        id="nutritionist" style={{ marginRight: 8 }} />
                                    {t("titles.nutritionist")}
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="banner"
                                        {...register("type")}
                                        name="type"
                                        disabled={!!activeSection || bannerAlreadyExist}
                                        id="banner" style={{ marginRight: 8 }} />
                                    {t("titles.banner")}
                                </label>
                            </Stack>
                        </RadioGroup>
                        <FormErrorMessage textTransform={"capitalize"}>
                            {!!errors.type && errors.type.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.title_en} isRequired mb="4">
                        <FormLabel textTransform={"capitalize"} htmlFor="title_en">
                            {t("fields.englishTitle")}
                        </FormLabel>
                        <Input
                            id="title_en"
                            type="text"
                            size="lg"
                            {...register("title_en")}
                        />
                        <FormErrorMessage textTransform={"capitalize"}>
                            {!!errors.title_en && errors.title_en.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.title_ar} isRequired mb="4">
                        <FormLabel textTransform={"capitalize"} htmlFor="title_ar">
                            {t("fields.arabicTitle")}
                        </FormLabel>
                        <Input
                            id="title_ar"
                            type="text"
                            size="lg"
                            {...register("title_ar")}
                        />
                        <FormErrorMessage textTransform={"capitalize"}>
                            {!!errors.title_ar && errors.title_ar.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.order} isRequired>
                        <FormLabel
                            textTransform={"capitalize"}
                            htmlFor="order"
                        >
                            {t("fields.order")}
                        </FormLabel>
                        <NumberInput min={0} size="lg">
                            <NumberInputField
                                id="order"
                                {...register("order")}
                            />
                        </NumberInput>
                        <FormErrorMessage textTransform={"capitalize"}>
                            {!!errors.order && errors.order.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt="4">
                        <Button colorScheme="brand" size="lg" type="submit" isLoading={addingSection}>{editMode?"Edit":"Add"}</Button>
                        <Button colorScheme="orange" size="lg" type="button" onClick={onCancelClicked}>Cancel</Button>
                    </Box>
                </form>

            </ModalBody>
        </ModalContent>
    </Modal></>
}

export default NewSectionModal