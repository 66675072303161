import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import StaticPageGeneralContent from "src/components/app/StaticPageGeneralContent";
import { useTitle } from "src/hooks/useTitle";


const DeepLinks:React.FC = ()=>{
    
    return <Box p="8">
        <div> <a href="elevate://openMain/0">Open Home</a></div>
        <div> <a href="elevate://openMain/1">Open Nutritionists List</a></div>
        <div> <a href="elevate://openNutritionist/629caa5159cc01e7c967db96f">Open Nutritionist Detail</a></div>
        <div> <a href="elevate://openPlan/629bbd2459cc01e7c967d7b0">Open Plan Detail</a></div>
    </Box>
}

export default DeepLinks;
