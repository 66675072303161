import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
    Box,
    Input,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface IRejectPlanModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onConfirm:(message:string)=>void;
}


export const RejectPlanModal:React.FC<IRejectPlanModalProps> = (props)=>{
    const { isOpen, setIsOpen, onConfirm } = props;
    const {t} = useTranslation();

    const [rejectionMessage,setRejectionMessage] = useState<string>("");

    const onModalClosed = () => {
        setIsOpen(false);
    }

    const onNoClicked = ()=>{
        setIsOpen(false);
    }

    const onYesClicked = ()=>{
        onConfirm(rejectionMessage);
    }


    const onRejectionMessageChanged = (e:React.ChangeEvent<HTMLInputElement>)=>{
        setRejectionMessage(e.target.value)
    }

    return (<Modal isOpen={isOpen} onClose={onModalClosed} >
        <ModalOverlay />
        <ModalContent>
            <ModalBody>
                <Box pt={4} mb="3">
                    Reject plan, please enter rejection message and confirm.(At least 30 characters)
                </Box>
                <Box>
                    <Input id="rejectionMessage" type="text" value={rejectionMessage} onChange={onRejectionMessageChanged}/>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme="teal" mr="2" onClick={onNoClicked} >{t("actions.cancel")}</Button>
                <Button colorScheme="orange" onClick={onYesClicked} isDisabled={rejectionMessage.length < 30}>{t("actions.confirm")}</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>);
}


