import React from "react";
import { ChakraProvider } from '@chakra-ui/react'
import Routes from "./Routes";
import { theme } from "./theme";

const App: React.FC = () => {
    console.log(process.env)
  return (
    <ChakraProvider theme={theme}>
      <Routes />
    </ChakraProvider>
  );
};

export default App;