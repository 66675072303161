import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { Props } from "react-select";

interface ISelectOptionProps extends Props {
  error: any;
  name: string;
  options: { value: string | number; label: string | number }[];
  control: any;
  label?: string;
  required?: boolean;
  onCustomChange?: (e?: any) => void;
}

const SelectOption: React.FC<ISelectOptionProps> = (props) => {
  const customStyles = {
    control: (styles: any) => {
      return {
        ...styles,
        borderRadius: "0.375rem",
        border: props.error?.message
          ? "1px solid #E53E3E"
          : "1px solid #CBD5E0",
        color: "white",
        minHeight: "100%",
        outline: "none",
        boxShadow: props.error?.message ? "0 0 0 1px #e53e3e" : "none",
        height: "46px",
        ":active": {
          boxShadow: "0 0 0 1px #3182ce",
          border: "1px solid #3182ce",
        },
        ":focus": {
          boxShadow: "0 0 0 1px #3182ce",
          border: "1px solid #3182ce",
        },
      };
    },
  };

  return (
    <>
      <FormControl isInvalid={!!props.error} isRequired={props.required}>
        <FormLabel textTransform={"capitalize"} htmlFor={props.name}>
          {props.label}
        </FormLabel>
        <Controller
          control={props.control}
          render={({ field: { onChange, value, name } }) => {
            const currentSelection = props.options.find(
              (c: any) => c.value === value
            );
            const handleSelectChange = (selectedOption: any | null) => {
              onChange(selectedOption);
              if (props.onCustomChange) {
                props.onCustomChange(selectedOption);
              }
            };
            return (
              <Select
                {...props}
                value={currentSelection}
                isClearable
                name={name}
                options={props.options}
                onChange={handleSelectChange}
                styles={customStyles}
                defaultValue={props.defaultValue}
              />
            );
          }}
          name={props.name}
          rules={{
            required: props.required,
          }}
        />
        {props.error && (
          <FormErrorMessage textTransform={"capitalize"}>
            {props.error.message}
          </FormErrorMessage>
        )}
      </FormControl>
    </>
  );
};

export default SelectOption;
