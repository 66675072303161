import React from "react";
import { Center, Text } from "@chakra-ui/react";
import Icon from "@mdi/react"
import { mdiFlaskEmptyOffOutline } from '@mdi/js';

const EmptyResult:React.FC = ()=>{
    return (<Center padding="4" display="flex" flexDir="column" color="gray.400">
        <Icon path={mdiFlaskEmptyOffOutline} size="48px"/>
        <Text as="h3" fontSize="2xl" mt="4" color="gray.500">No results found!</Text>
    </Center>);
}

export default EmptyResult;