import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { IEndUserDetailSubscribePlans } from "src/api/types/admin-user";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import InfoField from "../InfoField";
import dateHelper from "src/utils/date-helper";

interface IEndUserSubscribedPlansProps {
  info: IEndUserDetailSubscribePlans;
}

const EndUserSubscribedPlans: React.FC<IEndUserSubscribedPlansProps> = ({
  info,
}) => {
  return (
    <Box>
      <Box mb="4" mt="4" borderWidth="2px" boxShadow="md" borderRadius={"md"}>
        <Box
          display="flex"
          alignItems="center"
          textTransform={"capitalize"}
          justifyContent="space-between"
          p="3"
          borderBottom="1px solid"
          borderColor="gray.300"
        >
          <Box display={"flex"} width="100%">
            Subscribe Code :
            <Text
              as="div"
              fontSize="1rem"
              mb="2"
              display={"flex"}
              color="blue.500"
            >
              <Link
                to={
                  ROUTE_CONSTANTS.DASHBOARD.ADMIN.SUBSCRIPTIONS.DETAILS.BY_DATA(
                    info._id
                  ).ABSOLUTE
                }
              >
                {info.code}
              </Link>
            </Text>
            <Text
              align="end"
              ml="auto"
              color={info.state === "activated" ? "green" : ""}
            >
              {info.state}
            </Text>
          </Box>
        </Box>
        <Box p="4">
          <Box display="flex">
            <Box>
              <InfoField label="Plan Title"> {info.plan?.title_en}</InfoField>
              <InfoField label="Plan Duration">{info.plan?.duration}</InfoField>
              <InfoField label="Subscribed date">
                {dateHelper.formatDate(info.subscribedAt)}
              </InfoField>
            </Box>
            <Box ml="4">
              <InfoField label="Nutritionist">
                {info.nutritionist?.firstname_en}{" "}
                {info.nutritionist?.lastname_en}
              </InfoField>

              <InfoField label="Plan Type">{info.plan?.type_en}</InfoField>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EndUserSubscribedPlans;
