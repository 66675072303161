import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import StaticPageGeneralContent from "src/components/app/StaticPageGeneralContent";
import { useTitle } from "src/hooks/useTitle";

const data = {
    en:{
        title:"Terms & Conditions",
        content:`
            <h2>Terms of Services</h2>
            <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat saepe asperiores possimus sapiente quibusdam, minus, maxime aperiam fugit voluptas beatae impedit dolor explicabo tempore mollitia suscipit eum alias, ipsam perferendis.    
            </p>
            <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat saepe asperiores possimus sapiente quibusdam, minus, maxime aperiam fugit voluptas beatae impedit dolor explicabo tempore mollitia suscipit eum alias, ipsam perferendis.    
            </p>
            <h2>General</h2>
            <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus cumque deleniti, praesentium assumenda quisquam, accusamus laborum, dignissimos laboriosam molestiae omnis suscipit! Eos consectetur quaerat molestias quod accusamus nulla praesentium distinctio.
            </p>
            <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus cumque deleniti, praesentium assumenda quisquam, accusamus laborum, dignissimos laboriosam molestiae omnis suscipit! Eos consectetur quaerat molestias quod accusamus nulla praesentium distinctio.
            </p>
        `
    },
    ar:{
        title:"البنود و الظروف",
        content:`
            <h2>جمع شروط الخدمة</h2>
            <p>
            لکن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.
            </p>
            <p>
            و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر بالسعادة التي لا تشوبها عواقب أليمة أو آخر أراد أن يتجنب الألم الذي ربما تنجم عنه بعض المتعة ؟ 
علي الجانب الآخر نشجب ونستنكر هؤلاء الرجال المفتونون بنشوة اللحظة الهائمون في رغباتهم فلا يدركون ما يعقبها من الألم والأسي المحتم، واللوم كذلك يشمل هؤلاء الذين أخفقوا في واجباتهم نتيجة لضعف إرادتهم فيتساوي مع هؤلاء الذين يتجنبون وينأون عن تحمل الكدح والألم
            </p>
            <h2>جنرال لواء</h2>
            <p>
            و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر بالسعادة التي لا تشوبها عواقب أليمة أو آخر أراد أن يتجنب الألم الذي ربما تنجم عنه بعض المتعة ؟ 
علي الجانب الآخر نشجب ونستنكر هؤلاء الرجال المفتونون بنشوة اللحظة الهائمون في رغباتهم فلا يدركون ما يعقبها من الألم والأسي المحتم، واللوم كذلك يشمل هؤلاء الذين أخفقوا في واجباتهم نتيجة لضعف إرادتهم فيتساوي مع هؤلاء الذين يتجنبون وينأون عن تحمل الكدح والألم . من المفترض أن نفرق بين هذه الحالات بكل سهولة ومرونة. في ذاك الوقت عندما تكون قدرتنا علي الاختيار غير مقيدة بشرط وعندما لا نجد ما يمنعنا أن نفعل الأفضل فها نحن نرحب بالسرور والسعادة ونتجنب كل ما يبعث إلينا الألم. في بعض الأحيان ونظراً للالتزامات التي يفرضها علينا الواجب والعمل سنتنازل غالباً ونرفض الشعور بالسرور ونقبل ما يجلبه إلينا الأسى. الإنسان الحكيم عليه أن يمسك زمام الأمور ويختار إما أن يرفض مصادر السعادة من أجل ما هو أكثر أهمية أو يتحمل الألم من أجل ألا يتحمل ما هو أسوأ.
            </p>
        `
    }
}

const TermsAndConditions:React.FC = ()=>{
    const [search] = useSearchParams();
    const [lang] = useState<"en" | "ar">(search.get("lang") === "ar"?"ar":"en");
    useTitle(data[lang].title);
    return <Box>
        <StaticPageGeneralContent content={data[lang].content} rtl={lang==="ar"}/>
    </Box>
}

export default TermsAndConditions;