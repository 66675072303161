import { string, object } from "yup";
export const newNutritionistValidationSchema = (t: any) => object().shape({
    email: string().required(t("validationMessages.fieldIsRequired", { field: t("fields.emailAddress") }))
        .email(t("validationMessages.fieldIsNotValid", { field: t("fields.emailAddress") })),
    firstname_en: string().required(),
    firstname_ar: string().required(),
    lastname_en: string().required(),
    lastname_ar: string().required(),
    avatar: string().required(t("validationMessages.fieldIsRequired", { field: t("fields.photo") })),
    specialty_en: string().required(),
    aboutMe_en: string().required(),
    aboutMe_ar: string().required()

})