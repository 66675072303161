import React, { useCallback, useEffect, useState } from "react";
import { adminMobileHomePageSections } from "src/api/services/admin-mobile-home-page-sections";
import { IMobileSection } from "src/api/types/admin-mobile-home-page-sections";
import { contextFactory } from "src/utils/context-factory";
import { toast } from "src/utils/toast";
import { IAddSectionData, IMobileHomePageContext } from "./types";

export const [MobileHomePageContext, useMobilePageContext] =
    contextFactory<IMobileHomePageContext>("MobileHomePageContext", "useMobilePageContext");


export const MobileHomePageProvider: React.FC = ({ children }) => {
    const [sections, setSections] = useState<IMobileSection[]>([]);
    const [addingSection, setAddingSection] = useState<boolean>(false);
    const [loadingSections, setLoadingSections] = useState<boolean>(false);
    const [fetchSectionsController] = useState<AbortController>(new AbortController());
    const [openAddChildModal, setOpenAddChildModal] = useState<boolean>(false);
    const [openAddSectionModal, setOpenAddSectionModal] = useState<boolean>(false);
    const [activeSection, setActiveSetion] = useState<IMobileSection | undefined>();

    const fetchSections = useCallback(async () => {
        try {
            setLoadingSections(true);
            const result = await adminMobileHomePageSections.getAll(fetchSectionsController.signal);
            setSections(result.data.payload);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingSections(false);
        }
    }, [fetchSectionsController.signal])

    useEffect(() => {
        fetchSections();
        return () => {
            fetchSectionsController.abort();
        }
    }, [fetchSectionsController, fetchSections]);


    const addSection = async (data: IAddSectionData, abortSignal: AbortSignal, cb: () => void) => {
        try {
            setAddingSection(true);
            const result = await adminMobileHomePageSections.create(data, abortSignal);
            toast.success("Collection created successfully");
            const tempSections = [...sections];
            const newSection: IMobileSection = {
                _id: result.data.payload._id,
                title_en: data.title_en,
                title_ar: data.title_ar,
                type: data.type === "plan" ? "plan" : "nutritionist",
                active: false,
                items: []
            };
            if (tempSections.length <= data.order) {
                tempSections.push(newSection)
            } else {
                tempSections.splice(data.order - 1, 0, newSection);
            }
            setSections(tempSections);
            cb();
        } catch (err) {
            console.log(err);
        } finally {
            setAddingSection(false);
        }
    };
    const editSection = async (sectionId: string, data: IAddSectionData) => {
        try {
            setAddingSection(true)
            await adminMobileHomePageSections.editSection(sectionId, data);
            const targetSectionIndex = sections.findIndex(si => si._id === activeSection?._id);
            let tempSections = [...sections];
            tempSections[targetSectionIndex].title_ar = data.title_ar;
            tempSections[targetSectionIndex].title_en = data.title_en;
            setOpenAddSectionModal(false);
            setActiveSetion(undefined);
            //-----------------------------------------
            const currentIndex = targetSectionIndex;
            const nextIndex = data.order > 0 ? (data.order > sections.length ? sections.length - 1 : data.order - 1) : 0;
            if (currentIndex !== nextIndex) {
                const reordered = [...sections];
                const [removed] = reordered.splice(currentIndex, 1);
                reordered.splice(nextIndex, 0, removed);
                tempSections = [...reordered];
            }

            setSections(tempSections);

        } catch (error) {
            console.log(error)

        } finally {
            setAddingSection(false)
        }
    };
    const deleteSection = (id: string) => {
        adminMobileHomePageSections.deleteSection(id);
        toast.success("Collection Deleted Successfully");
        const targetIndex = sections.findIndex(si => si._id === id);
        const tempSections = [...sections];
        tempSections.splice(targetIndex, 1);
        setSections(tempSections);
    };

    const addChildToSection = async (items: string[]) => {
        try {
            setAddingSection(true)
            const result = await adminMobileHomePageSections.addItemsToSection(activeSection?._id!, items)
            const targetSectionIndex = sections.findIndex(si => si._id === activeSection?._id);
            const tempSections = [...sections];
            tempSections[targetSectionIndex].items = result.data.payload.items;
            setOpenAddChildModal(false);
            setActiveSetion(undefined);

        } catch (error) {
            console.log(error)

        } finally {
            setAddingSection(false)
        }
    };

    const changeSectionActivation = async (sender: string, cb: () => void) => {
        const tempSections = [...sections];
        const targetIndex = tempSections.findIndex(ts => ts._id === sender);
        await adminMobileHomePageSections.changeActivation(sender, !tempSections[targetIndex].active);
        tempSections[targetIndex].active = !tempSections[targetIndex].active;
        setSections(tempSections);
        cb();
    }



    return (<MobileHomePageContext.Provider value={{
        sections,
        setSections,
        addingSection,
        loadingSections,
        addSection,
        editSection,
        deleteSection,
        addChildToSection,
        openAddChildModal,
        setOpenAddChildModal,
        activeSection,
        setActiveSetion,
        openAddSectionModal,
        setOpenAddSectionModal,
        changeSectionActivation
    }}>{children}</MobileHomePageContext.Provider>)
}