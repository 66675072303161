import React from "react";
import {
  BrowserRouter as Router,
  Routes as RoutesWrapper,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "src/pages/Home";
import NotFound from "src/pages/NotFound";
import ROUTE_CONSTANTS from "./route-constants";
import Login from "src/pages/auth/Login";
import ForgotPassword from "src/pages/auth/ForgotPassword";
import ResetPassword from "src/pages/auth/ResetPassword";
import GeneralLayout from "src/layouts/GeneralLayout";
import MainLayout from "src/layouts/DashboardLayout";
import DashboardHome from "src/pages/dashboard/home";
import Profile from "src/pages/dashboard/Profile";
import NutritionistsLayout from "src/layouts/NutritionistsLayout";
import MySubscriptions from "src/pages/dashboard/doctors/MySubscriptions";
import AdminLayout from "src/layouts/AdminLayout";
import Plans from "src/pages/dashboard/admin/Plans";
import Users from "src/pages/dashboard/admin/Users";
import Subscriptions from "src/pages/dashboard/admin/Subscriptions";
import SubscriptionDetails from "src/pages/dashboard/admin/Subscriptions/SubscriptionDetails";
import Nutritionists from "src/pages/dashboard/admin/Nutritionists";
import NewNutritionist from "src/pages/dashboard/admin/Nutritionists/NewNutritionist";
import Administrators from "src/pages/dashboard/admin/Administrators";
import NutritionistDetails from "src/pages/dashboard/admin/Nutritionists/NutritionistDetails";
import { GlobalProvider } from "src/global-context/GlobalContext";
import NewAdministrator from "src/pages/dashboard/admin/Administrators/NewAdministrator";
import NewPlan from "src/pages/dashboard/admin/Plans/NewPlan";
import PlanDetail from "src/pages/dashboard/admin/Plans/PlanDetail";
import NutritionistPlans from "src/pages/dashboard/doctors/Plans";
import NutritionistNewPlan from "src/pages/dashboard/doctors/Plans/NewPlan";
import NutritionistPlanDetail from "src/pages/dashboard/doctors/Plans/PlanDetail";
import UserDetails from "src/pages/dashboard/admin/Users/UserDetails";
import TermsAndConditions from "src/pages/Home/TermsAndConditions";
import PrivacyPolicy from "src/pages/Home/PrivacyPolicy";
import AboutUs from "src/pages/Home/AboutUs";
import DeepLinks from "src/pages/Home/DeepLinks";
import NutritionistSubscriptionDetails from "src/pages/dashboard/doctors/MySubscriptions/SubscriptionDetails";
import MobileHomePage from "src/pages/dashboard/admin/MobileHomePage";
import CheckoutStatus from "src/pages/Home/CheckoutStatus";

const Routes: React.FC = () => {
  return (
    <Router basename={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
      <GlobalProvider>
        <RoutesWrapper>
          <Route path={ROUTE_CONSTANTS.ROOT.ABSOLUTE} element={<Home />} />
          <Route path={ROUTE_CONSTANTS.ABOUT_US.ABSOLUTE} element={<AboutUs />} />
          <Route path={ROUTE_CONSTANTS.DEEP_LINKS.ABSOLUTE} element={<DeepLinks />} />
          <Route path={ROUTE_CONSTANTS.PRIVACY_POLICY.ABSOLUTE} element={<PrivacyPolicy />} />
          <Route path={ROUTE_CONSTANTS.TERMS_AND_CONDITIONS.ABSOLUTE} element={<TermsAndConditions />} />
          <Route path={ROUTE_CONSTANTS.CHECKOUT_STATUS.ABSOLUTE} element={<CheckoutStatus />} />
          <Route
            path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE}
            element={<GeneralLayout />}
          >
            <Route
              path=""
              element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} />}
            />
            <Route
              path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE}
              element={<Login />}
            />
            <Route
              path={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.RELATIVE}
              element={<ForgotPassword />}
            />
            <Route
              path={ROUTE_CONSTANTS.AUTH.RESET_PASSWORD.RELATIVE}
              element={<ResetPassword />}
            />
          </Route>
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE}
            element={<MainLayout />}
          >
            <Route path="" element={<DashboardHome />} />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.PROFILE.RELATIVE}
              element={<Profile />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.ROOT.RELATIVE}
              element={<AdminLayout />}
            >
              <Route
                path=""
                element={
                  <Navigate
                    to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.USERS.RELATIVE}
                  />
                }
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.USERS.RELATIVE}
                element={<Users />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.USERS.DETAILS.RELATIVE}
                element={<UserDetails />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.ROOT.RELATIVE}
                element={<Nutritionists />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.DETAILS.RELATIVE}
                element={<NutritionistDetails />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.CREATE.RELATIVE}
                element={<NewNutritionist />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.EDIT.RELATIVE}
                element={<NewNutritionist />}
              />
              <Route
                path={
                  ROUTE_CONSTANTS.DASHBOARD.ADMIN.ADMINISTRATORS.ROOT.RELATIVE
                }
                element={<Administrators />}
              />
              <Route
                path={
                  ROUTE_CONSTANTS.DASHBOARD.ADMIN.ADMINISTRATORS.CREATE.RELATIVE
                }
                element={<NewAdministrator />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.ROOT.RELATIVE}
                element={<Plans />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.CREATE.RELATIVE}
                element={<NewPlan />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.EDIT.RELATIVE}
                element={<NewPlan />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.DETAILS.RELATIVE}
                element={<PlanDetail />}
              />
              <Route
                path={
                  ROUTE_CONSTANTS.DASHBOARD.ADMIN.ADMINISTRATORS.EDIT.RELATIVE
                }
                element={<NewAdministrator />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.ROOT.RELATIVE}
                element={<Plans />}
              />
              <Route
                path={
                  ROUTE_CONSTANTS.DASHBOARD.ADMIN.SUBSCRIPTIONS.ROOT.RELATIVE
                }
                element={<Subscriptions />}
              />
              <Route
                path={
                  ROUTE_CONSTANTS.DASHBOARD.ADMIN.SUBSCRIPTIONS.DETAILS.RELATIVE
                }
                element={<SubscriptionDetails />}
              />
              <Route
                path={
                  ROUTE_CONSTANTS.DASHBOARD.ADMIN.MOBILE_HOME_PAGE.ROOT.RELATIVE
                }
                element={<MobileHomePage />}
              />
            </Route>
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.DOCTORS.ROOT.RELATIVE}
              element={<NutritionistsLayout />}
            >
              <Route
                path=""
                element={
                  <Navigate
                    to={ROUTE_CONSTANTS.DASHBOARD.DOCTORS.PLANS.ROOT.RELATIVE}
                  />
                }
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.DOCTORS.PLANS.ROOT.RELATIVE}
                element={<NutritionistPlans />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.DOCTORS.PLANS.CREATE.RELATIVE}
                element={<NutritionistNewPlan />}
              />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.DOCTORS.PLANS.EDIT.RELATIVE}
                element={<NutritionistNewPlan />}
              />

              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.DOCTORS.PLANS.DETAILS.RELATIVE}
                element={<NutritionistPlanDetail />}
              />
              <Route
                path={
                  ROUTE_CONSTANTS.DASHBOARD.DOCTORS.MY_SUBSCRIPTIONS.RELATIVE
                }
                element={<MySubscriptions />}

              />
              <Route
                path={
                  ROUTE_CONSTANTS.DASHBOARD.DOCTORS.MY_SUBSCRIPTIONS.DETAILS.RELATIVE
                }
                element={<NutritionistSubscriptionDetails />}

              />
            </Route>
          </Route>
          <Route
            path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE}
            element={<NotFound />}
          />
        </RoutesWrapper>
      </GlobalProvider>
    </Router>
  );
};

export default Routes;
