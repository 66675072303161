import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import AddChildModal from "./components/AddChildModal";
import NewSectionModal from "./components/NewSectionModal";
import Section from "./components/Section";
import { MobileHomePageProvider, useMobilePageContext } from "./MobileHomePageContext";



const MobileHomePageContent = () => {
    const { sections, openAddChildModal, openAddSectionModal, setOpenAddSectionModal } = useMobilePageContext();

    const onNewSectionClicked = () => {
        setOpenAddSectionModal(true)
    }


    return <Box>
        <Box mb="4" display="flex" alignItems="center" justifyContent="space-between"
            borderBottom="1px solid #ccc" pb="3">
            <Text fontSize="1.6rem">Mobile Home Page Collections</Text>
            <Box>
                <Button onClick={onNewSectionClicked}>New Collection</Button>
            </Box>
        </Box>
        {
            sections.map(s => (<Section data={s} key={s._id} />))
        }
        {
            openAddSectionModal && <NewSectionModal open={openAddSectionModal} setOpen={setOpenAddSectionModal} />
        }
        {
            openAddChildModal && <AddChildModal />
        }
    </Box>
}


const MobileHomePage: React.FC = () => {

    return (<MobileHomePageProvider>
        <MobileHomePageContent />
    </MobileHomePageProvider>)
}

export default MobileHomePage;