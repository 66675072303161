import React, { useCallback, useEffect, useState } from "react";

import { contextFactory } from "src/utils/context-factory";
import { ISubscriptionDetailPayload } from "src/api/types/admin-subscriptions";
import { adminSubscriptionService } from "src/api/services/admin-subscriptions";
import { useParams } from "react-router-dom";
import { ISubscriptionDetailsContext } from "./types";
import { useGlobal } from "src/global-context/GlobalContext";
import { nutritionistSubscriptionService } from "src/api/services/nutritionist.subscription";
import dateHelper from "src/utils/date-helper";
import { IPlanDay } from "src/api/types/plan-days";

export const [SubscriptionDetailsContext, useSubscriptionDetails] = contextFactory<ISubscriptionDetailsContext>("SubscriptionDetailsContext", "useSubscriptionDetails");

export const SubscriptionDetailsProvider: React.FC = ({ children }) => {
  const params = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useGlobal();
  const [getSubscribeController] = useState<AbortController>(
    new AbortController()
  );
  const [subscribeDetails, setSubscribeDetails] = useState<ISubscriptionDetailPayload>();
  const [remainingTime, setRemainigTime] = useState<number>(0);

  useEffect(() => {
    if (subscribeDetails && subscribeDetails.state === "ordered") {
      setRemainigTime(dateHelper.getTwoDaysRemainingTime(subscribeDetails.subscribedAt));
    }
  }, [subscribeDetails]);

  const getDetail = useCallback(async () => {
    try {
      setLoading(true);
      if (params.id) {
        if (user) {
          if (user.role === "admin") {
            const result = await adminSubscriptionService.getDetail(
              params.id,
              getSubscribeController.signal
            );
            setSubscribeDetails(result.data.payload);
          } else {
            const nutritionistResult = await nutritionistSubscriptionService.getDetail(
              params.id,
              getSubscribeController.signal
            );
            setSubscribeDetails(nutritionistResult.data.payload);
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [params, getSubscribeController.signal, user]);

  useEffect(() => {
    getDetail();
    return () => {
      getSubscribeController.abort();
    };
  }, [getDetail, getSubscribeController]);

  const canEdit = () => {
    if (!subscribeDetails) {
      return false;
    }

    if (subscribeDetails.plan.type_en === "customized" && subscribeDetails.state === "ordered") {
      return true;
    }
    return false;
  }


  const setAvailable = async (cb: () => void) => {
    try {
      if (user?.role === "admin") {
        await adminSubscriptionService.changeOrderedSubscriptionToAvailable(subscribeDetails?._id || "");
      } else {
        await nutritionistSubscriptionService.changeOrderedSubscriptionToAvailable(subscribeDetails?._id || "");
      }
      setSubscribeDetails({
        ...subscribeDetails!,
        state: "available"
      })
    } catch (err) {
      console.log(err);
    } finally {
      cb()
    }
  }

  const updateSubscriptionDetails = (data:IPlanDay[])=>{
    setSubscribeDetails({
      ...subscribeDetails!,
      daysDetail:data
    })
  }

  return <SubscriptionDetailsContext.Provider value={{
    subscribeDetails,
    loading,
    canEdit,
    remainingTime,
    setAvailable,
    updateSubscriptionDetails
  }}>
    {children}
  </SubscriptionDetailsContext.Provider>
}

