import { Avatar, Box,IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import {mdiMenu} from "@mdi/js";
import Icon from "@mdi/react";
import LogoText from "src/components/app/LogoText";
import { useDashboardLayout } from "../../DashboardLayoutContext";
import { useGlobal } from "src/global-context/GlobalContext";
import { ACCESS_TOKEN } from "src/constants/access-token";
import { useNavigate , Link} from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";

const Navbar:React.FC = ()=>{
    const {setDrawerIsOpen} = useDashboardLayout();
    const {user,setUser} = useGlobal();
    const navigate = useNavigate();

    const onMenuHandlerClicked = ()=>{
        setDrawerIsOpen((value)=>!value)
    }

    const onSignOutClicked =()=>{
        setUser(undefined);
        localStorage.removeItem(ACCESS_TOKEN);
        navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
    }

    return (
        <Box className="navbar">
            <Box className="brand">
                <IconButton aria-label="Test" icon={<Icon path={mdiMenu} size="28px"/>} 
                    color="gray.700" 
                    bg="transparent"
                    _hover={{bg:"transparent"}}
                    onClick={onMenuHandlerClicked}/>
                <LogoText />
            </Box>
            <Box>
                <Menu>
                    <MenuButton>
                        <Avatar name={`${user?.firstname_en} ${user?.lastname_en}`} src={user?.avatar}/>
                    </MenuButton>
                    <MenuList>
                        <Box p={2} display="flex" alignItems="center" justifyContent="center" flexDir="column">
                            <Avatar name={`${user?.firstname_en} ${user?.lastname_en}`} mb={2} src={user?.avatar}/>
                            <Box>{`${user?.firstname_en} ${user?.lastname_en}`}</Box>
                        </Box>
                        <MenuItem as={Link} to={ROUTE_CONSTANTS.DASHBOARD.PROFILE.ABSOLUTE}>Profile</MenuItem>
                        <MenuItem onClick={onSignOutClicked}>Sign out</MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        </Box>
    );
}

export default Navbar;