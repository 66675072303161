import { string, object } from "yup";
export const newAdministratorValidationSchema = (t: any) =>
  object().shape({
    email: string()
      .required(
        t("validationMessages.fieldIsRequired", {
          field: t("fields.emailAddress"),
        })
      )
      .email(
        t("validationMessages.fieldIsNotValid", {
          field: t("fields.emailAddress"),
        })
      ),
    firstname_en: string().required(
      t("validationMessages.fieldIsRequired", { field: t("fields.firstName") })
    ),
    firstname_ar: string().required(
      t("validationMessages.fieldIsRequired", {
        field: t("fields.firstName_ar"),
      })
    ),
    lastname_en: string().required(
      t("validationMessages.fieldIsRequired", { field: t("fields.lastName") })
    ),
    lastname_ar: string().required(
      t("validationMessages.fieldIsRequired", {
        field: t("fields.lastName_ar"),
      })
    ),
    avatar: string().required(
      t("validationMessages.fieldIsRequired", {
        field: t("fields.photo"),
      })
    ),
  });
