import { Box,Image, Text } from "@chakra-ui/react";
import React from "react";
import { ISectionChildItem } from "src/api/types/admin-mobile-home-page-sections";

interface INutritionistCardProps {
    data: ISectionChildItem
}

const NutritionistCard: React.FC<INutritionistCardProps> = (props) => {
    const { data } = props;

    return <Box boxShadow="md" display="inline-block" width="200px" mr="4" borderRadius={"md"} textTransform={"capitalize"}
    opacity={data.active?"1":"0.5"}>
    <Box mb="3">
        <Image objectFit='cover' src={data.avatar} alt={`${data.firstname_en} ${data.lastname_en}`}
            fallbackSrc="https://www.qiteplanguage.org/assets/img/noimage2.png"
        />
    </Box>
    <Box fontSize='1rem' p="3">
        <Text>{`${data.firstname_en} ${data.lastname_en}`}</Text>
    </Box>
</Box>
}

export default NutritionistCard;