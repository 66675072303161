import { Box } from "@chakra-ui/react";
import React from "react";
import { INewDayMeal } from "src/api/types/plan-days";
import { DayMeal } from "./DayMeal";

interface IDayMealsProps{
    setActiveMealToAddFood:(value:INewDayMeal)=>void;
    meals:INewDayMeal[];

}

export const DayMeals:React.FC<IDayMealsProps> = (props)=>{
    const {meals,setActiveMealToAddFood} = props;
    return <Box>
        {
            meals.map(m=>(
                <DayMeal key={m.title} setActive={setActiveMealToAddFood} mealData={m}/>
            ))
        }
    </Box>
}