import App from "./App";
import ReactDOM from "react-dom";
import "./i18n";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (window.REACT_APP_API_URL) {
  Sentry.init({
    dsn: "https://fc657701fdc448e2bf0c4e3933bfdd2c@o469240.ingest.sentry.io/6327475",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}


ReactDOM.render(<App />, document.getElementById("root"));
