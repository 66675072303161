import { Box,Text } from "@chakra-ui/react";
import React from "react";

const Home:React.FC = ()=>{
    return (<Box>
        <Text as="h2" fontSize="2xl">Welcome to ELEVATE</Text>
    </Box>);
}

export default Home;

/*
<SimpleGrid columns={{base:1,sm:2,md:3,lg:4,xl:5}}>
        <Box>Item 1</Box>
        <Box>Item 2</Box>
        <Box>Item 3</Box>
        <Box>Item 4</Box>
        <Box>Item 5</Box>
        <Box>Item 6</Box>
        <Box>Item 7</Box>
    </SimpleGrid>
*/