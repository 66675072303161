import { Box } from "@chakra-ui/react";
import React from "react";
import clsx from "clsx";
import { useDashboardLayout } from "../../DashboardLayoutContext";
import Menu from "../Menu";
import { nutritionistMenuData,adminMenuData } from "./menuData";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useGlobal } from "src/global-context/GlobalContext";


const Drawer: React.FC = () => {
    const { drawerIsOpen, setDrawerIsOpen } = useDashboardLayout();
    const {user} = useGlobal();
    const onCloseClicked = () => {
        setDrawerIsOpen(false);
    }

    return (
        <>
            <Box className={clsx("d-back-drop",drawerIsOpen && "show")} onClick={onCloseClicked}></Box>
            <Box className={clsx("drawer", drawerIsOpen && "expand")}>
                <span className="d-close-btn" onClick={onCloseClicked}>
                    <Icon path={mdiClose} size="24px" />
                </span>
                {
                    user?.role === "admin" && <Menu key={adminMenuData.title} title={adminMenuData.title} icon={adminMenuData.icon} 
                        navLinks={adminMenuData.navLinks} />
                }
                {
                    user?.role === "nutritionist" && <Menu key={nutritionistMenuData.title} title={nutritionistMenuData.title} 
                    icon={nutritionistMenuData.icon} navLinks={nutritionistMenuData.navLinks} />
                }
            </Box>
        </>
    );
}

export default Drawer;