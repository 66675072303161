
import { transform } from "src/utils/validation-number-transform";
import { string, object, number } from "yup";

export const addSectionValidationSchema = (t: any) => object().shape({
    type: string().required(t("validationMessages.fieldIsRequired",{field:t("fields.type")})),
    title_en: string().required(t("validationMessages.fieldIsRequired",{field:t("fields.englishTitle")})),
    title_ar: string().required(t("validationMessages.fieldIsRequired",{field:t("fields.arabicTitle")})),
    order:number()
    .typeError(
      t("validationMessages.amountMostBeNumber", {
        field: t("fields.order"),
      })
    )
    .required(
      t("validationMessages.fieldIsRequired", {
        field: t("fields.order"),
      })
    )
    .nullable()
    .transform((_,v) => transform(v)),
})