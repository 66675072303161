import {
    Box,
    Button,
    Center,
    Divider,
    IconButton,
    Image,
    Spinner,
    Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentView from "src/components/app/ContentView";
import FormRow from "src/components/app/FormRow";
import { useTitle } from "src/hooks/useTitle";
import { Link, useParams } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { imageOnError } from "src/utils/image-helper";
import { IAdminDetailPlanPayload } from "src/api/types/admin-plans";
import { adminPlansService } from "src/api/services/admin-plan";
import dateHelper from "src/utils/date-helper";
import PlanDays from "src/components/app/PlanDays";
import { IPlanDay } from "src/api/types/plan-days";
import PlanStatusActionsBtns from "./components/StatusActionButtons";
import { useConfirmModal } from "src/hooks/useConfirmModal";
import { IEntityDetailsField } from "src/components/app/EntityDetails/types";
import EntityDetails from "src/components/app/EntityDetails";
import { useGlobal } from "src/global-context/GlobalContext";
import { nutritionistPlansService } from "src/api/services/nutritionist-plans";

const PlanDetail: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.plans"));
    const params = useParams<{ id: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [getPlanController] = useState<AbortController>(new AbortController());
    const [planDetails, setPlanDetails] = useState<
        IAdminDetailPlanPayload | undefined
    >();
    const [planDetailFields, setPlanDetailFields] = useState<(IEntityDetailsField | null)[]>([]);
    const { user } = useGlobal();

    const { openModal } = useConfirmModal<IAdminDetailPlanPayload>({
        confirmLabel: "Yes",
        onConfirm: (data?: IAdminDetailPlanPayload) => {
            if (data) {
                if (planDetails) {
                    adminPlansService.changeActivation(data._id, !data.active);
                    setPlanDetails({ ...planDetails, active: !planDetails?.active });
                }
            }
        },
    });

    const getDetail = useCallback(async () => {
        try {
            if (user) {
                setLoading(true);
                if (params.id) {
                    const service = user.role === "admin" ? adminPlansService : nutritionistPlansService;
                    const result = await service.getDetail(
                        params.id,
                        getPlanController.signal
                    );
                    setPlanDetails(result.data.payload);
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [params, getPlanController.signal, user]);

    useEffect(() => {
        getDetail();
        return () => {
            getPlanController.abort();
        };
    }, [getDetail, getPlanController]);

    useEffect(() => {
        if (planDetails) {
            const data = { ...planDetails }
            setPlanDetailFields([{
                data: {
                    label: t("fields.code"),
                    value: data.code
                },
                arabicData: {
                    label: t("fields.duration"),
                    value: `${data.duration} ${t("fields.days")}`
                }
            }, {
                data: {
                    label: t("fields.type"),
                    value: data.type_en
                },
                arabicData: {
                    label: t("fields.isActive"),
                    value: data.active ? "Yes" : "No"
                }
            }, user?.role === "admin" ? {
                data: {
                    label: t("fields.nutritionist"),
                    value: <Link to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.DETAILS.BY_DATA((data.nutritionist as any)._id).ABSOLUTE}>{`${(data.nutritionist as any).firstname_en} ${(data.nutritionist as any).lastname_en}`}</Link>
                },
                arabicData: {
                    label: t("fields.chatEnabled"),
                    value: data.chatEnabled ? "Yes" : "No"
                }
            } : null, {
                data: {
                    label: t("fields.status"),
                    value: data.status
                },
                divide: data.status !== "rejected"
            }, data.status === "rejected" ? {
                data: {
                    label: t("fields.latestRejectionReason"),
                    value: data.rejectionMessage || "",
                },
                divide: true
            } : null, {
                data: {
                    label: t("fields.description"),
                    value: data.description_en
                },
                arabicData: {
                    label: t("fields.description_ar"),
                    value: data.description_ar
                },
                bidirectional: true
            }, {
                data: {
                    label: t("fields.contains"),
                    value: data.contains_en
                },
                arabicData: {
                    label: t("fields.contains_ar"),
                    value: data.contains_ar
                },
                bidirectional: true
            }, {
                data: {
                    label: `${t("fields.first")} ${t("fields.brief")}`,
                    value: data.briefs_en[0]
                },
                arabicData: {
                    label: `${t("fields.first_ar")} ${t("fields.brief_ar")}`,
                    value: data.briefs_ar[0]
                },
                bidirectional: true
            }, {
                data: {
                    label: `${t("fields.second")} ${t("fields.brief")}`,
                    value: data.briefs_en[1]
                },
                arabicData: {
                    label: `${t("fields.second_ar")} ${t("fields.brief_ar")}`,
                    value: data.briefs_ar[1]
                },
                bidirectional: true
            }, {
                data: {
                    label: `${t("fields.third")} ${t("fields.brief")}`,
                    value: data.briefs_en[2]
                },
                arabicData: {
                    label: `${t("fields.third_ar")} ${t("fields.brief_ar")}`,
                    value: data.briefs_ar[2]
                },
                bidirectional: true,
                divide: true
            }, {
                data: {
                    label: t("fields.createdAt"),
                    value: dateHelper.formatDate(data.createdAt)
                },
                arabicData: {
                    label: t("fields.updatedAt"),
                    value: dateHelper.formatDate(data.updatedAt)
                }
            }, {
                data: {
                    label: t("fields.price"),
                    value: `${data.originalPrice} KD`
                },
                arabicData: {
                    label: t("fields.discountPrice"),
                    value: data.discountedPrice ? `${data.discountedPrice} KD` : "---"
                }
            }, {
                data: {
                    label: t("fields.fat"),
                    value: `${data.fat} GR`
                },
                arabicData: {
                    label: t("fields.carbohydrate"),
                    value: `${data.carbohydrate} GR`
                }
            }, {
                data: {
                    label: t("fields.protein"),
                    value: `${data.protein} GR`
                },
                arabicData: {
                    label: t("fields.calories"),
                    value: `${data.calories} KC`
                }
            }])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planDetails])

    const onDaysUpdate = (days: IPlanDay[]) => {
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                daysDetail: days,
                status: "draft",
            });
        }
    };

    const toggleActivation = () => {
        if (planDetails) {
            const title = `${planDetails.active ? "Deactivate" : "Activate"} Plan`;
            const message = `${planDetails.active ? "Deactivating" : "Activating"
                } <b>${planDetails.title_en}</b>, are you sure?`;
            openModal(planDetails, title, message);
        }
    };

    return (
        <ContentView>
            {loading && (
                <Center>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    />
                </Center>
            )}
            {planDetails && (
                <>
                    <Box mb={6} display="flex" justifyContent="space-between">
                        <Box>
                            <Text as="h3" fontSize="1.4rem">
                                {planDetails.title_en}
                            </Text>
                        </Box>
                        <Box>
                            <Button
                                as={Link}
                                colorScheme="brand"
                                size="md"
                                type="submit"
                                to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.ROOT.ABSOLUTE}
                            >
                                {t("actions.back")}
                            </Button>
                            <Button
                                colorScheme={planDetails.active ? "red" : "green"}
                                size="md"
                                ml="3"
                                onClick={toggleActivation}
                            >
                                {planDetails.active ? "Deactivate" : "Activate"}
                            </Button>
                            <PlanStatusActionsBtns
                                plan={planDetails}
                                setPlan={setPlanDetails}
                            />
                            <IconButton
                                aria-label="Edit"
                                icon={<Icon path={mdiPencil} size="28px" />}
                                color="gray.100"
                                bg="blue.300"
                                ml="3"
                                as={Link}
                                to={
                                    ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.EDIT.BY_DATA(
                                        planDetails._id
                                    ).ABSOLUTE
                                }
                            />
                        </Box>
                    </Box>
                    <Divider mb="6" />
                    <FormRow
                        bidirectional
                        leftContent={
                            <>
                                <Text textTransform={"capitalize"} as="span" fontWeight="bold">
                                    {t("fields.photo")}:
                                </Text>
                                <Image
                                    mt={"3"}
                                    boxSize="95%"
                                    objectFit="cover"
                                    src={planDetails.thumbnail_en}
                                    alt={planDetails.title_en}
                                    width={"95%"}
                                    height={"200px"}
                                    onError={(e: any) => {
                                        imageOnError(e, process.env.PUBLIC_URL + "/notImage.png");
                                    }}
                                />
                            </>
                        }
                        rightContent={
                            <>
                                <Text textTransform={"capitalize"} as="span" fontWeight="bold">
                                    {t("fields.photo_ar")}:
                                </Text>
                                <Image
                                    mt={"3"}
                                    boxSize="100%"
                                    objectFit="cover"
                                    src={planDetails.thumbnail_ar}
                                    alt={planDetails.title_ar}
                                    width={"100%"}
                                    height={"200px"}
                                    onError={(e: any) => {
                                        imageOnError(e, process.env.PUBLIC_URL + "/notImage.png");
                                    }}
                                />
                            </>
                        }
                    />
                    <EntityDetails items={planDetailFields} />

                    {planDetails.type_en === "normal" && (
                        <>
                            <Divider mb="6" borderColor="blackAlpha.600" />
                            <Box>
                                <Text fontWeight="bold" mb="4">
                                    Plan Days
                                </Text>
                                <PlanDays
                                    days={planDetails.daysDetail}
                                    planOrSubscriptionId={planDetails._id}
                                    duration={planDetails.duration}
                                    onUpdate={onDaysUpdate}
                                />
                            </Box>
                            <Divider mb="6" borderColor="blackAlpha.600" />
                        </>
                    )}
                </>
            )}
        </ContentView>
    );
};

export default PlanDetail;
