import React, {  useState } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { adminPlansService } from "src/api/services/admin-plan";
import ConfirmModal from "../../../ConfirmModal";
import PlanSubmitBtn from "../PlanSubmitBtn";
import { IAdminDetailPlanPayload } from "src/api/types/admin-plans";
import { PlanStatus } from "src/api/types/plan-status";
import { RejectPlanModal } from "./RejectPlanModal";
import { useGlobal } from "src/global-context/GlobalContext";

interface IPlanStatusActionsBtnsProps {
    plan: IAdminDetailPlanPayload;
    setPlan: React.Dispatch<
        React.SetStateAction<IAdminDetailPlanPayload | undefined>
    >;
}

const PlanStatusActionsButtons: React.FC<IPlanStatusActionsBtnsProps> = ({
    plan,
    setPlan,
}) => {
    const { user } = useGlobal();
    const { t } = useTranslation();
    const toast = useToast();
    const [approvedLoading, setApprovedLoading] = useState<boolean>(false);
    const [rejectedLoading, setRejectedLoading] = useState<boolean>(false);
    const [nextStatus, setNextStatus] = useState<string>("");
    const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
    const [showRejectPlanModal, setShowRejectPlanModal] = useState<boolean>(false);

    const changeStatus = async () => {
        if (nextStatus === "approve") {
            setApprovedLoading(true);
        } else {
            setRejectedLoading(true);
        }
        try {
            await adminPlansService.changeStatus(plan._id, nextStatus as PlanStatus);
            toast({
                status: "success",
                description: t("messages.planChangeStatus", {
                    status: `${nextStatus}ed`,
                }),
            });
            setPlan({ ...plan, status: `${nextStatus}ed` });
        } catch (error) {
            setApprovedLoading(false);
            setRejectedLoading(false);
            toast({
                status: "error",
                description: t("messages.someThingHappened"),
            });
        } finally {
            setApprovedLoading(false);
            setRejectedLoading(false);
        }
    };



    const onPlanSubmitted = (status: PlanStatus) => {
        setPlan({
            ...plan,
            status: status,
        });
    };

    const onRejectPlanClicked = () => {
        setShowRejectPlanModal(true);
    }

    const onRejectionConfirmed = (message: string) => {
        try {
            adminPlansService.rejection(plan._id, message);
            setPlan({ ...plan, status: "rejected",rejectionMessage:message });
            setShowRejectPlanModal(false);
            toast({
                status: "success",
                description: t("messages.planChangeStatus", {
                    status: `rejected`,
                }),
            });
        } catch (err) {
            console.log(err);
        }
    }

    const getStatusButtons = () => {
        if (user?.role === "admin" && plan.status === "submitted") {
            return <>
                <Button
                    colorScheme="orange"
                    size="md"
                    textTransform="capitalize"
                    ml="3"
                    onClick={() => {
                        setShowStatusModal(true);
                        setNextStatus("approve");
                    }}
                    isLoading={approvedLoading}
                    disabled={rejectedLoading}
                >
                    approve
                </Button>
                <Button
                    colorScheme="red"
                    textTransform="capitalize"
                    size="md"
                    ml="3"
                    onClick={onRejectPlanClicked}
                    disabled={approvedLoading}
                    isLoading={rejectedLoading}
                >
                    reject
                </Button>
            </>

        }
        return null;
    }

    return (
        <>
            {
                getStatusButtons()
            }
            {plan.status === "draft" && (
                <PlanSubmitBtn
                    planId={plan._id}
                    isDisable={
                        plan.type_en === "normal"
                            ? plan.status === "draft" &&
                            plan.daysDetail.length !== plan.duration
                            : false
                    }
                    submitCallBack={onPlanSubmitted}
                />
            )}
            <ConfirmModal
                isOpen={showStatusModal}
                setIsOpen={setShowStatusModal}
                onConfirm={() => {
                    setShowStatusModal(false);
                    changeStatus();
                }}
                message={`Are you sure ${nextStatus} this plan?`}
            />
            {
                showRejectPlanModal && <RejectPlanModal isOpen={showRejectPlanModal} setIsOpen={setShowRejectPlanModal} onConfirm={onRejectionConfirmed} />
            }
        </>
    );
};

export default PlanStatusActionsButtons;
