import { Box } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";

import Content from "./components/Content";
import Drawer from "./components/Drawer";
import Navbar from "./components/Navbar";
import { DashboardLayoutProvider } from "./DashboardLayoutContext";
import "./index.css";

const DashboardLayout: React.FC = () => {
    
    return (
        <DashboardLayoutProvider>
            <Box className="dashboard-layout">
                <Navbar />
                <Drawer />
                <Content>
                    <Outlet />
                </Content>
            </Box>
        </DashboardLayoutProvider>
    );
}

export default DashboardLayout;