import { Box } from "@chakra-ui/react";
import React from "react";

interface IInfoFieldProps{
    label:string;
}

const InfoField:React.FC<IInfoFieldProps> = (props)=>{
    const {label,children} = props;
    return <Box width="100%" mb="4">
        <Box fontSize="0.875rem" mb="1"
            color="gray.600">{label}:</Box>
        <Box fontSize="1.1rem">{children}</Box>
    </Box>
}

export default InfoField;