import { IMenuData } from "../../types";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { mdiBriefcaseAccount,mdiAccountSupervisor,mdiAccountGroup,mdiTicketAccount,mdiClipboardList,mdiShieldAccount,mdiDoctor,mdiHomeEdit} from '@mdi/js';


export const nutritionistMenuData: IMenuData = {
    title: "titles.nutritionist",
    icon: mdiDoctor,
    navLinks: [{
        text: "titles.myPlans",
        href: ROUTE_CONSTANTS.DASHBOARD.DOCTORS.PLANS.ROOT.ABSOLUTE,
        icon: mdiClipboardList
    }, {
        text: "titles.mySubscriptions",
        href: ROUTE_CONSTANTS.DASHBOARD.DOCTORS.MY_SUBSCRIPTIONS.ABSOLUTE,
        icon: mdiTicketAccount
    }]
};

export const adminMenuData: IMenuData = {
    title: "titles.admin",
    icon: mdiShieldAccount,
    navLinks: [{
        text: "titles.users",
        href: ROUTE_CONSTANTS.DASHBOARD.ADMIN.USERS.ABSOLUTE,
        icon: mdiAccountGroup
    }, {
        text: "titles.nutritionists",
        href: ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.ROOT.ABSOLUTE,
        icon: mdiBriefcaseAccount
    }, {
        text: "titles.administrators",
        href: ROUTE_CONSTANTS.DASHBOARD.ADMIN.ADMINISTRATORS.ROOT.ABSOLUTE,
        icon: mdiAccountSupervisor
    }, {
        text: "titles.plans",
        href: ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.ROOT.ABSOLUTE,
        icon: mdiClipboardList
    }, {
        text: "titles.subscriptions",
        href: ROUTE_CONSTANTS.DASHBOARD.ADMIN.SUBSCRIPTIONS.ROOT.ABSOLUTE,
        icon: mdiTicketAccount
    }, {
        text: "titles.mobileHomePage",
        href: ROUTE_CONSTANTS.DASHBOARD.ADMIN.MOBILE_HOME_PAGE.ROOT.ABSOLUTE,
        icon: mdiHomeEdit
    }]
};