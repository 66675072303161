import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  ISubscriptionDetailPayload,
  ISubscriptionListPayload,
} from "../types/admin-subscriptions";
import { IAxiosResponse } from "../types/axios-response";
import { INewPlanDay, IPlanDay } from "../types/plan-days";

export const adminSubscriptionService = {
  getAll(abortSignal: AbortSignal, searchQuery?: string) {
    return AxiosInstance.get<IAxiosResponse<ISubscriptionListPayload[]>>(
      `${API_ROUTES.PANEL.SUBSCRIPTIONS.ROOT}${searchQuery && `?${searchQuery}`
      }`,
      {
        signal: abortSignal,
      }
    );
  },
  getDetail(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<ISubscriptionDetailPayload>>(
      API_ROUTES.PANEL.SUBSCRIPTIONS.GET_ITEM(id),
      {
        signal: abortSignal,
      }
    );
  },
  addDay(subscriptionId: string, dayDetails: INewPlanDay) {
    const body = {
      dayNo: dayDetails.dayNo,
      meals: dayDetails.meals.map((m) => {
        return {
          title: m.title,
          foods: m.foods.map((f) => {
            return {
              foodId: f.foodId,
              value: f.value,
              unit: f.unit,
            };
          }),
        };
      }),
    };
    return AxiosInstance.post<IAxiosResponse<IPlanDay>>(
      API_ROUTES.PANEL.SUBSCRIPTIONS.ADD_DAY(subscriptionId),
      body
    );
  },
  editDay(subscriptionId: string, dayDetails: INewPlanDay) {
    const body = {
      meals: dayDetails.meals.map((m) => {
        return {
          title: m.title,
          foods: m.foods.map((f) => {
            return {
              foodId: f.foodId,
              value: f.value,
              unit: f.unit,
            };
          }),
        };
      }),
    };
    return AxiosInstance.put<IAxiosResponse<IPlanDay>>(
      API_ROUTES.PANEL.SUBSCRIPTIONS.EDIT_DAY(subscriptionId, dayDetails.dayNo),
      body
    );
  },
  deleteDay(subscriptionId: string, dayNumber: number) {
    return AxiosInstance.delete<IAxiosResponse<IPlanDay>>(
      API_ROUTES.PANEL.SUBSCRIPTIONS.EDIT_DAY(subscriptionId, dayNumber)
    );
  },
  changeOrderedSubscriptionToAvailable(_id: string) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.PANEL.SUBSCRIPTIONS.SET_AVAILABLE(_id),
      {
        _id
      }
    );
  }
};
