import { Text } from "@chakra-ui/react";
import React from "react";

interface ILogoTextProps{
  className?:string;
}

const LogoText: React.FC<ILogoTextProps> = ({className}) => {
  return (
    <Text as="span" className={className}
        fontSize="2xl" color="brand.800">ELEVATE</Text>
  );
};

export default LogoText;
