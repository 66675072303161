import { Box, Button, Modal, ModalBody, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAddingFood, INewDayMeal, INewPlanDay } from "src/api/types/plan-days";
import { usePlandays } from "../../PlanDaysContext";
import { AddFood } from "./AddFood";
import { DayMeals } from "./DayMeals";
import { getAddMealDefaults } from "./default-data";

interface IAddDayModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    newDayOrder: number;
    onConfirm: (createdDay: INewPlanDay) => void;
}


const AddDayModal: React.FC<IAddDayModalProps> = (props) => {
    const { isOpen, onConfirm, setIsOpen, newDayOrder } = props;
    const { editingDayTarget,addingDay } = usePlandays();
    const { t } = useTranslation();
    const [activeMeal, setActiveMeal] = useState<INewDayMeal | null>(null);
    const [meals, setMeals] = useState<INewDayMeal[]>([...getAddMealDefaults()]);
    const [showEditBtn,setShowEditBtn] = useState<boolean>(true);
    const [initialEditMealsStr,setInitialEditMealsStr] = useState<string>("");

    useEffect(() => {
        if (editingDayTarget) {
            setInitialEditMealsStr(JSON.stringify(editingDayTarget.meals));
            const mealsForEdit:INewDayMeal[] =[...editingDayTarget.meals];
            const snack1Index = mealsForEdit.findIndex(m=>m.title === "snack1");
            if(snack1Index === -1){
                mealsForEdit.push({title:"snack1",foods:[]});
            }
            const snack2Index = mealsForEdit.findIndex(m=>m.title === "snack2");
            if(snack2Index === -1){
                mealsForEdit.push({title:"snack2",foods:[]});
            }
            setMeals(mealsForEdit);
        } else {
            setMeals(getAddMealDefaults())
        }
    }, [editingDayTarget])

    const onModalClosed = () => {
        setIsOpen(false);
    }

    const onCancelClicked = () => {
        setIsOpen(false);
    }

    const onConfirmClicked = () => {
        let checkAdd = true;
        for (let i = 0; i < 3; i++) {
            if (!meals[i].foods.length) {
                checkAdd = false
            }
        }
        if (checkAdd) {
            if (editingDayTarget) {
                onConfirm({
                    dayNo: editingDayTarget.dayNo,
                    meals
                });
            } else {
                onConfirm({
                    dayNo: newDayOrder,
                    meals
                });
            }

        }
    }

    const onFoodsAdded = (foods: IAddingFood[]) => {
        const targetMealIndex = meals.findIndex(m => m.title === activeMeal?.title);
        const tempMeals = [...meals];
        tempMeals[targetMealIndex].foods = [...foods];
        setMeals([...tempMeals]);
        setActiveMeal(null);
    }

    useEffect(()=>{
        if(editingDayTarget){
            const strMeals = JSON.stringify(meals);
            setShowEditBtn(strMeals !== initialEditMealsStr);
        }
    },[editingDayTarget,meals,initialEditMealsStr])

    return (<Modal isOpen={isOpen} onClose={onModalClosed} size="lg">
        <ModalOverlay />
        <ModalContent>
            <ModalBody width="100%">
                <Box width="100%" overflow="hidden">
                    <Box width="200%" display="flex" marginLeft={!!activeMeal ? "-100%" : "0"} transition="all ease 0.2s">
                        <Box width="50%">
                            <Text as="h2" fontSize="1.4rem" mb="2" pt="2" pb="2">Day {!!editingDayTarget ? editingDayTarget.dayNo : newDayOrder}</Text>
                            <DayMeals setActiveMealToAddFood={setActiveMeal} meals={meals} />
                            <Box mt="4" textAlign="right" mb="2">
                                <Button colorScheme="orange" mr="2" onClick={onCancelClicked}>{t("actions.cancel")}</Button>
                                {
                                    showEditBtn && (<Button colorScheme="teal" onClick={onConfirmClicked}
                                    isLoading={addingDay}>{!!editingDayTarget ? t("actions.done") : t("actions.add")}</Button>)
                                }
                            </Box>
                        </Box>
                        <Box width="50%">
                            {
                                !!activeMeal && <AddFood meal={activeMeal} addFoods={onFoodsAdded} setActiveMeal={setActiveMeal} />
                            }
                        </Box>
                    </Box>
                </Box>
            </ModalBody>
        </ModalContent>
    </Modal>);
}

export default AddDayModal;