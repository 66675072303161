import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { IFood } from "../types/foods";

export const foodService = {
    getAll(abortSignal: AbortSignal, search?: string) {
        return AxiosInstance.get<IAxiosResponse<IFood[]>>(`${API_ROUTES.PANEL.FOODS.ROOT}${search ? `?search=${search}`: ''}`, {
            signal: abortSignal,
        }, true);
    }
};