import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  ICreatePlanForm,
  IDetailPlanPayload,
  IListPlanPayload,
} from "../types/nutritionist-plans";
import {IAdminDetailPlanPayload} from "../types/admin-plans";
import { IAxiosResponse } from "../types/axios-response";
import { INewPlanDay, IPlanDay } from "../types/plan-days";
import { IPlanChangeStatusPayload } from "../types/plan-status";

export const nutritionistPlansService = {
  create(body: ICreatePlanForm) {
    return AxiosInstance.post<IAxiosResponse<IDetailPlanPayload>>(
      API_ROUTES.DOCTOR.PLANS.ROOT,
      body
    );
  },
  getAll(abortSignal: AbortSignal, searchQuery?: string) {
    return AxiosInstance.get<IAxiosResponse<IListPlanPayload[]>>(
      `${API_ROUTES.DOCTOR.PLANS.ROOT}${
        searchQuery && `?${searchQuery}`
      }`,
      {
        signal: abortSignal,
      }
    );
  },
  getDetail(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IAdminDetailPlanPayload>>(
      API_ROUTES.DOCTOR.PLANS.GET_ITEM(id),
      {
        signal: abortSignal,
      }
    );
  },
  update(data: ICreatePlanForm, id: string) {
    const finalBody: any = { ...data };
    return AxiosInstance.put<IAxiosResponse<IDetailPlanPayload>>(
      API_ROUTES.DOCTOR.PLANS.GET_ITEM(id),
      finalBody
    );
  },
  addDay(planId: string, dayDetails: INewPlanDay) {
    const body = {
      dayNo: dayDetails.dayNo,
      meals: dayDetails.meals.map((m) => {
        return {
          title: m.title,
          foods: m.foods.map((f) => {
            return {
              foodId: f.foodId,
              value: f.value,
              unit: f.unit,
            };
          }),
        };
      }),
    };
    return AxiosInstance.post<IAxiosResponse<IPlanDay>>(
      API_ROUTES.DOCTOR.PLANS.ADD_DAY(planId),
      body
    );
  },
  editDay(planId: string, dayDetails: INewPlanDay) {
    const body = {
      meals: dayDetails.meals.map((m) => {
        return {
          title: m.title,
          foods: m.foods.map((f) => {
            return {
              foodId: f.foodId,
              value: f.value,
              unit: f.unit,
            };
          }),
        };
      }),
    };
    return AxiosInstance.put<IAxiosResponse<IPlanDay>>(
      API_ROUTES.DOCTOR.PLANS.EDIT_DAY(planId, dayDetails.dayNo),
      body
    );
  },
  deleteDay(planId: string, dayNumber: number) {
    return AxiosInstance.delete<IAxiosResponse<IPlanDay>>(
      API_ROUTES.DOCTOR.PLANS.EDIT_DAY(planId, dayNumber)
    );
  },
  submit(planId: string) {
    return AxiosInstance.put<IAxiosResponse<IPlanChangeStatusPayload>>(
      API_ROUTES.DOCTOR.PLANS.SUBMIT(planId)
    );
  },
};
