import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const theme = extendTheme(
    {
        colors: {
            brand: {
                500: "#0AA9B1",
                800:"#065569"
            },

        }
    },
    withDefaultColorScheme({ colorScheme: "brand" })
);

type Theme = typeof theme;

export type { Theme };
export { theme };

