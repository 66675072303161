import React from "react";

interface ILogoProps{
  className?:string;
}

const Logo: React.FC<ILogoProps> = ({className}) => {
  return (
    <svg width="188" height="98" viewBox="0 0 188 98" fill="none" xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path d="M125.677 97.5213V57.5583H187.184L178.412 48.7899H116.908V88.753H62.0153L46.3867 73.1244H101.284V33.1614H162.787L154.015 24.393H92.5153V64.356H37.6184L21.9898 48.7314H76.8868V8.76837H138.386L129.618 0H68.1184V39.963H0.820068L58.3823 97.5213H125.677Z" fill="#0AA9B1" />
    </svg>
  );
};

export default Logo;
