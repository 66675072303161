import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Switch,
  Button,
} from "@chakra-ui/react";
import Icon from "@mdi/react";
import { mdiMagnify, mdiTune } from "@mdi/js";
import { adminUsersService } from "src/api/services/admin-users";
import { IAdminUserListPayload } from "src/api/types/admin-user";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import dateHelper from "src/utils/date-helper";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import EmptyResult from "src/components/app/EmptyResult";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useSearch } from "src/hooks/useSearch";
import { useConfirmModal } from "src/hooks/useConfirmModal";
import Pagination from "src/components/app/Pagination";
const Users: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.users"));

  const {
    searchQuery,
    setSearchQuery,
    callApiController,
    resultMeta:usersMeta,
    searchResult: users,
    loading,
    setSearchResult: setUsers,
    setFilters,
    filters
  } = useSearch<IAdminUserListPayload[]>({
    searchApi: adminUsersService.getAll,
    filterItems: ["pageNumber","pageSize"]
  });
  const [searchValue, setSearchValue] = useState<string>(searchQuery || "");

  const { openModal } = useConfirmModal<IAdminUserListPayload>({
    confirmLabel: "Yes",
    onConfirm: (data?: IAdminUserListPayload) => {
      if (data) {
        adminUsersService.changeActivation(data._id, !data.active);
        const tempItems = [...users];
        const itemIndex = tempItems.findIndex((ti) => ti._id === data._id);
        tempItems[itemIndex].active = !tempItems[itemIndex].active;
        setUsers(tempItems);
      }
    },
  });

  useEffect(() => {
    setSearchValue(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    return () => {
      callApiController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    setSearchQuery(searchValue);
  };

  const changePage = (page:number)=>{
    setFilters({ ...filters, "pageNumber": `${page}` });
  }

  const onSearchQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  useEffect(() => {
    return () => {
      callApiController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActivation = (sender: IAdminUserListPayload) => {
    const title = `${sender.active ? "Deactivate" : "Activate"} User`;
    const message = `${sender.active ? "Deactivating" : "Activating"} <b>${sender.email
      }</b>, are you sure?`;
    openModal(sender, title, message);
  };

  const onPageSizeChanged = (value:number)=>{
    setFilters({ ...filters, "pageSize": `${value}`,"pageNumber": `1` });
  }

  return (
    <Box>
      <Text as="h3" fontSize="3xl">
        {t("titles.users")}
      </Text>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray"
              children={<Icon path={mdiMagnify} size="24px" />}
            />
            <Input
              type="search"
              placeholder={t("actions.search")}
              value={searchValue}
              onChange={onSearchQueryChanged}
              backgroundColor="white"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearch();
                }
              }}
            />
            <Button ml="3" onClick={() => onSearch()} isLoading={loading}>
              {t("actions.search")}
            </Button>
          </InputGroup>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton
              aria-label="Test"
              icon={<Icon path={mdiTune} size="28px" />}
              color="gray.700"
              bg="transparent"
            />
          </Box>
        </Box>
      </Box>
      <Box height="5px" mb="1">
        {loading && <HorizontalLoading />}
      </Box>
      <Table
        variant="simple"
        colorScheme="blackAlpha"
        size="md"
        boxShadow="md"
        backgroundColor="white"
        borderRadius="md"
      >
        <Thead>
          <Tr>
            <Th></Th>
            <Th>{t("fields.email")}</Th>
            <Th>{t("fields.fullName")}</Th>
            <Th>{t("fields.createdAt")}</Th>
            <Th>{t("fields.updatedAt")}</Th>
            <Th>{t("fields.isActive")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((u, ind) => (
            <Tr key={u._id}>
              <Td>
                <Text as="span" color="gray.400">{((usersMeta.pageNumber-1)*usersMeta.pageSize)+(ind+1)}</Text>
              </Td>
              <Td color="blue.500">
                <Link
                  to={
                    ROUTE_CONSTANTS.DASHBOARD.ADMIN.USERS.DETAILS.BY_DATA(u._id)
                      .ABSOLUTE
                  }
                >
                  {u.email}
                </Link>
              </Td>
              <Td >
                {`${u.firstname_en || "---"} ${u.lastname_en || "---"}`}
              </Td>
              <Td>{dateHelper.formatDate(u.createdAt)}</Td>
              <Td>{dateHelper.formatDate(u.updatedAt)}</Td>
              <Td>
                {
                  <Switch
                    isChecked={u.active}
                    onChange={() => {
                      toggleActivation(u);
                    }}
                  />
                }
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {!users.length && !loading && <EmptyResult />}
      <Pagination pageSize={usersMeta.pageSize} defaultActivePage={usersMeta.pageNumber} 
        totalRecordsCount={usersMeta.totalCount} onPageChanged={changePage}
        onPageSizeChanged={onPageSizeChanged}
        entity="user"/>
    </Box>
  );
};

export default Users;
