import { Accordion, Box, IconButton, SimpleGrid , Text} from "@chakra-ui/react";
import React, { useState } from "react";
import { IPlanDay } from "src/api/types/plan-days";
import Meal from "../Meal";
import Icon from "@mdi/react";
import { mdiTrashCan, mdiPencil } from "@mdi/js";
import { usePlandays } from "../../PlanDaysContext";
import ConfirmModal from "../../../ConfirmModal";
import clsx from "clsx";


interface IDayProps {
    dayDetails: IPlanDay;
    loading: boolean;
}

const DayCard: React.FC<IDayProps> = (props) => {
    const { dayDetails, loading = false } = props;
    const { removeDay, isEditable, setEditingDayTarget, setOpenAddDayModal } = usePlandays();
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);

    const onDeleteClicked = () => {
        setOpenConfirm(true);
    }

    const onDeleteConfirmed = () => {
        removeDay(dayDetails.dayNo);
        setOpenConfirm(false);
    }

    const openEditModal = () => {
        setEditingDayTarget({
            dayNo: dayDetails.dayNo,
            meals: dayDetails.meals.map(m => {
                return {
                    title: m.title,
                    foods: m.foods.map(mf => {
                        return {
                            foodId: mf.foodId,
                            value: mf.value,
                            unit: mf.unit,
                            name: mf.name_en
                        }
                    })
                }
            })
        });
        setOpenAddDayModal(true);
    }

    return <><Box className={clsx("plan-day-card-base", "plan-day-card", loading && "loading")} >
        <Box className="day-card-content">
            <Box display="flex" justifyContent="space-between" alignItems="center"
                borderBottom="1px solid #ebebeb">
                <Box className="plan-day-title">Day {dayDetails.dayNo}</Box>
                {
                    isEditable && (<Box>
                        <IconButton aria-label="Test" icon={<Icon path={mdiTrashCan} size="24px" />}
                            color="gray.700"
                            bg="transparent" onClick={onDeleteClicked} />
                        <IconButton aria-label="Test" icon={<Icon path={mdiPencil} size="24px" />}
                            color="gray.700"
                            bg="transparent"
                            _hover={{ bg: "transparent" }}
                            onClick={openEditModal} />
                    </Box>)
                }
            </Box>
            <Box p="4" borderBottom="1px solid #ebebeb" mb="2" display="flex" fontSize="0.875rem">
                <SimpleGrid columns={4} spacing="2">
                    <Box>
                        <span>Cal: </span>
                        <Text as="span" fontWeight="bold">{dayDetails.calories || "---"}</Text>
                    </Box>
                    <Box>
                        <span>Carb: </span>
                        <Text as="span" fontWeight="bold">{dayDetails.carbohydrate || "---"}</Text>
                    </Box>
                    <Box>
                        <span>Prot: </span>
                        <Text as="span" fontWeight="bold">{dayDetails.protein || "---"}</Text>
                    </Box>
                    <Box>
                        <span>Fat: </span>
                        <Text as="span" fontWeight="bold">{dayDetails.fat || "---"}</Text>
                    </Box>
                </SimpleGrid>
            </Box>
            <div>
                <Accordion allowMultiple>
                    {
                        dayDetails.meals.map((m, ind) => (
                            <Meal key={m.title} meal={m} isLatest={ind + 1 === dayDetails.meals.length} />
                        ))
                    }
                </Accordion>
            </div>
        </Box>
    </Box>
        <ConfirmModal isOpen={openConfirm} onConfirm={onDeleteConfirmed} setIsOpen={setOpenConfirm} />
    </>
}

export default DayCard;