import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  IconButton,
  Switch,
  Select,
  FormLabel,
} from "@chakra-ui/react";
import Icon from "@mdi/react";
import { mdiMagnify, mdiPencil, mdiPlus } from "@mdi/js";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { adminPlansService } from "src/api/services/admin-plan";
import { IAdminListPlanPayload } from "src/api/types/admin-plans";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import dateHelper from "src/utils/date-helper";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import EmptyResult from "src/components/app/EmptyResult";
import { useSearch } from "src/hooks/useSearch";
import { useConfirmModal } from "src/hooks/useConfirmModal";
import { planTypeFilters } from "src/constants/filters";
import Pagination from "src/components/app/Pagination";

const Plans: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.plans"));
  const {
    searchQuery,
    setSearchQuery,
    callApiController,
    searchResult: plans,
    resultMeta:plansMeta,
    setSearchResult: setPlans,
    filters,
    setFilters,
    loading,
  } = useSearch<IAdminListPlanPayload[]>({
    searchApi: adminPlansService.getAll,
    filterItems: ["planType","pageSize","pageNumber"]
  });
  const [searchValue, setSearchValue] = useState<string>(searchQuery || "");
  const [searchFilters, setSearchFilters] = useState<Record<string, string>>(filters);

  

  const { openModal } = useConfirmModal<IAdminListPlanPayload>({
    confirmLabel: "Yes",
    onConfirm: (data?: IAdminListPlanPayload) => {
      if (data) {
        adminPlansService.changeActivation(data._id, !data.active);
        const tempItems = [...plans];
        const itemIndex = tempItems.findIndex((ti) => ti._id === data._id);
        tempItems[itemIndex].active = !tempItems[itemIndex].active;
        setPlans(tempItems);
      }
    },
  });

  useEffect(() => {
    setSearchValue(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    setSearchFilters({...filters,"pageNumber":"1"});
  }, [filters])

  useEffect(() => {
    return () => {
      callApiController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    setSearchQuery(searchValue);
  };

  const onSearchQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  const toggleActivation = (
    sender: IAdminListPlanPayload
  ) => {
    const title = `${sender.active ? "Deactivate" : "Activate"} Plan`;
    const message = `${sender.active ? "Deactivating" : "Activating"} <b>${sender.title_en}</b>, are you sure?`;
    openModal(sender, title, message);
  };

  const onTypeChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({ ...filters, "planType": event.target.value,"pageNumber":"1" });
  }

  const changePage = (page:number)=>{
    setFilters({ ...filters, "pageNumber": `${page}` });
  }

  const onPageSizeChanged = (value:number)=>{
    setFilters({ ...filters, "pageSize": `${value}`,"pageNumber": `1` });
  }

 

  return (
    <Box>
      <Text as="h3" fontSize="3xl">
        {t("titles.plans")}
      </Text>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray"
              children={<Icon path={mdiMagnify} size="24px" />}
            />
            <Input
              type="search"
              placeholder={t("actions.search")}
              value={searchValue}
              onChange={onSearchQueryChanged}
              backgroundColor="white"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearch();
                }
              }}
            />
            <Button ml="3" onClick={() => onSearch()} isLoading={loading}>
              {t("actions.search")}
            </Button>
          </InputGroup>
        </Box>
        <Box display="flex" alignItems="center">
          <Box ml="4">
            <Button
              leftIcon={<Icon path={mdiPlus} size="24px" />}
              as={Link}
              to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.CREATE.ABSOLUTE}
            >
              {t("actions.add")}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box bg="#fff" boxShadow="lg" mb="3" p="3" borderRadius="8px">
        <Text as="h3" fontSize="1.2rem" mb="2">Filters</Text>
        <Box display="flex">
          <Box>
            <FormLabel htmlFor="planType">Plan Type</FormLabel>
            <Select minWidth="200px" id="planType" onChange={onTypeChanged} value={searchFilters.planType}>
              {
                planTypeFilters.map(pt => (<option value={pt.value} key={pt.value}>{pt.label}</option>))
              }
            </Select>
          </Box>
        </Box>
      </Box>
      <Box height="5px" mb="1">
        {loading && <HorizontalLoading />}
      </Box>
      <Table
        variant="simple"
        colorScheme="blackAlpha"
        size="md"
        boxShadow="md"
        backgroundColor="white"
        borderRadius="md"
      >
        <Thead>
          <Tr>
            <Th></Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.code")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.title")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.nutritionist")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.type")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.price")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.discountPrice")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.status")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.createdAt")}
            </Th>
            <Th px={2} >
              {t("fields.isActive")}
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {plans.map((n,ind) => (
            <Tr key={n._id}>
              <Td>
                <Text as="span" color="gray.400">{((plansMeta.pageNumber-1)*plansMeta.pageSize)+(ind+1)}</Text>
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.code ?? "---"}
              </Td>
              <Td px={2} textAlign={"center"} color="blue.500">
                <Box maxWidth="200px" m="0 auto">
                  <Link
                    to={
                      ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.DETAILS.BY_DATA(n._id)
                        .ABSOLUTE
                    }
                  >
                    {n.title_en ?? "---"}
                  </Link>
                </Box>
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.nutritionist
                  ? `${(n.nutritionist as any).firstname_en} ${(n.nutritionist as any).lastname_en
                  }`
                  : "---"}
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.type_en ?? "---"}
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.originalPrice ?? "---"} {n.currency}
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.discountedPrice ?? "---"} {n.currency}
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.status ?? "---"}
              </Td>
              <Td px={2} textAlign={"center"}>
                {dateHelper.formatDate(n.createdAt)}
              </Td>
              <Td>
                {
                  <Switch
                    isChecked={n.active}
                    onChange={() => {
                      toggleActivation(n);
                    }}
                  />
                }
              </Td>
              <Td>
                <IconButton
                  aria-label="Test"
                  icon={<Icon path={mdiPencil} size="28px" />}
                  color="gray.100"
                  bg="blue.300"
                  as={Link}
                  to={
                    ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.EDIT.BY_DATA(n._id)
                      .ABSOLUTE
                  }
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {!plans.length && !loading && <EmptyResult />}
      <Pagination pageSize={plansMeta.pageSize} defaultActivePage={plansMeta.pageNumber} 
        totalRecordsCount={plansMeta.totalCount} onPageChanged={changePage}
        onPageSizeChanged={onPageSizeChanged}
        entity="plan"/>
    </Box>
  );
};

export default Plans;
