import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState,useCallback } from "react";
import { Link,  useNavigate ,useParams} from "react-router-dom";
import ContentView from "src/components/app/ContentView";
import FormRow from "src/components/app/FormRow";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { INewAdministratorForm, IUpdateAdminData } from "../types.d"
import { newAdministratorValidationSchema } from "src/validation-schemas/administrator";
import { useTitle } from "src/hooks/useTitle";
import { useTranslation } from "react-i18next";
import { adminAdministratorsService } from "src/api/services/admin-admins";
import ImageUpload from "src/components/app/ImageUpload";
import { DEFAULT_ASPECT_RATIO } from "src/constants/aspect-ratio";

const NewAdministrator: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.newAdministrator"));
    const toast = useToast();
    const params = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [saveDataAbortController] = useState<AbortController>(new AbortController());
    const [getDataAbortController] = useState<AbortController>(new AbortController());
    const [editMode,setEditMode] = useState<boolean>(false);
    const [defaultAvatar, setDefautAvatar] = useState<string>("");

    const { register, formState: { errors }, handleSubmit,reset, setValue } = useForm<INewAdministratorForm>({
        resolver: yupResolver(newAdministratorValidationSchema(t)),
        mode: "all",
        defaultValues: {
            email: "",
            firstname_en: "",
            firstname_ar: "",
            lastname_en: "",
            lastname_ar: "",
            avatar:""
        }
    });

    const getAdminDetails = useCallback(async()=>{
        try{
            if(params.id){
                setLoading(true);
                const result = await adminAdministratorsService.getById(params.id,getDataAbortController.signal);
                reset({
                    email: result.data.payload.email,
                    firstname_en: result.data.payload.firstname_en,
                    firstname_ar: result.data.payload.firstname_ar,
                    lastname_en: result.data.payload.lastname_en,
                    lastname_ar: result.data.payload.lastname_ar,
                    avatar:result.data.payload.avatar || ""
                });
                setDefautAvatar(result.data.payload.avatar);
                setEditMode(true);
            }
        }catch(err){
            console.log(err);
        }finally{
            setLoading(false)
        }
    },[params,reset,getDataAbortController])

    useEffect(() => {
       getAdminDetails();
    }, [getAdminDetails])

    const onSubmit = async (data: INewAdministratorForm) => {
        try {
            setLoading(true);
            if (params.id) {
                const { avatar,email, ...fields } = data;
                const finalData: any = { ...fields };
                if (data.avatar && !data.avatar.includes("http")) {
                    finalData.avatar = data.avatar;
                }
                await adminAdministratorsService.update(finalData as IUpdateAdminData, params.id);
                toast({
                    title: t("messages.nutritionistUpdated"),
                    status: 'success',
                    duration: 5000
                })
            } else {
                await adminAdministratorsService.create(data as INewAdministratorForm,saveDataAbortController.signal);
                toast({
                    title: t("messages.adminCreated"),
                    status: 'success',
                    duration: 5000
                })
            }
            navigate(ROUTE_CONSTANTS.DASHBOARD.ADMIN.ADMINISTRATORS.ROOT.ABSOLUTE);
            //------------------------------
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        return () => {
            saveDataAbortController.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAvatarChanged = (newData: string) => {
        setValue("avatar", newData);
    }


    return (<ContentView>
        <Box mb="6" pb="2" display="flex" justifyContent="space-between" alignItems="center" borderBottom="1px solid" borderColor="gray.200">
            <Box>
                <Text as="h3" fontSize="3xl">{t("titles.newAdministrator")}</Text>
            </Box>
            <Box display="flex" alignItems="center">
                <Button
                                as={Link}
                                colorScheme="brand"
                                size="md"
                                type="submit"
                                to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.ADMINISTRATORS.ROOT.ABSOLUTE}
                            >
                                {t("actions.backToList")}
                            </Button>
            </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box>
                <ImageUpload onImageChanged={onAvatarChanged} upload withCrop aspectRatio={DEFAULT_ASPECT_RATIO}
                    width={200} height={200} src={defaultAvatar}/>
                    <Box mb={3} fontSize="0.875rem" mt={-4}>
                        <p>Image Types: *.jpg, *.jpeg, *.png</p>
                        <p>Maximum Size: 5 MB</p>
                    </Box>
                {
                    !!errors.avatar && <Box mb="4" color="red.500" fontSize="0.875rem">
                        {errors.avatar.message}
                    </Box>
                }
            </Box>
            <FormRow
                bidirectional
                leftContent={<FormControl isInvalid={!!errors.email} isRequired>
                    <FormLabel htmlFor="email">{t("fields.emailAddress")}</FormLabel>
                    <Input id="email" type="email" size="lg" {...register("email")} disabled={editMode}/>
                    <FormErrorMessage>
                        {!!errors.email && errors.email.message}
                    </FormErrorMessage>
                </FormControl>} />
            <FormRow
                bidirectional
                leftContent={<FormControl isInvalid={!!errors.firstname_en} isRequired>
                    <FormLabel htmlFor="firstName">{t("fields.firstName")}</FormLabel>
                    <Input id="firstName" type="text" size="lg" {...register("firstname_en")} />
                </FormControl>}
                rightContent={<FormControl isInvalid={!!errors.firstname_ar} isRequired>
                    <FormLabel htmlFor="firstName_ar">{t("fields.firstName_ar")}</FormLabel>
                    <Input id="firstName_ar" type="text" size="lg" {...register("firstname_ar")} />
                </FormControl>} />
            <FormRow
                bidirectional
                leftContent={<FormControl isInvalid={!!errors.lastname_en} isRequired>
                    <FormLabel htmlFor="lastName">{t("fields.lastName")}</FormLabel>
                    <Input id="lastName" type="text" size="lg" {...register("lastname_en")} />
                </FormControl>}
                rightContent={<FormControl isInvalid={!!errors.lastname_ar} isRequired>
                    <FormLabel htmlFor="lastName_ar">{t("fields.lastName_ar")}</FormLabel>
                    <Input id="lastName_ar" type="text" size="lg" {...register("lastname_ar")} />
                </FormControl>} />
            <Box textAlign="right">
                <Button as={Link} to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.DOCTORS.ROOT.ABSOLUTE}
                    size="lg" colorScheme="orange" mr="4">{t("actions.cancel")}</Button>
                <Button colorScheme="brand" size="lg" type="submit" isLoading={loading}>{t("actions.save")}</Button>
            </Box>
        </form>

    </ContentView>)
}

export default NewAdministrator;