import { useEffect, useState } from "react"
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";


interface IPageItem {
    text: string;
    index: number;
}

export function usePagination(defaultActivePage: number, defaultPageSize: number = DEFAULT_PAGE_SIZE) {
    const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);
    const [pages, setPages] = useState<IPageItem[]>([]);
    const [activePage, setActivePage] = useState<number>(defaultActivePage);
    const [canGoNext, setCanGoNext] = useState<boolean>(true);
    const [canGoPrevious, setCanGoPrevious] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);
    const [pageCount, setPageCount] = useState<number>(1);

    useEffect(() => {
        const tempPageCount = totalRecordsCount <= pageSize ? 1 : Math.ceil(totalRecordsCount / pageSize);
        const tempPages: IPageItem[] = []
        setPageCount(tempPageCount);
        for (let i = 1; i <= tempPageCount; i++) {
            tempPages.push({
                text: `${i}`,
                index: i,
            });
        }
        setActivePage(defaultActivePage);
        if (tempPageCount === 1) {
            setCanGoNext(false);
            setCanGoPrevious(false);
        } else {
            if (defaultActivePage === 1) {
                setCanGoPrevious(false);
            } else {
                setCanGoPrevious(true);
            }
            if (defaultActivePage === tempPageCount) {
                setCanGoNext(false);
            } else {
                setCanGoNext(true);
            }
        }
        setPages(tempPages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalRecordsCount, pageSize]);

    useEffect(() => {
        if (defaultActivePage === 1) {
            setCanGoPrevious(false);
        } else {
            setCanGoPrevious(true);
        }
        if (defaultActivePage === pageCount) {
            setCanGoNext(false);
        } else {
            setCanGoNext(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultActivePage]);

    const goToPage = (page: number) => {
        if (page <= pageCount && page > 0) {
            setActivePage(page);
            if (pageCount > 1) {
                if (page === pageCount) {
                    setCanGoNext(false);
                } else {
                    setCanGoNext(true);
                }
                if (page === 1) {
                    setCanGoPrevious(false);
                } else {
                    setCanGoPrevious(true);
                }
            }

        }
    }

    return {
        activePage,
        canGoNext,
        canGoPrevious,
        pageCount,
        setPageSize,
        pages,
        goToPage,
        setTotalRecordsCount
    }
}