import { Box, Button, Input, Text } from "@chakra-ui/react";
import Icon from "@mdi/react";
import React, { useCallback, useEffect, useState } from "react";
import { mdiChevronLeft } from "@mdi/js";
import { FoodItem } from "./FoodItem";
import { IFood } from "src/api/types/foods";
import { foodService } from "src/api/services/food";
import { IAddingFood, INewDayMeal } from "src/api/types/plan-days";

interface IAddFoodProps {
    meal: INewDayMeal;
    setActiveMeal: (value: INewDayMeal | null) => void;
    addFoods: (foods: IAddingFood[]) => void;
}



export const AddFood: React.FC<IAddFoodProps> = (props) => {
    const { meal, setActiveMeal, addFoods } = props;
    const [foods, setFoods] = useState<IFood[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [getFoodsController] = useState<AbortController>(new AbortController());
    const [errors, setErrors] = useState<string[]>([]);

    const [filteredFoods, setFilteredFoods] = useState<IFood[]>([]);
    const [selectedFoods, setSelectedFoods] = useState<IAddingFood[]>(meal.foods);

    const fetchFoods = async (search?:string) => {
        try {
            setLoading(true);
            const result = await foodService.getAll(getFoodsController.signal, search);
            setFoods(result.data.payload);
            setFilteredFoods(result.data.payload);
        } catch (err) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchFoods();
        return () => {
            getFoodsController.abort();
        }
    }, [getFoodsController]);

    const onBackClicked = () => {
        setActiveMeal(null);
    }

    const onSearchFoodChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value?.trim()?.length > 0) {
            fetchFoods(value)
        } else {
            fetchFoods()
        }
    }

    const getUnitsOfFood = (foodId: string): string[] => {
        const targetFood = foods.find(f => f._id === foodId);
        if (targetFood) {
            return targetFood.units.map(u => u.unit)
        }
        return [];
    }

    const getSelected = (sender: IFood): { food: IAddingFood, selected: boolean, foodUnits: string[] } => {
        const existingSelectedIndex = selectedFoods.findIndex((f) => {
            return f.foodId === sender._id;
        });
        if (existingSelectedIndex !== -1) {
            return {
                food: selectedFoods[existingSelectedIndex],
                selected: true,
                foodUnits: getUnitsOfFood(sender._id)
            }
        } else {
            return {
                food: {
                    foodId: sender._id,
                    name: sender.name_en,
                    unit: sender.units[0].unit,
                    value: sender.units[0].value
                },
                foodUnits: getUnitsOfFood(sender._id),
                selected: false
            }
        }
    }

    const onAddFoodsClicked = () => {
        const tempErrors: string[] = [];
        selectedFoods.forEach(sf => {
            if (sf.value < 1) {
                tempErrors.push(`${sf.name} has 0 value! please enter a value bigger than 0`);
            }
        })
        if (tempErrors.length) {
            setErrors(tempErrors);
            return;
        }
        addFoods(selectedFoods);
    }

    const onFoodItemChanged = (sender: IAddingFood, selected: boolean) => {
        const tempChosenFoods = [...selectedFoods];
        if (selected) {
            const existingInd = tempChosenFoods.findIndex((tc) => {
                return tc.foodId === sender.foodId;
            })
            if (existingInd !== -1) {
                tempChosenFoods[existingInd] = { ...sender };
            } else {
                tempChosenFoods.push(sender);
            }
            setSelectedFoods(tempChosenFoods);
        } else {
            const foodInd = tempChosenFoods.findIndex((f) => {
                return f.foodId === sender.foodId;
            });
            tempChosenFoods.splice(foodInd, 1);
            setSelectedFoods(tempChosenFoods);
        }
    }

    const checkAddFoodDisabled = () => {
        switch (meal.title) {
            case "breakfast":
            case "lunch":
            case "dinner":
                if(!selectedFoods.length){
                    return true;
                }
                return false;
            default:
                return false;
        }
    }

    return <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Text as="h3" fontSize="1.2rem" pt="2" pb="2" mb="2">{`Add ${meal.title} Food`}</Text>
            <Box pr="1">
                <Button type="button" onClick={onBackClicked} colorScheme="whiteAlpha"
                    leftIcon={<Icon path={mdiChevronLeft} size="24px" />}
                    color="blackAlpha.700">Back</Button>
            </Box>
        </Box>
        {
            (<Box p="2">
                <Box>
                    <Input placeholder="Search Food" onChange={onSearchFoodChanged} />
                </Box>
                {loading ? "Loading..." : <Box maxHeight="200px" overflowY="auto" mt="4" border="1px solid" borderColor="gray.200" display={filteredFoods.length ? "flex" : "none"}
                    flexDirection="column">
                    {
                        filteredFoods.map((ff, ind) => {
                            const fData = getSelected(ff);
                            return <FoodItem onChange={onFoodItemChanged} foodData={fData.food} selected={fData.selected} key={ind}
                                foodUnits={fData.foodUnits} />
                        })
                    }
                </Box>}
                {
                    !!errors.length && (
                        <Box mt="4" pl="2" color="red" fontSize="0.875rem">
                            {
                                errors.map((er, ind) => (
                                    <Box mb="2" key={ind}>{er}</Box>
                                ))
                            }
                        </Box>
                    )
                }
                <Box mt="4" pl="2">
                    <Button
                        onClick={onAddFoodsClicked} disabled={checkAddFoodDisabled()}>Update Food(s) {selectedFoods.length} selected</Button>
                </Box>
            </Box>)
        }
    </Box>
}