import React, { useEffect, useState } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { useSubscriptionDetails } from "../../SubscriptionDetailsContext";
import { useGlobal } from "src/global-context/GlobalContext";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useConfirmModal } from "src/hooks/useConfirmModal";

const Subscriber: React.FC = () => {
    const { subscribeDetails, setAvailable } = useSubscriptionDetails();
    const { user } = useGlobal();
    const [loading, setLoading] = useState<boolean>(false);
    const [disableAvailability, setDisableAvailability] = useState<boolean>(true);

    useEffect(() => {
        if (subscribeDetails) {
            if (subscribeDetails.daysDetail.length === subscribeDetails.plan.duration) {
                const existingEmptyItems = subscribeDetails.daysDetail.filter(sd => sd.meals.length === 0);
                setDisableAvailability(existingEmptyItems.length ? true : false);
            } else {
                setDisableAvailability(true);
            }
        } else {
            setDisableAvailability(true);
        }
    }, [subscribeDetails]);

    const { openModal } = useConfirmModal<{}>({
        confirmLabel: "Yes",
        onConfirm: (data?: {}) => {
            setLoading(true);
            setAvailable(() => {
                setLoading(false);
            })
        },
    });

    const onSetAvailableClicked = () => {
        openModal({}, "Confirmation", "Are you sure you want to send the plan days details to the user?");
    }

    return <Box display="flex" alignItems="center" justifyContent="space-between" mb="4">
        <Box display="flex" alignItems="center">
            <Text as="h3" fontSize="1.4rem" mr="2">Subscription Details</Text>
            {
                user?.role === "admin" ? (
                    <Text as={Link} to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.USERS.DETAILS.BY_DATA(subscribeDetails?.endUser._id || "").ABSOLUTE} color="blue.500">{subscribeDetails?.endUser.email}</Text>
                ) : (
                    <Text as="span" color="gray.500">{subscribeDetails?.endUser.email}</Text>
                )
            }
        </Box>
        <Box>
            <Button as={Link} colorScheme="brand" size="md" type="submit" mr="2"
                to={user?.role === "admin" ? ROUTE_CONSTANTS.DASHBOARD.ADMIN.SUBSCRIPTIONS.ROOT.ABSOLUTE : ROUTE_CONSTANTS.DASHBOARD.DOCTORS.MY_SUBSCRIPTIONS.ABSOLUTE}>Back</Button>
            {
                (subscribeDetails?.state === "ordered") && (

                    <Button
                        colorScheme="green"
                        size="md"
                        ml="3"
                        onClick={onSetAvailableClicked}
                        disabled={disableAvailability}
                        isLoading={loading}
                    >
                        Publish
                    </Button>

                )
            }
        </Box>

    </Box>
}

export default Subscriber;