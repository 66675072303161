export enum PlanStatus {
  DRAFT = "draft",
  SUBMITTED = "submit",
  APPROVED = "approve",
  REJECTED = "reject",
}

export interface IPlanChangeStatusPayload {
  _id: string;
  currentStatus: PlanStatus;
}

export interface IPlanRejectionPayload {
  rejectionMessage: PlanStatus;
}
