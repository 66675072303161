import { API_ROUTES } from "src/constants/api-routes";
import {AxiosInstance} from "../index";
import { IForgotPasswordResponse, ILoginResponse, IResetPasswordResponse } from "../types/auth";
import { IAxiosResponse } from "../types/axios-response";

export const authService = {
    login(email:string,password:string,abortSignal:AbortSignal){
        return AxiosInstance.post<IAxiosResponse<ILoginResponse>>(API_ROUTES.AUTH.LOGIN,{
            email,
            password
        },{
            signal:abortSignal
        });
    },
    forgotPassword(email:string){
        return AxiosInstance.post<IForgotPasswordResponse>(API_ROUTES.AUTH.FORGOT_PASSWORD,{
            email
        });
    },
    resetPassword(token:string,password:string){
        return AxiosInstance.post<IResetPasswordResponse>(API_ROUTES.AUTH.RESET_PASSWORD,{
            token,
            password
        });
    }
};