import { INewDayMeal } from "src/api/types/plan-days";

export function getAddMealDefaults(): INewDayMeal[] {
    return [{
        title: "breakfast",
        foods: []
    }, {
        title: "lunch",
        foods: []
    }, {
        title: "dinner",
        foods: []
    }, {
        title: "snack1",
        foods: []
    }, {
        title: "snack2",
        foods: []
    }
    ]

}