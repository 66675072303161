import React, { useState } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { adminPlansService } from "src/api/services/admin-plan";
import { PlanStatus } from "src/api/types/plan-status";
import ConfirmModal from "../../../ConfirmModal";
import { nutritionistPlansService } from "src/api/services/nutritionist-plans";
import { useGlobal } from "src/global-context/GlobalContext";

interface IPlanSubmitBtnProps {
  planId: string;
  isDisable: boolean;
  submitCallBack: (newStatus: PlanStatus) => void;
}

const PlanSubmitBtn: React.FC<IPlanSubmitBtnProps> = ({
  isDisable,
  planId,
  submitCallBack
}) => {
  const { t } = useTranslation();
  const {user} = useGlobal();
  const toast = useToast();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitPlan = async () => {
    setIsLoading(true);
    try {
      const result =
        user?.role === "admin"
          ? await adminPlansService.changeStatus(planId, PlanStatus.SUBMITTED)
          : await nutritionistPlansService.submit(planId);
      toast({
        status: "success",
        description: t("messages.planChangeStatus", {
          status: `${PlanStatus.SUBMITTED}ed`,
        }),
      });
      submitCallBack(result.data.payload.currentStatus);
    } catch (error) {
      setIsLoading(false);
      toast({
        status: "error",
        description: t("messages.someThingHappened"),
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Button
        size="md"
        colorScheme="green"
        onClick={() => setShowModal(true)}
        disabled={isDisable}
        isLoading={isLoading}
        ml="3"
      >
        {t("actions.submit")}
      </Button>
      <ConfirmModal
        isOpen={showModal}
        setIsOpen={() => setShowModal(false)}
        onConfirm={() => {
          setShowModal(false);
          submitPlan();
        }}
        message={`Are you sure change status to submitted?`}
      />
    </>
  );
};

export default PlanSubmitBtn;
