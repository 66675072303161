import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { IPaymentOrderInfoPayload } from "../types/payment";

export const paymentService = {
    getPaymentInfo(isMobile: boolean, orderId: string, controllerSignal: AbortSignal) {
        return AxiosInstance.get<IAxiosResponse<IPaymentOrderInfoPayload>>(`${API_ROUTES.PAYMENT.GET_INFO}?isMobile=${isMobile}&orderId=${orderId}`, {
            signal: controllerSignal
        });
    }
};