import { Link } from "react-router-dom";
import React from "react";
import { IAdminDetailPlanPayload } from "src/api/types/admin-plans";
import InfoField from "src/components/app/InfoField";
import InfoSection from "src/components/app/InfoSection";
import { useGlobal } from "src/global-context/GlobalContext";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useSubscriptionDetails } from "../../SubscriptionDetailsContext";
import { Text } from "@chakra-ui/react";

interface IPlanInfoProps {
  info: IAdminDetailPlanPayload;
}

const PlanInfo: React.FC<IPlanInfoProps> = ({ info }) => {
  const { user } = useGlobal();
  const { subscribeDetails } = useSubscriptionDetails();

  const getPlanDetailsRoute = (): string => {
    if(!subscribeDetails) return "";
    return user?.role === "admin" ? ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.DETAILS.BY_DATA(subscribeDetails.plan._id).ABSOLUTE :
      ROUTE_CONSTANTS.DASHBOARD.DOCTORS.PLANS.DETAILS.BY_DATA(subscribeDetails.plan._id).ABSOLUTE;
  }

  return subscribeDetails ? (<InfoSection title="Plan Info">
    <InfoField label="Code">
      <Text as={Link} to={getPlanDetailsRoute()} color="blue.500">{subscribeDetails.plan.code}</Text>
    </InfoField>
    <InfoField label="Nutritionist">
      {subscribeDetails.plan.nutritionist && (subscribeDetails.plan.nutritionist as any).firstname_en} {` `}
      {subscribeDetails.plan.nutritionist && (subscribeDetails.plan.nutritionist as any).lastname_en}
    </InfoField>
    <InfoField label="Plan Name">{subscribeDetails.plan.title_en}</InfoField>
    <InfoField label="Plan Type">{subscribeDetails.plan.type_en}</InfoField>
    {
      subscribeDetails.plan.type_en === "customized" && <InfoField label="Chat Enabled">{subscribeDetails.plan.chatEnabled ? " Yes":" No"}</InfoField>
    }
    <InfoField label="Daily Carbohydrates">{subscribeDetails.plan.carbohydrate}(GR)</InfoField>
    <InfoField label="Daily Fat">{subscribeDetails.plan.fat}(GR)</InfoField>
    <InfoField label="Daily Protein">{subscribeDetails.plan.protein}(GR)</InfoField>
    <InfoField label="Daily Calories">{subscribeDetails.plan.calories}(KC)</InfoField>
  </InfoSection>) : null
};

export default PlanInfo;
