import { API_ROUTES } from "src/constants/api-routes";
import { IAddSectionData } from "src/pages/dashboard/admin/MobileHomePage/types";
import { AxiosInstance } from "../index";
import {
  ICreateSectionPayload, IMobileSection, ISectionChildItem
} from "../types/admin-mobile-home-page-sections";
import { IAxiosResponse } from "../types/axios-response";

export const adminMobileHomePageSections = {
  getAll(abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IMobileSection[]>>(
      API_ROUTES.PANEL.MOBILE_HOME_PAGE_SECTIONS.ROOT,
      {
        signal: abortSignal,
      }
    );
  },
  create(data: IAddSectionData, abortSignal: AbortSignal) {
    return AxiosInstance.post<IAxiosResponse<ICreateSectionPayload>>(
      API_ROUTES.PANEL.MOBILE_HOME_PAGE_SECTIONS.ROOT,
      {
        ...data,
        active: false,
        order: data.order - 1
      }, {
      signal: abortSignal,
    }
    );
  },

  addItemsToSection(sectionId: string, itemsId: string[]) {
    return AxiosInstance.post<IAxiosResponse<{ items: ISectionChildItem[] }>>(
      API_ROUTES.PANEL.MOBILE_HOME_PAGE_SECTIONS.ADD_ITEMS(sectionId),
      {
        itemsId,
      }
    );
  },

  deleteSection(sectionId: string) {
    return AxiosInstance.delete<IAxiosResponse<{}>>(
      API_ROUTES.PANEL.MOBILE_HOME_PAGE_SECTIONS.GET_ITEM(sectionId)
    );
  },

  editSection(id: string, data: IAddSectionData) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.PANEL.MOBILE_HOME_PAGE_SECTIONS.GET_ITEM(id),
      {
        title_en: data.title_en,
        title_ar: data.title_ar,
        order: data.order - 1
      }
    );
  },

  changeActivation:(sectionId:string,activation:boolean)=>{
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.PANEL.MOBILE_HOME_PAGE_SECTIONS.CHANGE_ACTIVATION(sectionId),
      {
        activation
      }
    );
  }

};