import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Text, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner } from '@chakra-ui/react'
import { useMobilePageContext } from '../../MobileHomePageContext'
import { adminPlansService } from 'src/api/services/admin-plan'
import { adminNutritionistsService } from 'src/api/services/admin-nutritionists'
import { IAdminListPlanPayload } from 'src/api/types/admin-plans'
import { IAdminNutritionistListPayload } from 'src/api/types/admin-nutritionist';
import { mdiCheckboxMarked, mdiCheckboxBlankOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


interface IChildListItem {
    _id: string;
    title: string;
    selected: boolean;
    meta: string;
    disabled: boolean;
    tag?: string;
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    listStyle: "none",
    background: isDragging ? "whitesmoke" : "#fff",
    opacity: isDragging ? "0.5" : "1",
    ...draggableStyle
});

const reorder = (list: any, startIndex: any, endIndex: any): IChildListItem[] => {
    const result: IChildListItem[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const AddChildModal: React.FC = () => {
    const { addingSection, addChildToSection, activeSection, openAddChildModal, setOpenAddChildModal, setActiveSetion } = useMobilePageContext();
    const [abortController] = useState<AbortController>(new AbortController());
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [childItems, setChildItems] = useState<IChildListItem[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    const closeModal = () => {
        setActiveSetion(undefined)
        setOpenAddChildModal(false)
    }


    const checkItemSelected = (id: string) => {
        const result = activeSection?.items.find(item => item._id === id)
        return !!result

    }

    const fetchData = useCallback(async (type: string) => {
        const service = type === "nutritionist" ? adminNutritionistsService : adminPlansService;
        try {
            setLoadingData(true);
            const result = await service.getAll(abortController.signal, "");
            if (result.data.payload.length) {
                const tempChildItems: IChildListItem[] = [];
                result.data.payload.forEach(di => {
                    tempChildItems.push({
                        _id: di._id,
                        selected: checkItemSelected(di._id),
                        title: type === "nutritionist" ? `${(di as IAdminNutritionistListPayload).firstname_en} ${(di as IAdminNutritionistListPayload).lastname_en}` : (di as IAdminListPlanPayload).title_en,
                        meta: type === "nutritionist" ? `${(di as IAdminNutritionistListPayload).email}` : `${(di as IAdminListPlanPayload).code}, ${(di as IAdminListPlanPayload).status}`,
                        tag: type === "nutritionist" ? (di as IAdminNutritionistListPayload).specialty_en : "",
                        disabled: type === "nutritionist" ? !(di as IAdminNutritionistListPayload).active : (di as IAdminListPlanPayload).status !== "approved" || !(di as IAdminListPlanPayload).active,
                    })
                });
                setChildItems(tempChildItems);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [abortController.signal]);

    useEffect(() => {
        if (activeSection) {
            fetchData(activeSection?.type);
        }
        return () => {
            abortController.abort();
        }
    }, [abortController, activeSection, fetchData]);

    const onCancelClicked = () => {
        closeModal();
    }

    const onListItemClicked = (sender: IChildListItem) => {
        const tempItems = [...childItems];
        const itemIndex = tempItems.findIndex(item => item._id === sender._id);
        tempItems[itemIndex].selected = !tempItems[itemIndex].selected;
        setChildItems([...tempItems]);
    }

    const onAddItemsClicked = () => {
        const selectedItems = childItems.filter(ci => ci.selected).map(i => i._id);
        addChildToSection(selectedItems)
    }

    const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }

    // --------------------------------------------------


    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            childItems,
            result.source.index,
            result.destination.index
        );

        setChildItems(items);
    }

    return <><Modal motionPreset="slideInBottom" onClose={closeModal} isOpen={openAddChildModal} size="lg">
        <ModalOverlay />
        <ModalContent pb={5}>
            <ModalHeader>Update {activeSection?.type === "nutritionist" ? "Nutritionists" : "Plans"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box >
                    <Box mb="4">
                        <Input type="search" placeholder="Search" value={searchValue} onChange={onSearchChanged} />
                    </Box>
                    <Box maxHeight="260px" overflowY="auto" mb="2">
                        {
                            loadingData && (<Box display="flex" justifyContent="center" alignItems="center" p="8">
                                <Spinner size='lg' />
                            </Box>)
                        }
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {
                                    (provided, snapshot) => (
                                        <ul
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {
                                                childItems.filter(fi => (fi.title.toLowerCase()).includes(searchValue.toLowerCase()))
                                                    .map((ci, ind) => (
                                                        <Draggable key={ci._id} draggableId={ci._id} index={ind}>
                                                            {
                                                                (provided, snapshot) => (
                                                                    <li
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}>
                                                                        <Box onClick={() => { onListItemClicked(ci) }} p="2" userSelect="none" cursor="pointer"
                                                                            display="flex" opacity={ci.disabled ? "0.5" : "1"}>
                                                                            <Box>
                                                                                <Icon path={ci.selected ? mdiCheckboxMarked : mdiCheckboxBlankOutline} size="24px" color="#55aab1" />
                                                                            </Box>
                                                                            <Text ml="2">{ci.title}({ci.meta}) {ci.tag ? ` - ${ci.tag}` : ""}</Text>
                                                                        </Box>
                                                                    </li>
                                                                )
                                                            }
                                                        </Draggable>
                                                    ))
                                            }
                                        </ul>
                                    )
                                }
                            </Droppable>
                        </DragDropContext>
                    </Box>
                </Box>
            </ModalBody>
            <Box pl="4" pr="4" textAlign="right">
                <Button onClick={onCancelClicked} colorScheme="orange">Cancel</Button>
                <Button isLoading={addingSection} ml="3" onClick={onAddItemsClicked}>Submit</Button>
            </Box>
        </ModalContent>
    </Modal></>
}

export default AddChildModal