import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { IWeightTrack } from "src/api/types/admin-user";
import dayjs from "dayjs";
ChartJS.register(...registerables);

interface IWeightLogs {
  info: IWeightTrack[];
}

const WeightLogs: React.FC<IWeightLogs> = ({ info }) => {
  const [data, setData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    let tempData: number[] = [];
    let tempLabels: string[] = [];
    if (info && info.length > 0) {
      info.map((i) => tempData.push(i.weight));
      info.map((i) => tempLabels.push(dayjs(i.date).format("YYYY-MM-DD")));
      setData(tempData);
      setLabels(tempLabels);
    }
  }, [info]);

  return (
    <Box>
      <Line
      height="50px"
        data={{
          labels,
          datasets: [
            {
              label: "Weight Log",
              data,
              borderColor: "rgb(75, 192, 192)",
              pointBorderWidth:12,
              pointBorderColor:"rgba(104,193,192,0.5)",
              pointBackgroundColor:"rgba(104,193,192,0.5)",
              
            },
          ],
        }}
        options={{
          
          scales:{
            y:{
              beginAtZero:false,
            }
          },
          responsive:true,
          aspectRatio:5
        }}
      />
    </Box>
  );
};

export default WeightLogs;
