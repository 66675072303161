import React from "react";
import InfoField from "src/components/app/InfoField";
import InfoSection from "src/components/app/InfoSection";
import { useSubscriptionDetails } from "../../SubscriptionDetailsContext";

const Answers: React.FC = () => {
  const { subscribeDetails } = useSubscriptionDetails();
  
  const getFoodAllergies = ()=>{
    if(subscribeDetails?.userAnswers){
      const items = [...(subscribeDetails?.userAnswers.predefinedFoodAllergies || []),...(subscribeDetails?.userAnswers.myFoodAllergies || [])]
      return items.join(", ");
    }
  }

  const getDislkedFoods = ()=>{
    if(subscribeDetails?.userAnswers){
      const items = [...(subscribeDetails?.userAnswers.predefinedDislikeFoods || []),...(subscribeDetails?.userAnswers.myDislikeFoods || [])]
      return items.join(", ");
    }
  }

  return subscribeDetails ? (
    <InfoSection title="User's Answer">
      {subscribeDetails.userAnswers.gender && <InfoField label="Gender">{subscribeDetails.userAnswers.gender}</InfoField>}
      {subscribeDetails.userAnswers.weightValue && (
        <InfoField label="Weight">
          {`${subscribeDetails.userAnswers.weightValue} ${subscribeDetails.userAnswers.weightUnit}`}
        </InfoField>
      )}
      {subscribeDetails.userAnswers.heightValue && (
        <InfoField label="Height">
          {`${subscribeDetails.userAnswers.heightValue} ${subscribeDetails.userAnswers.heightUnit}`}
        </InfoField>
      )}
      {subscribeDetails.userAnswers.targetWeightValue && (
        <InfoField label="Target Weight">
          {`${subscribeDetails.userAnswers.targetWeightValue} ${subscribeDetails.userAnswers.targetWeightUnit}`}
        </InfoField>
      )}
      {subscribeDetails.userAnswers.age && <InfoField label="Age">{subscribeDetails.userAnswers.age}</InfoField>}
      {subscribeDetails.userAnswers.activityLevel && (
        <InfoField label="Activity Level">{subscribeDetails.userAnswers.activityLevel}</InfoField>
      )}
      {
        subscribeDetails.plan.type_en === "customized" && (
          <>
            <InfoField label="Has Medical Condition">{subscribeDetails.userAnswers.hasMedicalCondition ? "Yes" : "No"}</InfoField>
            {
              subscribeDetails.userAnswers.medicalConditionDescription &&
              <InfoField label="Medical Condition">{subscribeDetails.userAnswers.medicalConditionDescription}</InfoField>
            }
            <InfoField label="Food Allergies">{getFoodAllergies()}</InfoField>
            <InfoField label="Dislike Food">{getDislkedFoods()}</InfoField>
          </>
        )
      }
    </InfoSection>
  ) : null
};

export default Answers;
