import { Center, Text ,Box} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import Icon from "@mdi/react";
import { mdiFingerprintOff } from '@mdi/js';

const PermissionDenied: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.permissionDenied"));
    return (
        <Center display="flex" flexDirection="column">
            <Box color="red.500">
            <Icon path={mdiFingerprintOff} size="48px" />
            </Box>
            <Text as="h3" fontSize="2xl" mt="4" fontWeight="bold">
                {
                    t("messages.permissionDenied")
                }
            </Text>
            <Text as="p" mt="4">
                {
                    t("messages.permissionDeniedError")
                }
            </Text>
        </Center>
    );
}

export default PermissionDenied;