/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { adminNutritionistsService } from "src/api/services/admin-nutritionists";
import { adminPlansService } from "src/api/services/admin-plan";
import { IAdminDetailPlanPayload } from "src/api/types/admin-plans";
import ContentView from "src/components/app/ContentView";
import SelectOption from "src/components/app/FormElements/Select";
import FormRow from "src/components/app/FormRow";
import ImageUpload from "src/components/app/ImageUpload";
import { useTitle } from "src/hooks/useTitle";
import { newPlanValidationSchema } from "src/validation-schemas/plan";
import { INewPlanForm, PlanFormFields } from "./type";

const NewPlan: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  useTitle(t("pageTitles.newPlan"));
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [thumbnail_enFlag, setThumbnail_enFlag] = useState<boolean>(false);
  const [thumbnail_arFlag, setThumbnail_arFlag] = useState<boolean>(false);
  const [detailIsLoading, setDetailIsLoading] = useState<boolean>(false);
  const [planDetails, setPlanDetails] = useState<
    IAdminDetailPlanPayload | undefined
  >();
  const [getPlanController] = useState<AbortController>(new AbortController());
  const [getNutritionistsController] = useState<AbortController>(new AbortController());
  const [nutritionists, setNutritionists] = useState<{ label: string, value: string }[]>([])

  const typeOptions = [
    { value: "normal", label: "normal" },
    { value: "customized", label: "customized" },
  ];

  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
  } = useForm<INewPlanForm>({
    resolver: yupResolver(newPlanValidationSchema(t, "admin")),
    mode: "all",
    defaultValues: {

    },
  });

  const getNutritionists = useCallback(async () => {
    try {
      const result = await adminNutritionistsService.getAll(getNutritionistsController.signal, "")
      const data = result.data.payload.map(n => ({
        label: `${n.firstname_en} ${n.lastname_en}`,
        value: n._id
      }))
      setNutritionists(data);
    } catch (err) {
      console.log(err);
    }
  }, [getNutritionistsController]);


  const getDetail = useCallback(async () => {
    try {
      setDetailIsLoading(true);
      if (params.id) {
        const result = await adminPlansService.getDetail(
          params.id,
          getPlanController.signal
        );
        setPlanDetails(result.data.payload);
        setType(result.data.payload.type_en);
        const pData = { ...result.data.payload }


        const resultN = await adminNutritionistsService.getAll(getNutritionistsController.signal, "")
        const data = resultN.data.payload.map(n => ({
          label: `${n.firstname_en} ${n.lastname_en}`,
          value: n._id
        }))
        setNutritionists(data);
        const activeNutritionistIndex = data.findIndex(d => d.value === (typeof pData.nutritionist === "string" ? pData.nutritionist : pData.nutritionist._id))
        reset({
          type_en: pData
            ? { value: pData.type_en, label: pData.type_en }
            : null,
          thumbnail_en: pData.thumbnail_en,
          thumbnail_ar: pData.thumbnail_ar,
          nutritionist: data[activeNutritionistIndex],
          briefs_en: {
            first: pData.type_en === "normal" ? pData.briefs_ar[0] : (pData.chatEnabled ? "With Chat" : "----"),
            second: pData.briefs_en[1],
            third: pData.briefs_en[2],
          },
          briefs_ar: {
            first: pData.type_en === "normal" ? pData.briefs_ar[0] : (pData.chatEnabled ? "مع الدردشة" : "----"),
            second: pData.briefs_ar[1],
            third: pData.briefs_ar[2],
          },
          title_en: pData.title_en,
          title_ar: pData.title_ar,
          description_en: pData.description_en,
          description_ar: pData.description_ar,
          contains_en: pData.contains_en,
          contains_ar: pData.contains_ar,
          duration: pData.duration,
          carbohydrate: pData.carbohydrate,
          fat: pData.fat,
          protein: pData.protein,
          calories: pData.calories,
          originalPrice: pData.originalPrice,
          discountedPrice: pData.discountedPrice,
          currency: "KD",
          chatEnabled: pData.chatEnabled,
        })
      }
    } catch (err) {
      setDetailIsLoading(false);
    } finally {
      setDetailIsLoading(false);
    }
  }, [params, getPlanController.signal]);


  const onThumbnailENChange = (newData: string) => {
    setThumbnail_enFlag(true);
    setValue("thumbnail_en", newData);
  };

  const onThumbnailARChange = (newData: string) => {
    setThumbnail_arFlag(true);
    setValue("thumbnail_ar", newData);
  };

  useEffect(() => {
    if (!params.id) {
      getNutritionists();
    }
  }, [getNutritionists, params.id]);

  useEffect(() => {
    if (params.id) {
      getDetail();
      return () => {
        getPlanController.abort();
      };
    }
  }, [getDetail, getPlanController, params.id]);



  const editPlan = async (data: INewPlanForm) => {
    let briefs_ar: string[] = [];
    let briefs_en: string[] = [];
    let newData: any = params.id ? {} : data;
    Object.keys(dirtyFields).forEach(function (key) {
      const _key = key as PlanFormFields;
      newData[key] = data[_key];
      if (key === "briefs_ar") {
        Object.values(data.briefs_ar).forEach(function (val) {
          if (val)
            briefs_ar.push(val);
        });
        newData.briefs_ar = briefs_ar;
      }
      if (key === "briefs_en") {
        Object.values(data.briefs_en).forEach(function (val) {
          if (val)
            briefs_en.push(val);
        });
        newData.briefs_en = briefs_en;
      }
      if (key === "type_en") {
        newData.type_en = data.type_en?.value;
      }

      if (key === "chatEnabled") {
        newData.chatEnabled = data.chatEnabled;
      }
      if (
        newData.type_en &&
        newData.type_en === "normal" &&
        newData.chatEnabled
      ) {
        delete newData.chatEnabled;
      } else if (newData.type_en && newData.type_en === "customized") {
        newData.chatEnabled = data.chatEnabled ?? false;
      }

      if (key === "nutritionist") {
        newData.nutritionist = data.nutritionist?.value;
      }
    });

    if (thumbnail_enFlag) {
      newData.thumbnail_en = data.thumbnail_en;
    }
    if (thumbnail_arFlag) {
      newData.thumbnail_ar = data.thumbnail_ar;
    }

    try {
      await adminPlansService.update(newData, params.id!);
      setLoading(false);
      toast({
        status: "success",
        description: `${t("messages.planUpdated")}.
        ${t("messages.changeStatusAfterEdit")}`,
      });
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.DETAILS.BY_DATA(params.id!)
          .ABSOLUTE
      );
    } catch (error) {
      setLoading(false);
      toast({
        status: "error",
        description: t("messages.someThingHappened"),
      });
    }
  };

  const insertPlan = async (data: INewPlanForm) => {
    let briefs_ar: string[] = [];
    let briefs_en: string[] = [];
    let newData: any = params.id ? {} : data;
    Object.values(data.briefs_ar).forEach(function (val) {
      if (val)
        briefs_ar.push(val);
    });
    Object.values(data.briefs_en).forEach(function (val) {
      if (val)
        briefs_en.push(val);
    });
    newData.type_en = data.type_en?.value;
    newData.nutritionist = data.nutritionist?.value;
    newData.briefs_ar = briefs_ar;
    newData.briefs_en = briefs_en;
    if (!data.discountedPrice) {
      delete newData.discountedPrice;
    }
    if (newData.type_en === "normal") {
      delete newData.chatEnabled;
    }

    try {
      const result = await adminPlansService.create(newData);
      setLoading(false);
      toast({
        status: "success",
        description: t("messages.planCreated"),
      });
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.DETAILS.BY_DATA(
          result.data.payload._id
        ).ABSOLUTE
      );
    } catch (error) {
      setLoading(false);
      toast({
        status: "error",
        description: t("messages.someThingHappened"),
      });
    }
  };

  const onSubmit = async (data: INewPlanForm) => {
    setLoading(true);
    if (params.id) {
      editPlan(data);
    } else {
      insertPlan(data);
    }
  };

  return (
    <ContentView>
      {detailIsLoading ? (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      ) : (
        <>
          <Box
            mb="6"
            pb="2"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <Box>
              <Text as="h3" fontSize="3xl">
                {t(params.id ? "titles.editPlan" : "titles.newPlan")}
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Button
                as={Link}
                colorScheme="brand"
                size="md"
                type="submit"
                to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.ROOT.ABSOLUTE}
              >
                {t("actions.backToList")}
              </Button>
            </Box>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormRow
              bidirectional
              leftContent={
                <Box>
                  <FormControl isRequired>
                    <FormLabel
                      textTransform={"capitalize"}
                      htmlFor="thumbnail_en"
                    >
                      {t("fields.photo")}
                    </FormLabel>
                    <ImageUpload
                      onImageChanged={onThumbnailENChange}
                      upload
                      withCrop
                      aspectRatio={16 / 9}
                      width={500}
                      height={200}
                      src={planDetails?.thumbnail_en || ""}
                    />
                    {!!errors.thumbnail_en && (
                      <Box
                        mb="4"
                        color="red.500"
                        fontSize="0.875rem"
                        textTransform={"capitalize"}
                      >
                        {errors.thumbnail_en.message}
                      </Box>
                    )}
                  </FormControl>
                </Box>
              }
              rightContent={
                <Box>
                  <FormControl isRequired>
                    <FormLabel
                      textTransform={"capitalize"}
                      htmlFor="thumbnail_ar"
                    >
                      {t("fields.photo_ar")}
                    </FormLabel>
                    <ImageUpload
                      onImageChanged={onThumbnailARChange}
                      upload
                      withCrop
                      aspectRatio={16 / 9}
                      width={500}
                      height={200}
                      src={planDetails?.thumbnail_ar || ""}
                    />
                    {!!errors.thumbnail_ar && (
                      <Box
                        mb="4"
                        color="red.500"
                        fontSize="0.875rem"
                        textTransform={"capitalize"}
                      >
                        {errors.thumbnail_ar.message}
                      </Box>
                    )}
                  </FormControl>
                </Box>
              }
            />
            <Box mb={3} fontSize="0.875rem" mt={-8}>
              <p>Image Types: *.jpg, *.jpeg, *.png</p>
              <p>Maximum Size: 5 MB</p>
            </Box>
            <FormRow
              leftContent={
                <SelectOption
                  required
                  control={control}
                  error={errors.type_en}
                  name={"type_en"}
                  label={t("fields.type")}
                  options={typeOptions}
                  defaultValue={getValues("type_en")}
                  onCustomChange={(e) => setType(e.value)}
                />
              }
              rightContent={
                type &&
                type !== "normal" && (
                  <FormControl
                    display="flex"
                    alignItems="center"
                    isRequired
                    opacity={0}
                    isDisabled
                    isInvalid={!!errors.chatEnabled}
                    height="100%"
                  >
                    <FormLabel htmlFor="chatEnabled" mb="3" mt="auto">
                      {t("fields.chatEnabled")}?
                    </FormLabel>
                    <Checkbox {...register("chatEnabled")} mb="4" mt="auto" />
                    <FormErrorMessage textTransform={"capitalize"}>
                      {!!errors.chatEnabled && errors.chatEnabled.message}
                    </FormErrorMessage>
                  </FormControl>
                )
              }
            />
            <FormRow
              bidirectional
              leftContent={
                <FormControl isInvalid={!!errors.title_en} isRequired>
                  <FormLabel textTransform={"capitalize"} htmlFor="title_en">
                    {t("fields.title")}
                  </FormLabel>
                  <Input
                    id="title_en"
                    type="text"
                    size="lg"
                    {...register("title_en")}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.title_en && errors.title_en.message}
                  </FormErrorMessage>
                </FormControl>
              }
              rightContent={
                <FormControl isInvalid={!!errors.title_ar} isRequired>
                  <FormLabel textTransform={"capitalize"} htmlFor="title_ar">
                    {t("fields.title_ar")}
                  </FormLabel>
                  <Input
                    id="title_ar"
                    type="text"
                    size="lg"
                    {...register("title_ar")}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.title_ar && errors.title_ar.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <FormRow
              leftContent={
                <SelectOption
                  required
                  name="nutritionist"
                  error={errors.nutritionist}
                  label={t("fields.nutritionist")}
                  control={control}
                  options={nutritionists}
                  defaultValue={getValues("nutritionist")}
                  placeholder={"search nutritionist's name here"}
                />
              }
            />
            <FormRow
              bidirectional
              leftContent={
                <FormControl isInvalid={!!errors.description_en} isRequired>
                  <FormLabel textTransform={"capitalize"} htmlFor="description">
                    {t("fields.description")}
                  </FormLabel>
                  <Textarea
                    id="description_en"
                    size="lg"
                    resize="none"
                    {...register("description_en")}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.description_en && errors.description_en.message}
                  </FormErrorMessage>
                </FormControl>
              }
              rightContent={
                <FormControl isInvalid={!!errors.description_ar} isRequired>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="description_ar"
                  >
                    {t("fields.description_ar")}
                  </FormLabel>
                  <Textarea
                    id="description_ar"
                    size="lg"
                    resize="none"
                    {...register("description_ar")}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.description_ar && errors.description_ar.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <FormRow
              bidirectional
              leftContent={
                <FormControl isInvalid={!!errors.briefs_en?.first} isRequired>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="briefs_en.first"
                  >
                    {t("fields.first")} {t("fields.brief")}
                  </FormLabel>
                  <Textarea
                    id={`briefs_en.first`}
                    size="lg"
                    resize="none"
                    {...register(`briefs_en.first`)}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.briefs_en?.first &&
                      errors.briefs_en.first.message}
                  </FormErrorMessage>
                </FormControl>
              }
              rightContent={
                <FormControl isInvalid={!!errors.briefs_ar?.first} isRequired>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="briefs_ar.first"
                  >
                    {t("fields.first_ar")} {t("fields.brief_ar")}
                  </FormLabel>
                  <Textarea
                    id="briefs_ar.first"
                    size="lg"
                    resize="none"
                    {...register("briefs_ar.first")}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.briefs_ar?.first &&
                      errors.briefs_ar.first.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <FormRow
              bidirectional
              leftContent={
                <FormControl isInvalid={!!errors.briefs_en?.second}>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="briefs_en.second"
                  >
                    {`${t("fields.second")} ${t("fields.brief")}`}
                  </FormLabel>
                  <Textarea
                    id={`briefs_en.second`}
                    size="lg"
                    resize="none"
                    {...register(`briefs_en.second`)}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.briefs_en?.second &&
                      errors.briefs_en.second.message}
                  </FormErrorMessage>
                </FormControl>
              }
              rightContent={
                <FormControl isInvalid={!!errors.briefs_ar?.second}>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="briefs_ar.second"
                  >
                    {`${t("fields.second_ar")} ${t("fields.brief")}`}
                  </FormLabel>
                  <Textarea
                    id="briefs_ar.second"
                    size="lg"
                    resize="none"
                    {...register("briefs_ar.second")}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.briefs_ar?.second &&
                      errors.briefs_ar.second.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <FormRow
              bidirectional
              leftContent={
                <FormControl isInvalid={!!errors.briefs_en?.third}>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="briefs_en.third"
                  >
                    {`${t("fields.third")} ${t("fields.brief")}`}
                  </FormLabel>
                  <Textarea
                    id={`briefs_en.third`}
                    size="lg"
                    resize="none"
                    {...register(`briefs_en.third`)}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.briefs_en?.third &&
                      errors.briefs_en.third.message}
                  </FormErrorMessage>
                </FormControl>
                
              }
              rightContent={
                <FormControl isInvalid={!!errors.briefs_ar?.third}>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="briefs_ar.third"
                  >
                    {`${t("fields.third_ar")} ${t("fields.brief")}`}
                  </FormLabel>
                  <Textarea
                    id="briefs_ar.third"
                    size="lg"
                    resize="none"
                    {...register("briefs_ar.third")}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.briefs_ar?.third &&
                      errors.briefs_ar.third.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <FormRow
              bidirectional
              leftContent={
                <FormControl isInvalid={!!errors.contains_en} isRequired>
                  <FormLabel textTransform={"capitalize"} htmlFor="contains_en">
                    {t("fields.contains")}
                  </FormLabel>
                  <Textarea
                    id="contains_en"
                    size="lg"
                    resize="none"
                    {...register("contains_en")}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.contains_en && errors.contains_en.message}
                  </FormErrorMessage>
                </FormControl>
              }
              rightContent={
                <FormControl isInvalid={!!errors.contains_ar} isRequired>
                  <FormLabel textTransform={"capitalize"} htmlFor="contains_ar">
                    {t("fields.contains_ar")}
                  </FormLabel>
                  <Textarea
                    id="contains_ar"
                    size="lg"
                    resize="none"
                    {...register("contains_ar")}
                  />
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.contains_ar && errors.contains_ar.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <FormRow
              leftContent={
                <FormControl isInvalid={!!errors.duration} isRequired>
                  <FormLabel textTransform={"capitalize"} htmlFor="duration">
                    {t("fields.duration")} ({t("fields.days")})
                  </FormLabel>
                  <NumberInput min={0} size="lg">
                    <NumberInputField id="duration" {...register("duration")} />
                  </NumberInput>
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.duration && errors.duration.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <FormRow
              leftContent={
                <FormControl isInvalid={!!errors.fat} isRequired>
                  <FormLabel textTransform={"capitalize"} htmlFor="fat">
                    {t("fields.daily")} {t("fields.fat")} (GR)
                  </FormLabel>
                  <NumberInput min={0} size="lg">
                    <NumberInputField id="fat" {...register("fat")} />
                  </NumberInput>
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.fat && errors.fat.message}
                  </FormErrorMessage>
                </FormControl>
              }
              rightContent={
                <FormControl isInvalid={!!errors.carbohydrate} isRequired>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="carbohydrate"
                  >
                    {t("fields.daily")} {t("fields.carbohydrate")} (GR)
                  </FormLabel>
                  <NumberInput min={0} size="lg">
                    <NumberInputField
                      id="carbohydrate"
                      {...register("carbohydrate")}
                    />
                  </NumberInput>
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.carbohydrate && errors.carbohydrate.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <FormRow
              leftContent={
                <FormControl isInvalid={!!errors.protein} isRequired>
                  <FormLabel textTransform={"capitalize"} htmlFor="protein">
                    {t("fields.daily")} {t("fields.protein")} (GR)
                  </FormLabel>
                  <NumberInput min={0} size="lg">
                    <NumberInputField id="protein" {...register("protein")} />
                  </NumberInput>
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.protein && errors.protein.message}
                  </FormErrorMessage>
                </FormControl>
              }
              rightContent={
                <FormControl isInvalid={!!errors.calories} isRequired>
                  <FormLabel textTransform={"capitalize"} htmlFor="calories">
                    {t("fields.daily")} {t("fields.calories")} (KC)
                  </FormLabel>
                  <NumberInput min={0} size="lg">
                    <NumberInputField id="calories" {...register("calories")} />
                  </NumberInput>
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.calories && errors.calories.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <FormRow
              leftContent={
                <FormControl isInvalid={!!errors.originalPrice} isRequired>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="originalPrice"
                  >
                    {t("fields.price")} (KD)
                  </FormLabel>
                  <NumberInput min={0} size="lg">
                    <NumberInputField
                      id="originalPrice"
                      {...register("originalPrice")}
                    />
                  </NumberInput>
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.originalPrice && errors.originalPrice.message}
                  </FormErrorMessage>
                </FormControl>
              }
              rightContent={
                <FormControl isInvalid={!!errors.discountedPrice}>
                  <FormLabel
                    textTransform={"capitalize"}
                    htmlFor="discountedPrice"
                  >
                    {t("fields.discountedPrice")} (KD)
                  </FormLabel>
                  <NumberInput min={0} size="lg">
                    <NumberInputField
                      id="discountedPrice"
                      {...register("discountedPrice")}
                    />
                  </NumberInput>
                  <FormErrorMessage textTransform={"capitalize"}>
                    {!!errors.discountedPrice && errors.discountedPrice.message}
                  </FormErrorMessage>
                </FormControl>
              }
            />
            <Box textAlign="right">
              <Button
                as={Link}
                to={ROUTE_CONSTANTS.DASHBOARD.ADMIN.PLANS.ROOT.ABSOLUTE}
                size="lg"
                colorScheme="orange"
                mr="4"
              >
                {t("actions.cancel")}
              </Button>
              <Button
                colorScheme="brand"
                size="lg"
                type="submit"
                isLoading={loading}
              >
                {t("actions.save")}
              </Button>
            </Box>
          </form>
        </>
      )}
    </ContentView>
  );
};

export default NewPlan;
