import { IMainContext } from "./types";
import React, {  useState,useEffect,useCallback } from "react";
import { contextFactory } from "src/utils/context-factory";
import { IUserProfile } from "src/types/users";
import { getAuthorization } from "src/utils/get-authorization";
import { useNavigate ,useLocation} from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { panelUserService } from "src/api/services/panel-user";
import { Box, Spinner } from "@chakra-ui/react";
import { HTTP_STATUS_CODES } from "src/constants/http-status-codes";
import { AxiosResponse } from "axios";


export const [GlobalContext, useGlobal] =
    contextFactory<IMainContext>("GlobalContext", "useGlobal");

const getProfileController = new AbortController();

export const GlobalProvider: React.FC = ({ children }) => {
    const [user,setUser] = useState<IUserProfile | undefined>();
    const [loading,setLoading] = useState<boolean>(false);
    const { pathname } = useLocation();
    
    const navigate = useNavigate();
    const getUserProfile = useCallback(async () => {
        
        try {
            if (getAuthorization()) {
                setLoading(true);
                const result = await panelUserService.getProfile(getProfileController.signal);
                setUser(result.data.payload);
            } else if (!user) {
                if(pathname.includes("dashboard")){
                    navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
                }
            }

        } catch (err) {
            const error = err as AxiosResponse;
            if(error.status && error.status === HTTP_STATUS_CODES.UN_AUTHORIZED && pathname.includes("dashboard")){
                navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
            }
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getUserProfile()
        return () => {
            getProfileController.abort();
        }
    }, [getUserProfile]);

    return (
        <GlobalContext.Provider value={{ user,setUser }}>
            {
                loading ? (
                    <Box position="absolute" width="100vw" height="100vh" top="0" left="0"
                        display="flex" alignItems="center" justifyContent="center">
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </Box>
                ) : children
            }
        </GlobalContext.Provider>
    );
}