import { Link, Text } from "@chakra-ui/react";
import CheckoutContainer from "../CheckoutContainer";
import CheckoutContent from "../CheckoutContent";

interface ICheckoutFailureProps {
    tryAgainUrl: string;
}

const CheckoutFailure: React.FC<ICheckoutFailureProps> = ({ tryAgainUrl }) => {
    return <CheckoutContainer
        topChild={
            <CheckoutContent>
                <img src={process.env.PUBLIC_URL + '/sorry-icon.png'} alt="failure payment" style={{ maxWidth: "160px", margin: "0 auto 30px" }} />
                <Text fontSize="1em" fontWeight="500" marginBottom="13px">Sorry!</Text>
                <Text fontSize="0.47em" color="#525252" marginBottom="13px">Payment failed</Text>
                <Text fontSize="0.53em" color="#525252">You can try again to finalize your Payment.</Text>
            </CheckoutContent>
        }
        bottomChild={
            <>
                <Link href={tryAgainUrl}
                    color="#fff"
                    bgColor="#0AA9B1"
                    padding="12px"
                    borderRadius="12px"
                    textAlign="center"
                    textDecor="none"
                    display="block"
                    _hover={{ textDecoration: "none" }}>Try Again</Link>
                <Link href="elevateApp://home"
                    mt="12px"
                    color="#0AA9B1"
                    border="1px solid #0AA9B1"
                    padding="12px"
                    borderRadius="12px"
                    textAlign="center"
                    textDecor="none"
                    display="block"
                    _hover={{ textDecoration: "none" }}>Back To Home</Link>
            </>
        }
    />;
}

export default CheckoutFailure;