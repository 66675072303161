import React from "react";
import { PlanDaysProvider } from "./PlanDaysContext";
import { IPlanDaysProps } from "./types.d";
import "./styles.css";
import Days from "./components/Days";

const PlanDaysContent: React.FC = () => {
    return <>
            <Days />
    </>
}

const PlanDays: React.FC<IPlanDaysProps> = (props) => {
    return (<PlanDaysProvider {...props}>
        <PlanDaysContent />
    </PlanDaysProvider>);
}


export default PlanDays;