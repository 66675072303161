import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Button,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import Icon from "@mdi/react";
import { mdiMagnify, mdiTune } from "@mdi/js";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { Link } from "react-router-dom";
import { ISubscriptionListPayload } from "src/api/types/admin-subscriptions";
import { adminSubscriptionService } from "src/api/services/admin-subscriptions";
import EmptyResult from "src/components/app/EmptyResult";
import { useSearch } from "src/hooks/useSearch";
import { planTypeFilters, statusFilters } from "src/constants/filters";
import Pagination from "src/components/app/Pagination";

const Subscriptions: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.planSubscriptions"));

  const {
    searchQuery,
    setSearchQuery,
    callApiController,
    searchResult: subscribes,
    resultMeta: subscriptionsMeta,
    loading,
    filters,
    setFilters
  } = useSearch<ISubscriptionListPayload[]>({
    searchApi: adminSubscriptionService.getAll,
    filterItems: ["planType", "state", "pageNumber","pageSize"]
  });
  const [searchValue, setSearchValue] = useState<string>(searchQuery || "");
  const [searchFilters, setSearchFilters] = useState<Record<string, string>>(filters);

  useEffect(() => {
    setSearchFilters(filters);
  }, [filters])

  useEffect(() => {
    setSearchValue(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    return () => {
      callApiController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    setSearchQuery(searchValue);
  };

  const onSearchQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  const onTypeChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({ ...filters, "planType": event.target.value,"pageNumber":"1" });
  }

  const onStateChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({ ...filters, "state": event.target.value,"pageNumber":"1" });
  }

  const changePage = (page: number) => {
    setFilters({ ...filters, "pageNumber": `${page}` });
  }

  const onPageSizeChanged = (value:number)=>{
    setFilters({ ...filters, "pageSize": `${value}`,"pageNumber": `1` });
  }

  return (
    <Box>
      <Text as="h3" fontSize="3xl">
        {t("titles.planSubscriptions")}
      </Text>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray"
              children={<Icon path={mdiMagnify} size="24px" />}
            />
            <Input
              type="search"
              placeholder={t("actions.search")}
              value={searchValue}
              onChange={onSearchQueryChanged}
              backgroundColor="white"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearch();
                }
              }}
            />
            <Button ml="3" onClick={() => onSearch()} isLoading={loading}>
              {t("actions.search")}
            </Button>
          </InputGroup>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton
              aria-label="Test"
              icon={<Icon path={mdiTune} size="28px" />}
              color="gray.700"
              bg="transparent"
            />
          </Box>
        </Box>
      </Box>
      <Box bg="#fff" boxShadow="lg" mb="3" p="3" borderRadius="8px">
        <Text as="h3" fontSize="1.2rem" mb="2">Filters</Text>
        <Box display="flex">
          <Box pr="3">
            <FormLabel htmlFor="planType">Plan Type</FormLabel>
            <Select minWidth="200px" id="planType" onChange={onTypeChanged} value={searchFilters.planType}>
              {
                planTypeFilters.map(pt => (<option value={pt.value} key={pt.value}>{pt.label}</option>))
              }
            </Select>
          </Box>
          <Box>
            <FormLabel htmlFor="planType">Subscription State</FormLabel>
            <Select minWidth="200px" id="state" onChange={onStateChanged} value={searchFilters.state}>
              {
                statusFilters.map(sf => (<option value={sf.value} key={sf.value}>{sf.label}</option>))
              }
            </Select>
          </Box>
        </Box>
      </Box>
      <Box height="5px" mb="1">
        {loading && <HorizontalLoading />}
      </Box>
      <Table
        variant="simple"
        colorScheme="blackAlpha"
        size="md"
        boxShadow="md"
        backgroundColor="white"
        borderRadius="md"
      >
        <Thead>
          <Tr>
            <Th></Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.code")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.planName")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.nutritionist")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.planType")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.userEmail")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.duration")}
            </Th>
            <Th px={2} textAlign={"center"}>
              {t("fields.state")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {subscribes.map((n, ind) => (
            <Tr key={n._id}>
              <Td>
                <Text as="span" color="gray.400">{((subscriptionsMeta.pageNumber - 1) * subscriptionsMeta.pageSize) + (ind + 1)}</Text>
              </Td>
              <Td px={2} textAlign={"center"} color="blue.500">
                <Link
                  to={
                    ROUTE_CONSTANTS.DASHBOARD.ADMIN.SUBSCRIPTIONS.DETAILS.BY_DATA(
                      n._id
                    ).ABSOLUTE
                  }
                >
                  {n.code ?? "---"}
                </Link>
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.plan?.title_en ?? "---"}
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.nutritionist
                  ? `${(n.nutritionist as any).firstname_en} ${(n.nutritionist as any).lastname_en
                  }`
                  : "---"}
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.plan?.type_en ?? "---"}
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.endUser ? n.endUser.email : "---"}
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.plan?.duration ?? "---"}
              </Td>
              <Td px={2} textAlign={"center"}>
                {n.state ?? "---"}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {!subscribes.length && !loading && <EmptyResult />}
      <Pagination pageSize={subscriptionsMeta.pageSize} 
        defaultActivePage={parseInt(`${subscriptionsMeta.pageNumber}`)} totalRecordsCount={subscriptionsMeta.totalCount} 
        onPageChanged={changePage} 
        onPageSizeChanged={onPageSizeChanged}
        entity="subscription"/>
    </Box>
  );
};

export default Subscriptions;
