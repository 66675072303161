import { Box, Divider, Heading } from "@chakra-ui/react";
import React from "react";


interface IFormBoxProps{
    title:string;
}

const FormBox:React.FC<IFormBoxProps> = ({title,children})=>{
    return (
    <Box>
        <Heading as="h1" mb="4" size="lg">{title}</Heading>
        <Divider />
        <Box mt="4">{children}</Box>
    </Box>
    )
}

export default FormBox;