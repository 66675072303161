import { Box } from "@chakra-ui/react";
import React, {  useEffect, useRef, useState } from "react";
import CropperModal from "../CropperModal";
import { ICroppedImage } from "../CropperModal/CropperModal.types";
import useUploadToCDN from "src/hooks/useUploadToCDN";
import { IAttachment } from "src/types/attachment";
import { DEFAULT_ASPECT_RATIO } from "src/constants/aspect-ratio";

interface IImageUploadProps {
    src?: string;
    width?: number;
    height?: number;
    onImageChanged?: (url: string) => void;
    withCrop?: boolean;
    upload?: boolean;
    aspectRatio?: number;
    isCircle?: boolean;
}

const ImageUpload: React.FC<IImageUploadProps> = (props) => {
    const { src, withCrop, upload, width = 200, height = 200, onImageChanged, aspectRatio = DEFAULT_ASPECT_RATIO, isCircle = false } = props;
    const fileUploadRef = useRef<HTMLInputElement>(null);
    
    const [imageData, setImageData] = useState<string>(src || "");
    const [cropperOpen, setCropperOpen] = useState<boolean>(false);
    const [croppedImage, setCroppedImage] = useState<string>(src || "");
    const [uploading, setUploading] = useState<boolean>(false);
    const { progress, upload: uploadFile } = useUploadToCDN({
        onComplete: (data: IAttachment) => {
            //TODO: Upload Finished call onChange
            onImageChanged && onImageChanged(data._id);
        }, onError: () => {
            //TODO: Upload Error
        }, setUploading
    })

    useEffect(()=>{
        setImageData(src || "");
    },[src])


    const onFileUploadChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (FileReader && files && files.length) {
            var fr = new FileReader();
            fr.onload = function () {
                const image = fr.result as string;
                //TODO: if withCrop is true then show cropper modal, else just go to next step after selecting image(upload or just show)
                //work with withCrop and upload props
                if (withCrop) {
                    setCroppedImage(image);
                    setCropperOpen(true);
                } else if (upload) {
                    setImageData(image)
                    uploadFile(files[0]);
                } else {
                    setImageData(image)
                    onImageChanged && onImageChanged(image)
                    //just call onImageChanged without upload or crop
                }

            }
            fr.readAsDataURL(files[0]);
        }
    }

    const onCropFinished = (data: ICroppedImage) => {
        setImageData(data.base64);
        setCropperOpen(false);
        if (upload) {
            uploadFile(data.file);
        } else {
            onImageChanged && onImageChanged(data.base64)
        }
    }

    const onFileUploadClicked = () => {
        if (uploading) return;
        fileUploadRef.current?.click();
    }

    const onCropperModalCancel = ()=>{
        if (fileUploadRef.current) {
            fileUploadRef.current.value = "";
        }
    }



    return <>
        <Box position="relative">
            <Box backgroundImage={`url(${imageData})`}
                width={width} maxWidth={width > 400 ? "100%" : width} height={height} backgroundColor="gray.100"
                borderRadius={isCircle ? "50%" : "0"}
                backgroundPosition="center center"
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                cursor="pointer"
                mb="6"
                position="relative"
                onClick={onFileUploadClicked}
                transition="all ease 0.2s"
                _hover={{
                    opacity: "0.8"
                }}
                _after={{
                    content: `"Choose Photo"`,
                    position: "absolute",
                    bottom: "16px",
                    right: "32px",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    padding: "2px 8px",
                    color: "#fff",
                    borderRadius: "4px",
                    opacity: uploading ? 0 : 1
                }}></Box>
            {
                !uploading && <input type="file" ref={fileUploadRef} accept="image/png, image/gif, image/jpeg" onChange={onFileUploadChanged}
                    style={{ opacity: 0, width: 0, height: 0, position: "absolute", top: 0, left: 0, zIndex: 1 }} />
            }
            {
                uploading && (<>
                    <Box style={{
                        position: "absolute",
                        width: width,
                        height: height,
                        top: 0,
                        left: 0,
                        overflow: "hidden",
                        borderRadius: isCircle ? "50%" : 0,
                        zIndex: 2
                    }}>
                        <div style={{
                            backgroundColor: "#000",
                            opacity: 0.5,
                            width: "100%",
                            height: `${progress}%`,
                            transition: "all ease 0.3s"
                        }}></div>
                        <Box style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%,-50%)",
                            fontSize: "2rem",
                            fontWeight: "bold",
                            color: "#fff",
                            textShadow: "0 2px #000"
                        }}>{progress}</Box>
                    </Box>

                </>)
            }
        </Box>
        {/* TODO: replace haha.jpg with the file name from fileUpload or incomming file from API response */}
        {
            cropperOpen && <CropperModal isOpen={cropperOpen} setIsOpen={setCropperOpen}
                defaultImage={croppedImage}
                fileName="haha.jpg"
                aspectRatio={aspectRatio}
                onCropFinished={onCropFinished}
                modalSize="md"
                onCancel={onCropperModalCancel}
            />
        }
    </>
}

export default ImageUpload;