import { API_ROUTES } from "src/constants/api-routes";
import { NUTRITIONIST_SPECIALITIES } from "src/constants/notritionist-specialties";
import { AxiosInstance } from "../index";
import {
  ICreateNutritionistBody,
  ICreateNutritionistPayload,
  IAdminNutritionistListPayload,
  IAdminNutritionistProfile,
} from "../types/admin-nutritionist";
import { IAxiosResponse } from "../types/axios-response";
import { IBaseUserInfo } from "../types/base";

export const adminNutritionistsService = {
  create(data: ICreateNutritionistBody) {
    return AxiosInstance.post<IAxiosResponse<ICreateNutritionistPayload>>(
      API_ROUTES.PANEL.NUTRITIONISTS.ROOT,
      {
        ...data,
        specialty_ar: NUTRITIONIST_SPECIALITIES.arabic[data.specialty_en],
      }
    );
  },
  update(data: ICreateNutritionistBody, id: string) {
    const finalBody: any = { ...data };
    if (data.specialty_en) {
      finalBody.specialty_ar =
        NUTRITIONIST_SPECIALITIES.arabic[data.specialty_en];
    }
    return AxiosInstance.put<IAxiosResponse<ICreateNutritionistPayload>>(
      API_ROUTES.PANEL.NUTRITIONISTS.GET_ITEM(id),
      finalBody
    );
  },
  getAll(abortSignal: AbortSignal, searchQuery?: string) {
    return AxiosInstance.get<IAxiosResponse<IAdminNutritionistListPayload[]>>(
      `${API_ROUTES.PANEL.NUTRITIONISTS.ROOT}${searchQuery && `?${searchQuery}`}`,
      {
        signal: abortSignal,
      }
    );
  },
  getNutritionistProfile(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IAdminNutritionistProfile>>(
      API_ROUTES.PANEL.NUTRITIONISTS.GET_ITEM(id),
      {
        signal: abortSignal,
      }
    );
  },
  searchByName(param: string) {
    return AxiosInstance.get<IAxiosResponse<IBaseUserInfo[]>>(
      API_ROUTES.PANEL.NUTRITIONISTS.SEARCH_PARAM(param)
    );
  },
  changeActivation(nutritionistId:string,activation:boolean){
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.PANEL.NUTRITIONISTS.CHANGE_ACTIVATION(nutritionistId),
      {
        activation
      }
    );
  }
};
