import { API_ROUTES } from "src/constants/api-routes";
import {AxiosInstance} from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { IUserProfile } from "src/types/users";

export const panelUserService = {
    getProfile(controllerSignal:AbortSignal){
        return AxiosInstance.get<IAxiosResponse<IUserProfile>>(API_ROUTES.PANEL.CURRENT_USER.ROOT,{
            signal:controllerSignal
        });
    }
};