import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";

interface IInfoSectionProps{
    title:string;
}

const InfoSection: React.FC<IInfoSectionProps> = (props) => {
    const {title, children} = props;
    return <Box bg="white" boxShadow="lg" borderRadius="8px" p="4" mb="4">
        <Text fontSize="1.3rem" as="h3" mb="2">{title}</Text>
        <SimpleGrid columns={{base:1,sm:1,md:3,lg:4}}>{children}</SimpleGrid>
    </Box>
}

export default InfoSection;