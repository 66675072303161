export function transform(value: any,fieldIsOptional:boolean = false) {
    if (value === "") {
        return null;
    } else if (value === "*") {
        return null;
    } else if(!value) {
        return null;
    }
    else {
        //in this scope, the value is NaN but because number() function into yup validator already converted the empty string to number and result is NaN
        //this value is empty and we can return null! why? becuase number() preventing string entry..
        return parseInt(value)
    }
}