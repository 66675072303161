import React, { useRef } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
} from "@chakra-ui/react";
import { ICropper, ICropperModalProps } from "./CropperModal.types";
import { useTranslation } from "react-i18next";
import { Cropper } from "react-cropper";
import { base64ToFile } from "src/utils/base64-to-file";
import "cropperjs/dist/cropper.css";


const CropperModal: React.FC<ICropperModalProps> = (props) => {
    const {
        isOpen,
        setIsOpen,
        defaultImage,
        onCropFinished,
        onCancel,
        fileName,
        width = "100%",
        height = "auto",
        aspectRatio,
        modalSize = 'xs'
    } = props;

    const { t } = useTranslation();
    const cropperRef = useRef<HTMLImageElement>(null);

    const onModalClosed = () => {
        setIsOpen(false);
    }

    const onCancelClicked = () => {
        onCancel();
        setIsOpen(false);
    }

    const onConfirmClicked = () => {
        if (onCropFinished) {
            const imageElement = (cropperRef.current as unknown) as ICropper;
            const cropper = imageElement.cropper;
            const base64 = cropper.getCroppedCanvas().toDataURL();
            const mimeType = base64.substring(5, base64.indexOf(';'));
            base64ToFile(base64, fileName, mimeType)
                .then((converted) => {
                    onCropFinished({
                        base64: base64,
                        file: converted
                    })
                })
        }
    }


    

    return <Modal isOpen={isOpen} onClose={onModalClosed} size={modalSize}>
        <ModalOverlay />
        <ModalContent>
            <ModalBody>
                <Cropper
                    src={defaultImage}
                    style={{ width: width, height: height }}
                    initialAspectRatio={aspectRatio}
                    guides={true}
                    ref={cropperRef}
                    aspectRatio={aspectRatio}
                />
            </ModalBody>
            <ModalFooter>
                <Button colorScheme="teal" mr="2" onClick={onConfirmClicked}>{t("actions.done")}</Button>
                <Button colorScheme="orange" onClick={onCancelClicked}>{t("actions.cancel")}</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
}


export default CropperModal;