import { Box } from "@chakra-ui/react";
import React, {  useState } from "react";
import AddDayCard from "../AddDayCard";
import DayCard from "../DayCard";
import AddDayModal from "../AddDayModal";
import { usePlandays } from "../../PlanDaysContext";
import { INewPlanDay } from "src/api/types/plan-days";


const Days: React.FC = () => {
    const {days,openAddDayModal,setOpenAddDayModal,addDay,deletingDayNumber} = usePlandays();
    const [addDayNumber, setAddDayNumber] = useState<number>(-1);

    const onAddClicked = (dayNumber: number) => {
        setAddDayNumber(dayNumber);
        setOpenAddDayModal(true);
    }

    const onDayCreated = (sender:INewPlanDay) => {
        addDay(sender);
    }

    return <>
        <Box className="plan-days">
            {
                days.map(d => {
                    return d.meals.length ?
                        (<DayCard dayDetails={d} key={d.dayNo} loading={deletingDayNumber === d.dayNo}
                        />)
                        : (<AddDayCard dayNumber={d.dayNo} key={d.dayNo} onClick={onAddClicked} />)
                })
            }
        </Box>
        {
            openAddDayModal && <AddDayModal isOpen={openAddDayModal} onConfirm={onDayCreated} setIsOpen={setOpenAddDayModal}
                newDayOrder={addDayNumber} />
        }
    </>
}

export default Days;