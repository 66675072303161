import React, { useEffect, useState } from "react";
import { useTitle } from "src/hooks/useTitle";
import { useTranslation } from "react-i18next";
import FormBox from "../components/FormBox";
import { Box, Button, FormControl, FormLabel, Input, FormErrorMessage, InputGroup, InputRightElement } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useForm } from "react-hook-form";
import { ILoginForm } from "./types";
import { loginValidationSchema } from "src/validation-schemas/login";
import { yupResolver } from "@hookform/resolvers/yup";
import { authService } from "src/api/services/auth";
import { setAuthorization } from "src/utils/set-authorization";
import { useGlobal } from "src/global-context/GlobalContext";
import { HTTP_STATUS_CODES } from "src/constants/http-status-codes";
import { mdiEye,mdiEyeOff } from '@mdi/js';
import Icon from "@mdi/react";
import { IAxiosErrorResponse } from "src/api";

const Login: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.login"));
    const { setUser } = useGlobal();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [loginController] = useState<AbortController>(new AbortController());
    const [showPassword,setShowPassword] = useState<boolean>(false);

    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm<ILoginForm>({
        resolver: yupResolver(loginValidationSchema(t)),
        mode: "all",
        defaultValues: {
            email: '',
            password: ''
        }
    });

    useEffect(()=>{
        return ()=>{
            loginController.abort();
        }
    },[loginController]);


    const navigate = useNavigate();
    const onFormSubmit = async (data: ILoginForm) => {
        try {
            setError(null);
            setLoading(true);
            const result = await authService.login(data.email, data.password,loginController.signal);
            setAuthorization(result.data.payload.token);
            setUser(result.data.payload.profile);
            navigate(ROUTE_CONSTANTS.DASHBOARD.ROOT.ABSOLUTE);
        } catch (err) {
            const error = err as IAxiosErrorResponse;
            if (error?.response?.status && error?.response?.status === `${HTTP_STATUS_CODES.UN_AUTHORIZED}`) {
                setError(t("messages.loginAuthorizationError"));
            }
        } finally {
            setLoading(false);
        }
    }

    const onTogglePasswordType  = ()=>{
        setShowPassword(!showPassword);
    }

    return (
        <FormBox title={t("titles.login")}>
            <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                <FormControl mb="6" isInvalid={!!errors.email}>
                    <FormLabel htmlFor="email">{t("fields.emailAddress")}</FormLabel>
                    <Input id="email" type="email" size="lg" {...register("email")} />
                    <FormErrorMessage>
                        {!!errors.email && errors.email.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb="6" isInvalid={!!errors.password}>
                    <FormLabel htmlFor="password">{t("fields.password")}</FormLabel>
                    <InputGroup>
                    <Input id="password" type={showPassword?"text":"password"} size="lg" {...register("password")} />
                    <InputRightElement mt="1" cursor="pointer" onClick={onTogglePasswordType}>
                        <Icon path={showPassword?mdiEyeOff:mdiEye} size="28px"/>
                    </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                        {!!errors.password && errors.password.message}
                    </FormErrorMessage>
                </FormControl>
                {
                    error && <Box mb="6" color="red">
                        {error}
                    </Box>
                }
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Link to={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.ABSOLUTE}>{t("content.forgotPassword")}</Link>
                    <Button colorScheme="brand" size="lg" type="submit" isLoading={loading}>{t("actions.signIn")}</Button>
                </Box>
            </form>
        </FormBox>
    );
}

export default Login;