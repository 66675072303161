import { Box } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import { ROLES } from "src/constants/roles";
import { useGlobal } from "src/global-context/GlobalContext";

const NutritionistsLayout: React.FC = () => {
    const { user } = useGlobal();
    return (
        <Box>
            {
                user?.role === ROLES.NUTRITIONIST ? (
                    <Outlet />
                ) : (
                    <Box>
                        <h2>Access Denied!</h2>
                    </Box>
                )
            }
        </Box>
    );
}

export default NutritionistsLayout;