import { Box } from "@chakra-ui/react";
import React from "react";
import Icon from "@mdi/react"
import { mdiPlus } from '@mdi/js';
import { usePlandays } from "../../PlanDaysContext";

interface IAddDayProps {
    dayNumber: number;
    onClick:(dayNumber:number)=>void;
}

const AddDayCard: React.FC<IAddDayProps> = (props) => {
    const { dayNumber,onClick } = props;
    const {isEditable} = usePlandays();
    const onAddClicked = ()=>{
        if(!isEditable) return;
        onClick(dayNumber)
    }
    return <Box className="plan-day-card-base plan-add-day-card" onClick={onAddClicked}>
        <Box fontSize="2rem" textAlign="center">{dayNumber}</Box>
        {
            isEditable && (<Box display="flex" justifyContent="center" mt="4">
            <Icon path={mdiPlus} size="36px" />
        </Box>)
        }
    </Box>
}

export default AddDayCard;