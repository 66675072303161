import { Box, Checkbox, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IAddingFood } from "src/api/types/plan-days";
import { UnitSelect } from "./UnitSelect";

interface IFoodItemProps {
    selected?: boolean;
    onChange: (sender: IAddingFood, selected: boolean) => void;
    foodData: IAddingFood;
    foodUnits:string[]
}

export const FoodItem: React.FC<IFoodItemProps> = (props) => {
    const { selected, onChange, foodData , foodUnits} = props;
    const [chosenValue, setChosenValue] = useState<string>(`${foodData.value}`);
    const [chosenUnit, setChosenUnit] = useState<string>(foodData.unit);

    useEffect(() => {
        setChosenValue(`${foodData.value}`);
        setChosenUnit(foodData.unit);
    }, [foodData])

    const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChosenValue(e.target.value);
        if (e.target.value && selected) {
            onChange({
                ...foodData,
                value: parseInt(e.target.value)
            }, !!selected);
        }
    }

    const onSelectedUnitChanged = (sender: string) => {
        setChosenUnit(sender);
        if (selected) {
            onChange({
                ...foodData,
                unit: sender
            }, !!selected);
        }
    }

    const onCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        onChange({
            ...foodData,
            value: parseInt(chosenValue),
            unit: chosenUnit
        }, checked);
    }

    return (<Box display="flex" alignItems="center" p="2" pt="4" pb="4" borderBottom="1px solid" borderColor="gray.300"
        >
        <Box width="calc(100% - 110px)">
            <Checkbox isChecked={!!selected} onChange={onCheckedChanged}>
                {
                    foodData.name
                }
            </Checkbox>
        </Box>
        <Box display="flex" alignItems="center" overflow="hidden" maxHeight={selected ? "120px" : "0"} transition="all linear 0.2s"
            opacity={selected?"1":"0"} ml="1">
            <Box>
                <Input type="number" value={chosenValue} onChange={onValueChanged} width="56px" pl="2" pr="2" textAlign="center" />
            </Box>
            <Box>
                <UnitSelect units={foodUnits} selectedUnit={chosenUnit} setSelectedUnit={onSelectedUnitChanged} />
            </Box>
        </Box>
    </Box>);
}