import { Context, createContext, useContext } from "react";

export const contextFactory = <T>(
  contextName: string,
  useContextName: string
): [Context<T | undefined>, () => T] => {
  const ctx = createContext<T | undefined>(undefined);

  const useCtx = (): T => {
    const ctxValue = useContext(ctx);

    if (ctxValue === undefined) {
      throw new Error(
        `${useContextName} must be used within through ${contextName}`
      );
    }

    return ctxValue;
  };

  return [ctx, useCtx];
};
